<template>
  <v-toolbar color="white">
    <v-toolbar-items>
      <template v-for="item in items">
        <v-btn small :key="item.label" text :color="getColor(item)" @click="select(item)">
          <v-icon large>{{item.icon}}</v-icon>
          <span>{{item.label}}</span>
        </v-btn>
      </template>
    </v-toolbar-items>
  </v-toolbar>
</template>

<script>

export default {
  name: 'AufgabenSummary',
  mounted() {
    const selectedItem = this.items.find(i => i.selected);
    if (selectedItem) {
      this.select(selectedItem);
    }
  },
  methods: {
    isSelected(item) {
      return item.label === this.selected;
    },
    getColor(item) {
      return this.isSelected(item) ? 'blue darken-3' : 'grey lighten-1';
    },
    select(item) {
      this.selected = item.label;
      this.$emit('wasserzaehlerFilterChanged', item);
    },
  },
  props: ['items'],
  data: () => ({
    selected: null,
  }),
};
</script>
