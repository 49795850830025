<template>
  <v-form v-model="valid">
    <v-card>
      <v-card-title>
        <h2>Tausch Änderung</h2>
      </v-card-title>
      <v-card-text>
        <h3>{{tauschDate}}</h3>

        <v-row>
          <v-col cols="5">
            <v-text-field
              v-model="letzteAblesung"
              :rules="letzteAblesungRules"
              label="Wasserzähler alt: Letzte Ablesung"
              type="number"
              min="0"
            />
            <v-text-field
              v-model="zaehlerNummer"
              :rules="zaehlerNummerRules"
              label="Zählerdaten Neu"
            />

            <v-text-field
              v-model="baujahr"
              :rules="baujahrRules"
              label="Baujahr"
              type="number"
              min="0"
            />

            <v-text-field
              v-model="eichjahr"
              :rules="eichjahrRules"
              label="Eichjahr"
              type="number"
              min="0"
            />

            <v-select
              :items="herstellerOptions"
              v-model="hersteller"
              label="Hersteller"
              :rules="herstellerRules"
            />
          </v-col>
          <v-col cols="5">
            <v-text-field
              v-model="nenndurchfluss"
              :rules="nenndurchflussRules"
              label="Nenndurchfluss"
              type="number"
              min="0"
            />

            <v-text-field
              v-model="anfagsstand"
              :rules="anfagsstandRules"
              label="Anfangsstand"
              type="number"
              min="0"
            />

            <v-select
              :items="contactOptions"
              v-model="kontakt"
              label="Kontakt"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="default" text @click="doCancel">Abbrechen</v-btn>
        <v-btn
          color="primary"
          @click="doConfirm"
          :disabled="!valid"
          :loading="saving"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>

import _ from 'lodash';
import moment from 'moment';

export default {
  name: 'EditTausch',
  props: {
    tausch: {
      type: Object,
      required: true,
    },
    emailContact: String,
    allHerstellerOptions: Array,
  },
  mounted() {
  },
  methods: {
    doConfirm() {
      this.saving = true;
      const data = {
        zaehlerNummer: this.zaehlerNummer,
        baujahr: this.baujahr,
        eichjahr: this.eichjahr,
        hersteller: this.hersteller,
        nenndurchfluss: this.nenndurchfluss,
        anfagsstand: this.anfagsstand,
        kontakt: this.kontakt,
        letzteAblesung: this.letzteAblesung,
      };

      this.$emit('confirm', data);
    },
    doCancel() {
      this.$emit('cancel');
    },
    validateNumberIsPositive(v, errorMessage) {
      return (!!v && v >= 0) || errorMessage;
    },
    validateLetzteAblesungIsPositive(v) {
      return (!!v && v >= 0) || 'Letzte Ablesung kann nicht negativ sein';
    },
  },
  computed: {
    herstellerOptions() {
      return this.tausch.wasserzaehler.hersteller ?
        _.union(this.allHerstellerOptions, [this.tausch.wasserzaehler.hersteller]) :
        this.allHerstellerOptions;
    },
    tauschDate() {
      return moment(this.tausch.date).format('MMMM Do YYYY, HH:mm');
    },
    contactOptions() {
      const result = [];
      if (this.emailContact) {
        result.push(this.emailContact);
      }

      result.push('Brief');
      result.push('Keine');

      return result;
    },
    letzterVerbrauch() {
      return this.letzteAblesung
        ? this.letzteAblesung - this.previousReading
        : null;
    },
  },
  data() {
    return {
      saving: false,
      zaehlerNummer: this.tausch.wasserzaehler.externalId,
      baujahr: this.tausch.wasserzaehler.baujahr,
      eichjahr: this.tausch.wasserzaehler.eichjahr,
      hersteller: this.tausch.wasserzaehler.hersteller,
      nenndurchfluss: this.tausch.wasserzaehler.nenndurchfluss,
      anfagsstand: this.tausch.wasserzaehler.letzteAblesung,
      kontakt: this.tausch.metadata ? this.tausch.metadata.ContactOption : null,
      previousReading:
          this.tausch.wasserzaehlerAlt.letzteAblesung -
            this.tausch.wasserzaehlerAlt.letzterVerbrauch,
      letzteAblesung: this.tausch.wasserzaehlerAlt.letzteAblesung,
      valid: true,
      zaehlerNummerRules: [
        v => !!v || 'Zählernummer darf nicht leer sein',
      ],
      herstellerRules: [
        v => !!v || 'Hersteller darf nicht leer sein',
      ],
      baujahrRules: [
        v => !!v || 'Baujahr darf nicht leer sein',
        v => this.validateNumberIsPositive(v, 'Baujahr kann nicht negativ sein'),
      ],
      eichjahrRules: [
        v => !!v || 'Eichjahr darf nicht leer sein',
        v => this.validateNumberIsPositive(v, 'Eichjahr kann nicht negativ sein'),
      ],
      nenndurchflussRules: [
        v => !!v || 'Nenndurchfluss darf nicht leer sein',
        v => this.validateNumberIsPositive(v, 'Nenndurchfluss kann nicht negativ sein'),
      ],
      anfagsstandRules: [
        v => !!v || 'Anfangsstand darf nicht leer sein',
        v => this.validateNumberIsPositive(v, 'Anfangsstand kann nicht negativ sein'),
      ],
      letzteAblesungRules: [
        v => !!v || 'Zählerstand darf nicht leer sein',
        v => this.validateLetzteAblesungIsPositive(v),
      ],
    };
  },
};
</script>
