<template>
  <v-row justify="space-between">
    <v-col cols="5">
      <zaehler-daten :wasserzaehler="historie.wasserzaehler" />
    </v-col>
    <v-col cols="5">
      <zaehler-stand :wasserzaehler="historie.wasserzaehler" />
    </v-col>
  </v-row>
</template>

<script>

import ZaehlerStand from "@/components/common/ZaehlerStand";
import ZaehlerDaten from "@/components/common/ZaehlerDaten";

export default {
  name: 'NichtAnwesendHistorieView',
  props: ['historie'],
  components: {
    ZaehlerStand,
    ZaehlerDaten,
  },
};
</script>
