<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-on="on"
        v-bind="attrs"
        color="blue-grey"
        :class="buttonClass"
        class="white--text">
        <v-icon left dark>cloud_upload</v-icon>
        Datei Upload
      </v-btn>
    </template>
    <v-card>
      <v-card-title>Datei Upload</v-card-title>
      <v-card-text>
        <v-text-field
          v-model="name"
          label="Bezeichnung"
        />
        <input
          type="file"
          ref="fileInput"
          accept=".pdf, .png, .jpg, .jpeg"
          @change="onFileInputChanged"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="close">Abbrechen</v-btn>
        <v-btn color="primary" :disabled="!fileSelected" @click="upload">Hochladen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "FileUploadButton",
  props: ['buttonClass'],
  data: () => ({
    dialog: false,
    name: null,
    selectedFile: null,
  }),
  computed: {
    fileSelected() {
      return !!this.selectedFile;
    },
  },
  methods: {
    onFileInputChanged(event) {
      this.selectedFile = event.target.files[0];
      if(!this.name) {
        this.name = this.selectedFile.name;
      }
    },
    upload() {
      this.$emit('upload', { name: this.name, file: this.selectedFile });
      this.close();
    },
    close() {
      this.dialog = false;
      this.name = null;
      this.selectedFile = null;
      this.$refs.fileInput.value = null;
    },
  },
}
</script>

<style scoped>

</style>
