import Vue from 'vue';
import Router from 'vue-router';
import MainScreen from '@/components/MainScreen';
import Aufgaben from '@/components/Aufgaben';
import AufgabeDetails from '@/components/AufgabeDetails';
import Dashboard from '@/components/Dashboard';
import Termine from '@/components/Termine';
import Extremwerte from '@/components/Extremwerte';
import Tags from '@/components/Tags';
import SystemSettings from '@/components/SystemSettings';
import Export from '@/components/exports/Export';
import Import from '@/components/Import';
import CustomerReadingsPreviewList from '@/components/CustomerReadingsPreviewList';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: MainScreen,
      children: [
        {
          path: '/',
          component: Dashboard,
        },
        {
          path: '/Aufgaben',
          component: Aufgaben,
        },
        {
          path: '/Aufgabe/:id',
          component: AufgabeDetails,
        },
        {
          path: '/Termine',
          component: Termine,
        },
        {
          path: '/Extremwerte',
          component: Extremwerte,
        },
        {
          path: '/Tags',
          component: Tags,
        },
        {
          path: '/SystemSettings',
          component: SystemSettings,
        },
        {
          path: '/Export',
          component: Export,
        },
        {
          path: '/Import',
          component: Import,
        },
        {
          path: '/Kundenablesungen',
          component: CustomerReadingsPreviewList,
        },
      ],
    },
    {
      path: '*',
      redirect: '/',
    },
  ],
});
