<template>
  <v-container v-if="hasBeenFound">
    <notifications position="top center" group="aufgabe" class="text-pre-wrap"/>
    <v-row v-if="!aufgabe.isActive">
      <v-col cols="2" offset="10">
        <v-alert :value="true" type="error">
          Inaktiv
        </v-alert>
      </v-col>
    </v-row>
    <v-tabs fixed-tabs v-model="tab">
      <v-tab href="#details-tab">
        Details
      </v-tab>
      <v-tab href="#zugang-tab">
        Zugang
      </v-tab>
      <v-tab href="#rechnungsadresse-tab" v-if="hasBillingAddress">
        Rechnungsadresse
      </v-tab>
      <v-tab href="#zbv-tab" v-if="aufgabe.hasZbvData">
        ZBV
      </v-tab>
      <v-tab href="#tags-tab">
        Tags
      </v-tab>
      <v-tab href="#tags-answers" v-if="hasAnswers">
        Kundenfragen
      </v-tab>
      <v-tab href="#anhaenge-tab" v-if="hasFiles">
        Anhänge
      </v-tab>
      <v-tabs-items v-model="tab">
        <v-tab-item id="details-tab">
          <v-card>
            <v-card-text>
              <v-row justify="space-between">
                <v-col cols="5">
                  <kunden-daten :kundendaten="kundendaten" editable @contactEdit="onContactEditConfirmed" @kundeEdit="onKundenEditConfirmed"/>
                </v-col>
                <v-col cols="5">
                  <wasserzaehler-daten :wasserzaehler="aufgabe.wasserzaehler" show-details />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item id="zugang-tab">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="5">
                  <h3 class="header-with-padding">Zugang</h3>
                  <v-list dense>
                    <v-list-item>
                      <v-list-item-content>Erreichbarkeit:</v-list-item-content>
                      <v-list-item-content class="align-end">
                        {{aufgabe.zugangErreichbarkeit}}
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>Anmerkung:</v-list-item-content>
                      <v-list-item-content class="align-end">
                        {{aufgabe.zugangAnmerkung}}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
              <ImageGallery :images="zugangImages" />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item id="rechnungsadresse-tab" v-if="hasBillingAddress">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="5">
                  <rechnungsadresse-daten :value="aufgabe.rechnungsadresse" />
                </v-col>
              </v-row>
              <ImageGallery :images="zugangImages" />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item id="zbv-tab" v-if="aufgabe.hasZbvData">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="5">
                  <zbv-daten :value="aufgabe" title="Rechnungsadresse" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item id="tags-tab">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="4">
                  <v-select
                    :clearable="true"
                    :value="versorgungskreiseTag"
                    :items="versorgungskreiseTagValues"
                    append-icon="label"
                    :placeholder="versorgungskreiseTagName"
                    :hint="versorgungskreiseTagName"
                    @change="changeTagValue(versorgungskreiseTagName, $event)"
                  />
                </v-col>
              </v-row>
              <v-row class="mt-4">
                <v-col cols="4">
                  <v-select
                    :clearable="true"
                    :value="katastralgemeindeTag"
                    :items="katastralgemeindeTagValues"
                    append-icon="label"
                    :placeholder="katastralgemeindeTagName"
                    :hint="katastralgemeindeTagName"
                    @change="changeTagValue(katastralgemeindeTagName, $event)"
                  />
                </v-col>
              </v-row>
              <v-row class="mt-4">
                <v-col cols="4">
                  <v-select
                    :clearable="true"
                    :value="strasseTag"
                    :items="strasseTagValues"
                    append-icon="label"
                    :placeholder="strasseTagName"
                    :hint="strasseTagName"
                    @change="changeTagValue(strasseTagName, $event)"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item id="tags-answers" v-if="hasAnswers">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="5">
                  <h3 class="header-with-padding">Kundendaten</h3>
                  <v-list dense>
                    <v-list-item v-for="a in aufgabe.wasserzaehler.answers" :key="a.question">
                      <v-list-item-content>{{a.question}}</v-list-item-content>
                      <v-list-item-content class="align-end">
                        <span>{{a.answer}}</span>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item id="anhaenge-tab" v-if="hasFiles">
          <v-card>
            <v-card-text>
              <file-gallery :files="files" title="Anhänge" @delete="deleteFile"/>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>

    <v-row>
      <v-col>
        <v-btn color="error" class="white--text mr-4" @click="deleteAufgabe">
          <v-icon left dark>delete</v-icon>
          löschen
        </v-btn>
        <v-btn
          :color="aufgabe.isActive ? 'error' : 'success'" class="white--text"
          @click="changeActivity"
        >
          <v-icon left dark v-if="aufgabe.isActive">visibility</v-icon>
          <v-icon left dark v-if="!aufgabe.isActive">visibility_off</v-icon>
          {{aufgabe.isActive ? 'Inaktiv' : 'Aktiv'}}
        </v-btn>
      </v-col>

      <v-col cols="auto">
        <v-btn color="blue-grey" class="white--text" @click="goBack">
          <v-icon left dark>keyboard_arrow_left</v-icon>
          Zurück
        </v-btn>
        <v-btn
          v-if="aufgabe.isActive"
          color="primary"
          class="white--text ml-4"
          @click="showEditDialog"
        >
          <v-icon left dark>edit</v-icon>
          Zählerdaten Korrigieren
        </v-btn>
        <file-upload-button button-class="ml-4" @upload="uploadFile" />
        <v-btn
          v-if="aufgabe.isActive"
          color="success"
          class="white--text ml-4"
          @click="showWebReadingDialog"
        >
          <v-icon left dark>picture_in_picture</v-icon>
          Web Ablesung
        </v-btn>
        <v-btn
          v-if="aufgabe.isActive"
          color="success"
          class="white--text ml-4"
          @click="showTauschnachtragDialog"
        >
          <v-icon left dark>cached</v-icon>
          Tauschnachtrag
        </v-btn>
        <v-btn
          v-if="aufgabe.isActive"
          color="info"
          class="white--text ml-4"
          @click="downloadReport"
          :loading="downloadingReport"
        >
          <v-icon left dark>print</v-icon>
          Übersicht
        </v-btn>
      </v-col>
    </v-row>

    <v-card v-if="hasTermin" class="mt-4">
      <v-card-title><strong>Termin:</strong>&nbsp;{{termin}}</v-card-title>
    </v-card>

    <v-card class="mt-4">
      <v-card-title class="d-flex justify-space-between">
        <h4>Verbrauch</h4>
        <span class="subtitle-2">{{ableseperiode.from | formatDate}} - {{ableseperiode.to | formatDate}}</span>
      </v-card-title>
      <v-divider></v-divider>
      <verbrauch-chart :height="80" :verbrauchData="verbrauchData" />
    </v-card>

    <div v-if="hasHistory" class="mt-4">
      <v-toolbar color="cyan" dark>
        <v-icon>history</v-icon>
        <v-toolbar-title>Historie</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="showUndoLastHistoryEntryModal = true" :loading="undoingHistorie">
          <v-icon>undo</v-icon> Rückgängig
        </v-btn>
      </v-toolbar>
      <v-expansion-panels>
        <v-expansion-panel v-for="(item,i) in historie" :key="i">
          <v-expansion-panel-header>
            <span>
              <strong>{{ item.title }}</strong>
              <span v-for="(addition, idx) of item.titleAdditions" :key="idx" :class="{ 'red--text': addition.showAsWarning }">{{ addition.content }}</span>
            </span>
            <div class="caption text-right">
              <span class="mr-4">{{ item.createdBy }}</span>
              <span>{{ item.date | formatDate('DD.MM.YYYY HH:mm') }}</span>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <historie-view
              :historie="item"
              :aufgabe="aufgabe"
              :previousHistoryDate="getPreviousHistoryEntryDate(i)"
              :herstellerOptions="wasserzaehlerHersteller"
              @changed="reloadAufgabe"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <v-dialog v-model="showEditModal" :persistent="true" max-width="500px">
      <edit-wasserzaehler
        v-if="showEditModal"
        :wasserzaehler="aufgabe.wasserzaehler"
        :allHerstellerOptions="wasserzaehlerHersteller"
        @confirm="onWasserzaehlerEditConfirmed"
        @cancel="showEditModal = false"
      />
    </v-dialog>
    <v-dialog v-model="showWebReadingModal" :persistent="true" max-width="800px">
      <web-ablesung
        :webAblesung="webAblesung"
        :lastHistoryDate="lastHistoryDate"
        :hasOpenKundenAblesung="aufgabe.hasOpenKundenAblesung"
        v-if="showWebReadingModal"
        @confirm="onWebAblesungConfirmed"
        @cancel="onWebAblesungCanceled"
      />
    </v-dialog>
    <v-dialog v-model="showTauschnachtragModal" :persistent="true" max-width="500px">
      <tauschnachtrag
        :emailContact="emailContact"
        :wasserzaehlerAltLetzteAblesung="letzteAblesung"
        :lastHistoryDate="lastHistoryDate"
        :hersteller-options="wasserzaehlerHersteller"
        v-if="showTauschnachtragModal"
        @confirm="onTauchnachtragConfirmed"
        @cancel="onTauchnachtragCanceled"
      />
    </v-dialog>
    <v-dialog v-model="showDeleteConfirmModal" :persistent="true" max-width="500px">
      <v-card>
        <v-card-text>
          <h3>
            Sind Sie sicher, dass Sie diese Aufgabe und sämtliche zugehörige
            Historieneinträge löschen wollen?
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-btn color="default" text @click="showDeleteConfirmModal = false">Nein</v-btn>
          <v-btn
            color="primary"
            text
            @click="doDeleteAufgabe"
          >
            Ja
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showUndoLastHistoryEntryModal" persistent max-width="500">
      <v-card v-if="showUndoLastHistoryEntryModal">
        <v-card-title>Historien Eintrag rückgängig machen</v-card-title>
        <v-card-text>
          <div>
            Sind Sie sicher, dass Sie den Historien Eintrag '{{historie[0].entryType}}' vom {{historie[0].date | formatDate('DD.MM.YYYY HH:mm')}} rückgängig machen wollen?
          </div>
          <div>
            Alle Daten der Aufgabe werden auf den Stand vor diesem Eintrag zurück gesetzt.
          </div>
          <div class="font-weight-bold text--primary mt-2">
            Der Eintrag und die damit verbundenen Daten können anschließend nicht Wiederhergestellt gemacht werden!
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text @click="showUndoLastHistoryEntryModal = false">Abbrechen</v-btn>
          <v-btn @click="confirmUndoLastHistoryEntry" color="error" :loading="undoingHistorie">Zurücksetzen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showActivityChangeModal" :persistent="true" max-width="500px">
      <v-card>
        <v-card-text>
          <h3>
            {{aufgabe.isActive
              ? 'Wollen Sie diese Aufgabe wirklich inaktiv setzen?'
              : 'Wollen Sie diese Aufgabe wirklich aktiv setzen?'
            }}
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-btn color="default" text @click="showActivityChangeModal = false">Nein</v-btn>
          <v-btn
            color="primary"
            text
            @click="doChangeActivity"
          >
            Ja
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

import moment from 'moment';
import util from '@/util';
import urljoin from 'url-join';
import WebAblesung from './aufgaben/WebAblesung';
import Tauschnachtrag from './aufgaben/Tauschnachtrag';
import wasserparkApi from '../wasserparkApi';
import VerbrauchChart from './VerbrauchChart';
import HistorieView from './HistorieView';
import ImageGallery from './common/ImageGallery';
import authUtils from "@/security/utils";
import WasserzaehlerDaten from "@/components/common/ZaehlerDaten";
import KundenDaten from "@/components/common/KundenDaten";
import ZbvDaten from "@/components/common/ZbvDaten";
import RechnungsadresseDaten from "@/components/common/RechnungsadresseDaten";
import FileUploadButton from "@/components/common/FileUploadButton";
import FileGallery from "@/components/common/FileGallery";
import EditWasserzaehler from "@/components/aufgaben/EditWasserzaehler";
import {mapState} from "vuex";

const console = window.console;

const filesUrlBase = util.getApiUrl();

export default {
  name: 'AufgabeDetails',
  components: {
    FileGallery,
    FileUploadButton,
    RechnungsadresseDaten,
    ZbvDaten,
    KundenDaten,
    WasserzaehlerDaten,
    VerbrauchChart,
    HistorieView,
    ImageGallery,
    WebAblesung,
    Tauschnachtrag,
    EditWasserzaehler,
  },
  mounted() {
    this.reloadAufgabe();
    wasserparkApi.getHerstellerList()
      .then((r) => {
        this.wasserzaehlerHersteller = r.data;
      });

    this.getTagValues(
      this.katastralgemeindeTagName,
      (r) => {
        this.katastralgemeindeTagValues = r;
      });
    this.getTagValues(
      this.strasseTagName,
      (r) => {
        this.strasseTagValues = r;
      });
    this.getTagValues(
      this.versorgungskreiseTagName,
      (r) => {
        this.versorgungskreiseTagValues = r;
      });
  },
  watch: {
    ableseperiode: {
      handler: 'reloadAufgabe',
      deep: true,
    }
  },
  computed: {
    hasFiles() {
      return this.aufgabe.files && this.aufgabe.files.length > 0;
    },
    files() {
      return this.aufgabe.files.map(f => ({
        id: f.id,
        name: f.name,
        thumbnailUrl: f.thumbnailUrl ? urljoin(filesUrlBase, f.thumbnailUrl, `?api_key=${authUtils.getAccessToken()}`) : undefined,
        url: urljoin(filesUrlBase, f.url, `?api_key=${authUtils.getAccessToken()}`),
      }))
    },
    kundendaten() {
      return {
        kundennummer: this.aufgabe.customerExternalId,
        titel: this.aufgabe.titel,
        name: this.aufgabe.name,
        nachname: this.aufgabe.nachname,
        namensergaenzung: this.aufgabe.namensergaenzung,
        telefonnummer: this.aufgabe.telefonnummer,
        email: this.aufgabe.email,
        ort: this.aufgabe.ort,
        strasse: this.aufgabe.addresse,
      };
    },
    hasAnswers() {
      return this.aufgabe.wasserzaehler.answers && this.aufgabe.wasserzaehler.answers.length > 0;
    },
    hasTermin() {
      return this.hasBeenFound && this.aufgabe.termin;
    },
    termin() {
      return this.hasTermin
        ? `${this.aufgabe.terminart ? this.aufgabe.terminart : 'Tausch'}, ${moment(this.aufgabe.termin).format('DD.MM.YYYY HH:mm')}`
        : '';
    },
    letzteAblesung() {
      return this.historie && this.historie.length > 0
        ? this.historie[0].wasserzaehler.letzteAblesung
        : 0;
    },
    emailContact() {
      return this.aufgabe ? this.aufgabe.email : null;
    },
    lastHistoryDate() {
      if (!this.historie || this.historie.length <= 0) {
        return null;
      }

      return this.historie[0].date;
    },
    hasBillingAddress() {
      return this.hasBeenFound && !!this.aufgabe.rechnungsadresse;
    },
    hasHistory() {
      return this.hasBeenFound && this.historie && this.historie.length > 0;
    },
    zugangImages() {
      if (!this.hasBeenFound || !this.aufgabe.zugangFotos) {
        return [];
      }

      return this.aufgabe.zugangFotos.map(f => ({
        thumbnail: urljoin(filesUrlBase, f.vorschaubildUrl, `?api_key=${authUtils.getAccessToken()}`),
        image: urljoin(filesUrlBase, f.url, `?api_key=${authUtils.getAccessToken()}`),
      }));
    },
    katastralgemeindeTag() {
      return this.getTagValue(this.katastralgemeindeTagName);
    },
    strasseTag() {
      return this.getTagValue(this.strasseTagName);
    },
    versorgungskreiseTag() {
      return this.getTagValue(this.versorgungskreiseTagName);
    },
    webAblesung() {
      return {
        zaehlernummer: this.aufgabe.wasserzaehler.externalId,
        hersteller: this.aufgabe.wasserzaehler.hersteller,
        baujahr: this.aufgabe.wasserzaehler.baujahr,
        eichjahr: this.aufgabe.wasserzaehler.eichjahr,
        nenndurchfluss: this.aufgabe.wasserzaehler.nenndurchfluss,
        letzteAblesung: this.aufgabe.wasserzaehler.letzteAblesung,
        letzterVerbrauch: this.aufgabe.wasserzaehler.letzterVerbrauch,
      };
    },
    verbrauchData() {
      if(!this.verbrauchHistorie) {
        return [];
      }
      const historie = this.verbrauchHistorie.filter(x => moment(x.date).isBetween(moment(this.ableseperiode.from), moment(this.ableseperiode.to)));
      const min = moment.min(historie.map(c => moment(c.date)));
      const max = moment.max(historie.map(c => moment(c.date)));
      const duration = moment.duration(max.diff(min));
      const displayMonths = duration.asYears() < 2;

      const groupedVerbrauch = historie.reduce((grouped, curr) => {
        const currentDate = moment(curr.date);
        const month =  displayMonths ? currentDate.month() : 0;
        const date = moment.utc({year: currentDate.year(), month, date: 1}).toISOString();
        const entry = grouped[date] ?? {
          date: moment({year: currentDate.year(), month, date: 1}),
          verbrauch: 0,
        };
        entry.verbrauch += curr.verbrauch;
        // eslint-disable-next-line no-param-reassign
        grouped[date] = entry;
        return grouped;
      }, {});

      return Object.values(groupedVerbrauch);
    },
    ...mapState(['ableseperiode']),
  },
  methods: {
    async reloadAufgabe() {
      const { data } = await wasserparkApi.getAufgabeDetails(this.$route.params.id);
      this.aufgabe = data;
      this.hasBeenFound = !!data;

      const historieData = await wasserparkApi.getAufgabeHistorie(this.$route.params.id);
      this.historie = historieData.data.historie;
      this.verbrauchHistorie = historieData.data.verbrauchHistorie;
    },
    async confirmUndoLastHistoryEntry() {
      this.undoingHistorie = true;
      this.showUndoLastHistoryEntryModal = false;
      try {
        await wasserparkApi.undoLastHistoryEntry(this.$route.params.id);
        await this.reloadAufgabe();
      } catch (e) {
        this.$notify({
          group: 'aufgabe',
          title: 'Rückgängig machen Fehlgeschlagen',
          text: 'Beim zurücksetzen des letzten Historien Eintrags ist ein Fehler aufgetreten.\n' +
            'Bitte versuchen Sie es später erneut. Sollte dieser Fehler wiederholt auftreten, wenden Sie sich bitte an Ihren Ansprechpartner bei Gemeindepark.',
          type: 'error',
          duration: 10000,
        })
      }
      this.undoingHistorie = false;
    },
    changeTagValue(name, value) {
      wasserparkApi
        .setAufgabeTag(this.$route.params.id, name, value)
        .then((r) => {
          this.aufgabe = r.data;
          this.hasBeenFound = !!r.data;
        },
        response => console.error(response));
    },
    getTagValues(name, cb) {
      wasserparkApi
        .getTagValuesByName(name)
        .then((r) => {
          cb(r.data);
        },
        response => window.console.error(response));
    },
    getTagValue(name) {
      if (!this.aufgabe) {
        return null;
      }
      const tag = this.aufgabe.tags.find(t => t.name === name);
      return tag ? tag.value : null;
    },
    getPreviousHistoryEntryDate(index) {
      if (!this.historie) {
        return null;
      }

      if (index >= this.historie.length - 1) {
        return null;
      }

      return this.historie[index + 1].date;
    },
    isHistoryElementNichtAnwesend(history) {
      return history.entryType === 'Nicht anwesend';
    },
    isHistoryElementTausch(history) {
      return history.entryType === 'Tausch' || history.entryType === 'Tausch Änderung';
    },
    isHistoryElementAblesung(history) {
      return history.entryType === 'Ablesung' || history.entryType === 'Ablesung Änderung';
    },
    goBack() {
      this.$router.go(-1);
    },
    downloadReport() {
      const url = wasserparkApi.getAufgabeReportUrl(this.$route.params.id);
      window.open(url, '_blank');
    },
    showEditDialog() {
      this.showEditModal = true;
    },
    showWebReadingDialog() {
      this.showWebReadingModal = true;
    },
    showTauschnachtragDialog() {
      this.showTauschnachtragModal = true;
    },
    onContactEditConfirmed(data) {
      wasserparkApi.editAufgabeContacts(
        this.$route.params.id,
        data.email,
        data.phone,
      ).then((r) => {
        this.aufgabe = r.data;
      });
    },
    async onKundenEditConfirmed(data) {
      await wasserparkApi.kundendatenKorrigieren(this.$route.params.id, data);
      await this.reloadAufgabe();
    },
    async onWasserzaehlerEditConfirmed(data) {
      await wasserparkApi.wasserzaehlerKorrigieren(this.$route.params.id, data);
      await this.reloadAufgabe();
      this.showEditModal = false;
    },
    onTauchnachtragCanceled() {
      this.showTauschnachtragModal = false;
    },
    async onTauchnachtragConfirmed(tauchnachtrag) {
      this.showTauschnachtragModal = false;

      await wasserparkApi
        .tauchnachtrag({
          aufgabeId: this.aufgabe.id,
          ...tauchnachtrag,
        });
      await this.reloadAufgabe();
    },
    onWebAblesungCanceled() {
      this.showWebReadingModal = false;
    },
    async onWebAblesungConfirmed(data) {
      this.showWebReadingModal = false;

      await wasserparkApi
        .webAblesung(
          this.aufgabe.id,
          this.aufgabe.wasserzaehler.id,
          data.letzteAblesung,
          data.letzterVerbrauch,
          data.nachkontrolle,
          data.datum,
          data.wayOfReading,
          data.answers,
        );
      await this.reloadAufgabe();
    },
    async doChangeActivity() {
      this.showActivityChangeModal = false;
      await wasserparkApi
        .changeAufgabeActivity(this.aufgabe.id, !this.aufgabe.isActive);
      await this.reloadAufgabe();
    },
    changeActivity() {
      this.showActivityChangeModal = true;
    },
    async deleteFile(file) {
      await wasserparkApi.deleteAufgabeFile(this.aufgabe.id, file.id);
      await this.reloadAufgabe();
    },
    deleteAufgabe() {
      this.showDeleteConfirmModal = true;
    },
    doDeleteAufgabe() {
      this.showDeleteConfirmModal = false;

      wasserparkApi.deleteAufgabe(this.aufgabe.id)
        .then(() => {
          this.goBack();
        });
    },
    async uploadFile(selected) {
      await wasserparkApi.uploadAufgabeFile(this.aufgabe.id, selected);
      await this.reloadAufgabe();
    },
  },
  data: () => ({
    aufgabe: null,
    historie: [],
    verbrauchHistorie: [],
    hasBeenFound: false,
    downloadingReport: false,
    katastralgemeindeTagValues: [],
    strasseTagValues: [],
    versorgungskreiseTagValues: [],
    katastralgemeindeTagName: 'Katastralgemeinde',
    strasseTagName: 'Straße',
    versorgungskreiseTagName: 'Versorgungskreise',
    showWebReadingModal: false,
    showTauschnachtragModal: false,
    showEditModal: false,
    showDeleteConfirmModal: false,
    showUndoLastHistoryEntryModal: false,
    showActivityChangeModal: false,
    tab: null,
    wasserzaehlerHersteller: [],
    undoingHistorie: false,
  }),
};
</script>
