<template>
    <div>
      <v-form v-model="valid" ref="tagForm">
        <v-row align="baseline">
          <v-col cols="8">
            <v-text-field
              append-icon="label"
              label="Neuer Tag"
              placeholder="Neuen Tag eingeben"
              v-model="newValue"
              :rules="newValueRules"
            />
          </v-col>
          <v-col cols="4">
            <v-btn
              small
              color="primary"
              @click="saveNewValue"
              :disabled="valid == false"
            >
              Speichern
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <div>
        <v-text-field
          v-if="hasValues"
          name="input-1"
          label="Suche..."
          append-icon="search"
          @input="onFilterChange"
        />
        <div class="d-flex flex-wrap">
          <v-chip
            v-for="(item, i) in filteredValues" :key="i"
            class="mr-2"
            close
            color="green"
            label
            outlined
            @click:close="deleteValue(item)"
          >
            {{item}}
          </v-chip>
        </div>
      </div>
    </div>
</template>

<script>

import wasserparkApi from '../wasserparkApi';

export default {
  name: 'TagValues',
  props: {
    id: {
      type: String,
    },
    name: {
      type: String,
    },
  },
  mounted() {
    wasserparkApi
      .getTagValues(this.id)
      .then((r) => {
        this.values = r.data;
      },
      response => window.console.error(response));
  },
  computed: {
    filteredValues() {
      if (!this.values || !this.filterText) {
        return this.values;
      }

      return this.values.filter(v =>
        v.toLowerCase().indexOf(this.filterText.toLowerCase()) >= 0);
    },
    hasValues() {
      return this.values && (this.values.length > 0);
    },
  },
  methods: {
    saveNewValue() {
      wasserparkApi.addTagValue(this.id, this.newValue).then((r) => {
        this.values = r.data.values;
        this.newValue = null;
        this.$refs.tagForm.reset();
      });
    },
    onFilterChange(searchText) {
      this.filterText = searchText;
    },
    deleteValue(value) {
      wasserparkApi.removeTagValue(this.id, value).then((r) => {
        this.values = r.data.values;
      });
    },
  },
  data() {
    return {
      values: null,
      newValue: null,
      filterText: null,
      valid: false,
      newValueRules: [
        v => !!v || 'Please enter value',
      ],
    };
  },
};
</script>
