<template>
  <v-form v-model="valid">
    <v-card>
      <v-card-title>
        <h2>Web Ablesung</h2>
      </v-card-title>
      <v-card-text>
        <v-alert type="warning" v-if="hasOpenKundenAblesung">
          Für diesen Wasserzähler gibt es bereits eine offene Kundenablesung! Mit dem Erfassen dieser Ablesung wird die vorhandene Kundenablesung überschrieben.
        </v-alert>
        <v-combobox
          v-model="wayOfReading"
          :items="wayOfreadingValues"
          label="Wie wurde die Ablesung gemeldet?"
        ></v-combobox>
        <v-menu
          v-model="datumMenu"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-on="on"
              v-model="formattedDatum"
              label="Datum"
              hint="Datum"
              prepend-icon="event"
              :rules="datumRules"
              persistent-hint
              readonly
            />
          </template>
          <v-date-picker
            v-model="datum"
            no-title
            locale="de"
            :allowed-dates="allowedDates"
          />
        </v-menu>
        <v-text-field
          v-model="ablesung"
          :rules="ablesungRules"
          label="Letzte Ablesung"
          type="number"
          prepend-icon="picture_in_picture"
          min="0"
        />
        <h4>Letzter Verbrauch: {{ letzterVerbrauch }}</h4>
        <v-switch
          label="Nachkontrolle"
          v-model="nachkontrolle"
        />
        <v-row justify="center">
          <v-col cols="10">
            <v-row wrap align="center" v-for="a in answers" :key="a.question">
              <v-col xs="12" sm="12" md="6" class="pl-2">
                {{a.question}} <template v-if="a.mandatory">&nbsp;*</template>
              </v-col>
              <v-col xs="12" sm="6" class="pr-2 d-flex justify-end">
                <v-radio-group v-model="a.answer" row>
                  <v-radio v-for="qa in a.answers" :key="qa" :label="qa" :value="qa" />
                </v-radio-group>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <strong
          v-if="!allMandatoryQuestionsAnswered"
          class="red--text text--lighten-1"
        >
          * Bitte beantworten Sie alle Fragen
        </strong>
      </v-card-text>
      <v-card-actions>
        <v-btn color="default" text @click="doCancel">Abbrechen</v-btn>
        <v-btn
          color="primary"
          @click="doConfirm"
          :disabled="!valid || !allMandatoryQuestionsAnswered"
          :loading="saving"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>

import moment from 'moment';
import wasserparkApi from "@/wasserparkApi";

export default {
  name: 'WebAblesung',
  props: {
    webAblesung: {
      type: Object,
      required: true,
    },
    lastHistoryDate: {
      type: String,
    },
    hasOpenKundenAblesung: Boolean,
  },
  mounted() {
    wasserparkApi.getCustomerPortalQuestions()
      .then((r) => {
        this.answers = r.data.map(q => ({
          ...q,
          answer: null,
        }));
      });
  },
  methods: {
    allowedDates(val) {
      return moment() >= this.getUtcDate(val);
    },
    doConfirm() {
      this.saving = true;
      const webAblesung = {
        letzteAblesung: this.ablesung,
        letzterVerbrauch: this.letzterVerbrauch,
        nachkontrolle: this.nachkontrolle,
        datum: this.getUtcDate(this.datum),
        wayOfReading: this.wayOfReading,
        answers: this.answers,
      };
      this.$emit('confirm', webAblesung);
    },
    doCancel() {
      this.$emit('cancel');
    },
    validateAblesungIsPositive() {
      return (this.ablesung) >= 0 || 'Zählerstand kann nicht negativ sein';
    },
    validateDatumIsAfterLastEntry() {
      const lastHistoryDate = this.lastHistoryMoment;
      if (!lastHistoryDate) {
        return true;
      }

      const datum = this.getUtcDate(this.datum);

      return datum > lastHistoryDate ||
        'Es is nicht möglich eine Ablesung vor einem anderen Historieneintrag zu erstellen';
    },
    getUtcDate(date) {
      if (!date) {
        return null;
      }

      const utcDate = moment.utc(date, 'YYYY-MM-DD');
      return utcDate;
    },
  },
  computed: {
    allMandatoryQuestionsAnswered() {
      return this.answers.every(a => !a.mandatory || !!a.answer);
    },
    formattedDatum() {
      return this.datum
        ? moment(this.getUtcDate(this.datum)).format('MMMM Do YYYY')
        : '';
    },
    letzterVerbrauch() {
      return this.ablesung
        ? this.ablesung - this.webAblesung.letzteAblesung
        : 0;
    },
    lastHistoryMoment() {
      if (!this.lastHistoryDate) {
        return null;
      }

      const result = moment(this.lastHistoryDate);

      return result.isValid ? result : null;
    },
  },
  data() {
    return {
      datum: moment().format('YYYY-MM-DD'),
      datumMenu: false,
      saving: false,
      valid: true,
      ablesung: this.webAblesung.letzteAblesung,
      nachkontrolle: false,
      wayOfReading: null,
      wayOfreadingValues: ['Schriftlich', 'Telefonisch', 'Mail', 'Wassermeister'],
      datumRules: [
        v => !!v || 'Datum kann nicht leer sein',
        v => this.validateDatumIsAfterLastEntry(v),
      ],
      ablesungRules: [
        v => !!v || 'Der Wasserzählerstand kann nicht negativ sein',
        v => this.validateAblesungIsPositive(v),
      ],
      answers: [],
    };
  },
};
</script>
