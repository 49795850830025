<script>
import store from '@/store';

import K5Export from './K5Export';
import GeorgExport from './GeorgExport';

export default {
  functional: true,

  render(createElementFnc, ctx) {
    const isK5Enabled = store.state.importType === 'K5';

    if (isK5Enabled) {
      return createElementFnc(K5Export, ctx.data, ctx.children);
    }

    return createElementFnc(GeorgExport, ctx.data, ctx.children);
  },
};
</script>
