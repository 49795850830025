<template>
  <v-form v-model="valid">
    <v-card>
      <v-card-title>
        <h2>Ablesung Korrigieren</h2>
      </v-card-title>
      <v-card-text>
        <h3>{{ablesungDate}}</h3>
        <date-picker v-model="dateOfReading" label="Wann wurde die Ablesung gemeldet?" :allowed-dates="allowedDates" />
        <v-combobox
          v-model="wayOfReading"
          :items="wayOfreadingValues"
          label="Wie wurde die Ablesung gemeldet?"
        ></v-combobox>
        <v-text-field
          v-model="letzteAblesung"
          :rules="letzteAblesungRules"
          label="Letzte Ablesung"
          type="number"
          min="0"
        />
        <h4>Letzter Verbrauch: {{ letzterVerbrauch }}</h4>
      </v-card-text>
      <v-card-actions>
        <v-btn color="default" text @click="doCancel">Abbrechen</v-btn>
        <v-btn
          color="primary"
          @click="doConfirm"
          :disabled="!valid"
          :loading="saving"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>

import moment from 'moment';

export default {
  name: 'EditAblesung',
  props: {
    ablesung: {
      type: Object,
      required: true,
    },
  },
  mounted() {
  },
  methods: {
    doConfirm() {
      this.saving = true;
      this.$emit('confirm', { ablesung: this.letzteAblesung, wayOfReading: this.wayOfReading, dateOfReading: this.dateOfReading });
    },
    doCancel() {
      this.$emit('cancel');
    },
    validateLetzteAblesungIsPositive(v) {
      return (!!v && v >= 0) || 'Letzte Ablesung kann nicht negativ sein';
    },
    getWayOfReading(ablesung) {
      const hasWayOfReading = ablesung && ablesung.metadata && ablesung.metadata.WayOfReading;
      return hasWayOfReading ? ablesung.metadata.WayOfReading : null;
    },
    allowedDates(val) {
      return moment(this.ablesung.date).isSameOrBefore(val, 'day') && moment().isSameOrAfter(val, 'day');
    },
  },
  computed: {
    ablesungDate() {
      return moment(this.ablesung.date).format('MMMM Do YYYY, HH:mm');
    },
    letzterVerbrauch() {
      return this.letzteAblesung
        ? this.letzteAblesung - this.previousReading
        : null;
    },
  },
  data() {
    return {
      saving: false,
      previousReading:
        this.ablesung.wasserzaehler.letzteAblesung - this.ablesung.wasserzaehler.letzterVerbrauch,
      letzteAblesung: this.ablesung.wasserzaehler.letzteAblesung,
      wayOfReading: this.getWayOfReading(this.ablesung),
      wayOfreadingValues: ['Schriftlich', 'Telefonisch', 'Mail'],
      valid: false,
      letzteAblesungRules: [
        v => !!v || 'Zählerstand darf nicht leer sein',
        v => this.validateLetzteAblesungIsPositive(v),
      ],
      dateOfReading: this.ablesung.date,
    };
  },
};
</script>
