<script>

import { Line } from 'vue-chartjs';
import moment from "moment";

export default {
  extends: Line,
  name: 'VerbrauchChart',
  props: {
    verbrauchData: Array,
  },
  computed: {
    displayMonths() {
      const min = moment.min(this.verbrauchData.map(c => moment(c.date)));
      const max = moment.max(this.verbrauchData.map(c => moment(c.date)));
      const duration = moment.duration(max.diff(min));
      return duration.asYears() < 2;
    },
    labels() {
      const min = moment.min(this.verbrauchData.map(c => c.date));
      const max = moment.max(this.verbrauchData.map(c => c.date));
      const format = this.displayMonths ?
        (min.year() === max.year() ? "MMM" : "MMM YY")
        : "YYYY";
      return this.verbrauchData.map(v => v.date.format(format));
    },
    datasets() {
      const data = this.verbrauchData.map(v => v.verbrauch);
      return [
        {
          label: 'Verbrauch',
          backgroundColor: 'rgba(66, 188, 211, 0.6)',
          data,
        },
      ];
    },
  },
  watch: {
    datasets() {
      /* eslint-disable no-underscore-dangle */
      this.$data._chart.destroy();
      this.renderChart({
        labels: this.labels,
        datasets: this.datasets,
      });
    },
  },
  mounted() {
    this.renderChart({
      labels: this.labels,
      datasets: this.datasets,
    });
  },
};
</script>
