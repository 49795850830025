<template>
  <v-container v-if="hasBeenFound">
    <v-row justify="space-between">
      <v-col cols="5">
        <v-card>
          <v-card-title class="d-flex justify-space-between">
            <h4>Verbrauch</h4>
            <span class="subtitle-2">{{ableseperiode.from | formatDate}} - {{ableseperiode.to | formatDate}}</span>
          </v-card-title>
          <v-divider></v-divider>
          <verbrauch-chart :height="150" :verbrauchData="statisticsData" />
        </v-card>
      </v-col>
      <v-col cols="5">
        <v-card>
          <v-card-title class="d-flex justify-space-between">
            <h4>Wasserzähler</h4>
            <span class="subtitle-2">{{ableseperiode.from | formatDate}} - {{ableseperiode.to | formatDate}}</span>
          </v-card-title>
          <v-divider></v-divider>
          <tausch-chart :height="150" :tauschData="statisticsData" />
        </v-card>
      </v-col>
    </v-row>
    <v-card class="mt-4">
      <v-card-title>
        <h2 class="headline">
          <v-icon>pin_drop</v-icon>
          Karte
        </h2>
      </v-card-title>
      <v-divider></v-divider>
      <div class="dashboard-map-container">
        <gmap-map
          :center="center"
          :zoom="zoom"
          style="width: 100%; height: 100%"
        >
          <gmap-info-window
            :options="infoOptions"
            :position="infoPosition"
            :opened="showInfoWindow"
          >
            <div>
              <h2>{{infoTitle}}</h2>
              <router-link v-bind:to="infoLinkDestination">
                {{ infoLinkText }}
              </router-link>
            </div>
          </gmap-info-window>
          <gmap-cluster
            :zoomOnClick="true"
          >
            <gmap-marker
              :key="i"
              v-for="(m,i) in mapData"
              :position="m.position"
              :title="m.label"
              :icon="m.icon"
              :clickable="true"
              :draggable="false"
              @click="toggleInfoWindow(m)"
            />
          </gmap-cluster>
        </gmap-map>
      </div>
    </v-card>
    <activity class="mt-4" />
  </v-container>
</template>

<script>

import wasserparkApi from '../wasserparkApi';
import VerbrauchChart from './VerbrauchChart';
import TauschChart from './TauschChart';
import Activity from './Activity';
import {mapState} from "vuex";
import moment from "moment";

const console = window.console;

export default {
  name: 'Dashboard',
  components: {
    VerbrauchChart,
    TauschChart,
    Activity,
  },
  mounted() {
    this.reloadData();
  },
  watch: {
    ableseperiode: {
      handler: 'reloadData',
      deep: true,
    }
  },
  computed: {
    hasBeenFound() {
      return !!this.stats;
    },
    statisticsData() {
      let data = this.stats.historie;

      const min = moment.min(this.stats.historie.map(c => moment(c.date)));
      const max = moment.max(this.stats.historie.map(c => moment(c.date)));
      const duration = moment.duration(max.diff(min));
      const displayMonths = duration.asYears() < 2;

      if(!displayMonths){
        const groupedByYear = data.reduce((grouped, curr) => {
          const currentDate = moment(curr.date);
          const date = moment.utc({year: currentDate.year(), month: 0, date: 1}).toISOString();
          const entry = grouped[date] ?? {
            date: moment({year: currentDate.year(), month: 0, date: 1}),
            verbrauch: 0,
            wasserzaehlerAblesung: 0,
            wasserzaehlerTausch: 0,
          };
          entry.verbrauch += curr.verbrauch;
          entry.wasserzaehlerAblesung += curr.wasserzaehlerAblesung;
          entry.wasserzaehlerTausch += curr.wasserzaehlerTausch;
          // eslint-disable-next-line no-param-reassign
          grouped[date] = entry;
          return grouped;
        }, {})
        data = Object.values(groupedByYear);
      }
      return data.map(v => ({...v, date: moment(v.date)}));
    },
    ...mapState(['showAllWatermeters', 'tauschperiode', 'ableseperiode']),
  },
  data: () => ({
    stats: null,
    mapData: [],
    showInfoWindow: false,
    infoTitle: '',
    infoLinkText: '',
    infoLinkDestination: '',
    infoPosition: { lat: -1, lng: -1 },
    infoOptions: {
      pixelOffset: { width: 0, height: -35 },
    },
    center: {
      lat: 0.0,
      lng: 0.0,
    },
    zoom: 14,
  }),
  methods: {
    getMarkerIcon(marker) {
      if (marker.nachkontrolle) {
        return '/static/img/marker-orange.png';
      }
      return marker.hasBeenReplaced ? '/static/img/marker-gray.png' : '/static/img/marker.png';
    },
    toggleInfoWindow(marker) {
      if (
        (this.infoPosition.lat === marker.position.lat) &&
        (this.infoPosition.lat === marker.position.lat)
      ) {
        this.showInfoWindow = !this.showInfoWindow;
        return;
      }
      this.infoPosition.lat = marker.position.lat;
      this.infoPosition.lng = marker.position.lng;
      this.infoTitle = marker.label;
      this.infoLinkText = marker.address;
      this.infoLinkDestination = `/Aufgabe/${marker.id}`;
      this.showInfoWindow = true;
    },
    reloadData() {
      this.stats = null;
      this.mapData = [];

      wasserparkApi
        .getDashboardStatistics(this.ableseperiode, this.showAllWatermeters)
        .then((r) => {
          this.stats = r.data;
        },
        response => console.error(response));

      wasserparkApi
        .getMapCenter()
        .then((r) => {
          this.center.lat = r.data.latitude;
          this.center.lng = r.data.longitude;
        });

      wasserparkApi
        .getDashboardMapData(this.tauschperiode, this.showAllWatermeters)
        .then((r) => {
          r.data.forEach((m) => {
            if (m.position) {
              this.mapData.push({
                id: m.id,
                address: m.address,
                label: m.label,
                icon: this.getMarkerIcon(m),
                position: {
                  lat: m.position.latitude,
                  lng: m.position.longitude,
                },
              });
            }
          });
        },
        response => console.error(response));
    },
  },
};
</script>

<style>
.dashboard-map-container {
  height: 550px;
  display: flex;
  flex-direction: column;
}
</style>
