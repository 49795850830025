<template>
  <v-form v-model="valid" ref="form">
    <v-card>
      <v-card-title class="blue lighten-5">
        <h4>Kundenablesung</h4>
        <v-spacer></v-spacer>
        <v-chip
          :color="statusColor"
          text-color="white"
        >
          <v-icon left>
            {{ statusIcon }}
          </v-icon>
          {{statusName}}
        </v-chip>
      </v-card-title>
      <v-card-text>
        <div>
          <v-row class="mt-4">
            <v-col>Datum:</v-col>
            <v-col cols="auto">{{ablesungDate}}</v-col>
          </v-row>
          <v-row class="mt-4">
            <v-col>Kunde:</v-col>
            <v-col cols="auto">{{customerReading.name}} {{customerReading.nachname}}</v-col>
          </v-row>
          <v-row class="mt-4">
            <v-col>Kundennummer:</v-col>
            <v-col cols="auto">{{customerReading.customerExternalId}}</v-col>
          </v-row>
          <v-row class="mt-4">
            <v-col>Zählernummer:</v-col>
            <v-col cols="auto">{{customerReading.zaehlernummer}}</v-col>
          </v-row>
          <v-row class="mt-4" v-if="!isEditable">
            <v-col>Ablesung:</v-col>
            <v-col cols="auto">{{customerReading.confirmedAblesung}}
            </v-col>
          </v-row>
          <v-row class="mt-4" v-if="!isEditable">
            <v-col>Verbrauch:</v-col>
            <v-col cols="auto">{{customerReading.confirmedVerbrauch}}</v-col>
          </v-row>

          <v-row class="mt-4" v-if="!isEditable">
            <v-col>Email:</v-col>
            <v-col cols="auto">{{customerReading.email}}</v-col>
          </v-row>

          <v-row  class="mt-4" v-for="a in answers" :key="a.question" justify="space-between">
            <v-col cols="6">
              {{a.question}}
            </v-col>
            <v-col cols="auto">
              <v-radio-group v-model="a.answer" row :readonly="!isEditable">
                <v-radio v-for="qa in a.answers" :key="qa" :label="qa" :value="qa" />
              </v-radio-group>
            </v-col>
          </v-row>
          <strong
            v-if="!allMandatoryQuestionsAnswered"
            class="red--text text--lighten-1"
          >
            Bitte beantworten Sie alle Fragen
          </strong>
        </div>

        <v-text-field
          v-if="isEditable"
          v-model="ablesung"
          :rules="ablesungRules"
          label="Ablesung"
          type="number"
          min="0"
        />
        <h4 v-if="isEditable">Verbrauch: {{ verbrauch }}</h4>

        <v-text-field
          v-if="isEditable"
          v-model="email"
          :rules="emailRules"
          label="Email"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn color="default" text @click="doCancel">Abbrechen</v-btn>
        <v-btn color="error" @click.stop="confirmDeletion = true" :loading="saving" v-if="isDeletable">Löschen</v-btn>
        <v-btn
          v-if="canRequestFollowupCheck"
          color="orange"
          @click="doFollowupCheck"
          :loading="saving"
          dark
        >
          Nachkontrolle
        </v-btn>
        <v-btn
          v-if="isEditable"
          color="primary"
          @click="confirmAblesung"
          :disabled="valid == false"
          :loading="saving"
        >
          Ablesung bestätigen
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="confirmDeletion" max-width="400">
      <v-card>
        <v-card-title class="headline">
          Kundenablesung Löschen?
        </v-card-title>
        <v-card-text>
          Sind Sie sicher, dass der Eintrag gelöscht werden soll?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="default" text @click="confirmDeletion = false">
            Abbrechen
          </v-btn>
          <v-btn color="error" @click="doDelete">
            Löschen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmRemoveNachkontrolle" max-width="500">
      <v-card>
        <v-card-title>Nachkontrolle entfernen?</v-card-title>
        <v-card-text>
          Diese Kundenablesung wurde mit "Nachkontrolle" markiert.
          Soll die Nachkontrolle der dazugehörigen Aufgabe entfernt werden?
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="doConfirm(false)">
            Nein
          </v-btn>
          <v-btn text @click="doConfirm(true)">
            Ja
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>

import moment from 'moment';
import customerReadingConstants from './customerReadingConstants';

export default {
  name: 'ConfirmCustomerReading',
  props: {
    customerReading: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.$refs.form.validate();
  },
  methods: {
    doFollowupCheck() {
      this.saving = true;
      this.$emit('followupCheck', this.letzteAblesung);
    },
    doDelete() {
      this.saving = true;
      this.confirmDeletion = false;
      this.$emit('delete');
    },
    confirmAblesung() {
      if (this.customerReading.status === 'FollowupCheckRequested') {
        this.confirmRemoveNachkontrolle = true;
        return;
      }
      this.doConfirm(false);
    },
    doConfirm(removeNachkontrolle) {
      this.confirmRemoveNachkontrolle = false;
      this.saving = true;
      const confirmation = {
        customerReading: this.customerReading,
        ablesung: this.ablesung,
        email: this.email,
        answers: this.answers,
        removeNachkontrolle
      };
      this.$emit('confirm', confirmation);
    },
    doCancel() {
      this.$emit('cancel');
    },
    isEmailValid(email) {
      // eslint-disable-next-line
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    validateAblesungIsPositive(v) {
      return (!!v && v >= 0) || 'Ablesung kann nicht negativ sein';
    },
    validateEmail(email) {
      return (!email || this.isEmailValid(email)) || 'Bitte geben Sie eine gültige Email Adresse ein';
    },
  },
  computed: {
    allMandatoryQuestionsAnswered() {
      return this.answers.every(a => !a.mandatory || !!a.answer);
    },
    isEditable() {
      return this.customerReading.status !== 'Confirmed' && this.customerReading.status !== 'Obsolete';
    },
    canRequestFollowupCheck() {
      return this.customerReading.status === 'Todo';
    },
    isDeletable() {
      return this.customerReading.status === 'Todo';
    },
    ablesungDate() {
      return moment(this.customerReading.date).format('MMMM Do YYYY, HH:mm');
    },
    verbrauch() {
      return this.customerReading.ablesung && this.ablesung
        ? (this.ablesung - this.customerReading.ablesung) + this.customerReading.verbrauch
        : null;
    },
    statusName() {
      return customerReadingConstants.getStatusNameFromValue(this.customerReading.status);
    },
    statusIcon() {
      return customerReadingConstants.getStatusIcon(this.statusName);
    },
    statusColor() {
      return customerReadingConstants.getStatusColor(this.statusName);
    },
  },
  data() {
    return {
      saving: false,
      initialVerbrauch: this.customerReading.verbrauch,
      ablesung: this.customerReading.ablesung,
      email: this.customerReading.email,
      answers: this.customerReading.answers || [],
      valid: false,
      status: customerReadingConstants.getStatusValue(this.customerReading.status),
      ablesungRules: [
        v => !!v || 'Zählerstand darf nicht leer sein',
        v => this.validateAblesungIsPositive(v),
      ],
      emailRules: [
        v => this.validateEmail(v),
      ],
      confirmDeletion: false,
      confirmRemoveNachkontrolle: false,
    };
  },
};
</script>
