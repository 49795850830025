export default {
  tenant: {
    name: 'sieghartskirchen',
  },
  googleMaps: {
    apiKey: 'AIzaSyDVmvk910X1FlA1xZbtkqy8a-XV7cXgGL8',
  },
  auth0: {
    authEndpoint: 'https://login.softwarepark.cc/login',
    refreshEndpoint: 'https://login.softwarepark.cc/refresh',
    audience: 'https://gemeindepark.eu.auth0.com/userinfo',
    clientId: 'Qn6k_GnXafrGEu2WzcWAzUATqkvW0yeD',
    domain: 'gemeindepark.eu.auth0.com',
    responseType: 'token',
    scope: 'openid profile email wasserpark',
  },
};
