// Needs to be the first thing we load
import 'babel-polyfill';

import * as VueGoogleMaps from 'vue2-google-maps';
import GmapCluster from 'vue2-google-maps/dist/components/cluster';

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import VueProgressiveImage from 'vue-progressive-image';
import VDateRange from 'vuetify-daterange-picker';
import 'vuetify-daterange-picker/dist/vuetify-daterange-picker.css';
import moment from 'moment';
import Chart from 'chart.js';
import App from './App';
import router from './router';
import store from './store';
import config from './config';
import vuetify from './plugins/vuetify';
import DateRangePicker from '@/components/common/DateRangePicker';
import DatePicker from '@/components/common/DatePicker';
import Notifications from 'vue-notification';

import './plugins/axios';
import './filters';

Chart.defaults.global.defaultFontSize = 18;

Vue.component('GmapCluster', GmapCluster);
Vue.component('date-range-picker', DateRangePicker);
Vue.component('date-picker', DatePicker);

moment.locale('de');
Vue.use(VDateRange);
Vue.use(VueGoogleMaps, {
  load: {
    key: config.googleMaps.apiKey,
    libraries: 'places',
  },
});
Vue.use(VueProgressiveImage);
Vue.use(Notifications);

Vue.config.productionTip = false;

/* eslint-disable no-new */

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app');
