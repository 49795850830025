<script>

import { Bar } from 'vue-chartjs';
import moment from "moment";

export default {
  extends: Bar,
  name: 'TauschChart',
  props: {
    tauschData: Array,
  },
  computed: {
    displayMonths() {
      const min = moment.min(this.tauschData.map(c => moment(c.date)));
      const max = moment.max(this.tauschData.map(c => moment(c.date)));
      const duration = moment.duration(max.diff(min));
      return duration.asYears() < 2;
    },
    labels() {
      const min = moment.min(this.tauschData.map(c => c.date));
      const max = moment.max(this.tauschData.map(c => c.date));
      const format = this.displayMonths ?
        (min.year() === max.year() ? "MMM" : "MMM YY")
        : "YYYY";
      return this.tauschData.map(v => v.date.format(format));
    }
  },
  watch: {
    tauschData: {
      handler() {
        this.$data._chart.destroy();
        this.createChart();
      },
    }
  },
  methods: {
    createChart() {
      const ablesung = this.tauschData.map(t => t.wasserzaehlerAblesung);
      const tausch = this.tauschData.map(t => t.wasserzaehlerTausch);

      const ablesungBackgroundColors = this.tauschData.map(() => 'rgba(54, 162, 235, 0.2)');
      const ablesungBorderColors = this.tauschData.map(() => 'rgba(54, 162, 235, 1)');

      const tauschBackgroundColors = this.tauschData.map(() => 'rgba(255, 99, 132, 0.2)');
      const tauschBorderColors = this.tauschData.map(() => 'rgba(255, 99, 132, 1)');

      this.renderChart({
        labels: this.labels,
        minValue: 0,
        datasets: [
          {
            label: 'Ablesung',
            data: ablesung,
            backgroundColor: ablesungBackgroundColors,
            borderColor: ablesungBorderColors,
            borderWidth: 1,
          },
          {
            label: 'Tausch',
            data: tausch,
            backgroundColor: tauschBackgroundColors,
            borderColor: tauschBorderColors,
            borderWidth: 1,
          },
        ],
      });
    },
  },
  mounted() {
    this.createChart();
  },
};
</script>
