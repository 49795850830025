<template>
  <v-container fluid>
    <v-toolbar color="white">
      <h2 class="headline ml-5"> Imports - K5</h2>
    </v-toolbar>
    <v-row class="mt-4">
      <v-col cols="8">
        <v-card>
          <v-card-title>
            <h2 class="headline">
              <v-icon>import_export</v-icon> Neuer Import
            </h2>
            <v-spacer />
            <v-btn color="success"
              class="white--text"
              @click="showManualView"
            >
              <v-icon left dark>info</v-icon>
              Anleitung
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-progress-circular
              v-if="saving"
              indeterminate
              color="primary"
            />
            <input
              v-if="!saving"
              type="file"
              ref="xmlUpload"
              accept=".xml"
              v-on:change="prepareFilesForUpload()"
            />
            <v-alert :value="hasMessage" :type="messageType">
              {{message}}
            </v-alert>

            <div v-if="hasPreparedFiles">
              <v-btn color="success"
                class="white--text"
                @click="handleFilesUpload('Neu')"
              >
                <v-icon left dark>cloud_upload</v-icon>
                Neu
                <v-icon left dark>input</v-icon>
              </v-btn>
              <v-btn
                color="primary"
                class="white--text"
                @click="handleFilesUpload('Update')"
              >
                <v-icon left dark>cloud_upload</v-icon>
                Update
                <v-icon left dark>autorenew</v-icon>
              </v-btn>
              <v-btn
                color="blue-grey"
                class="white--text"
                @click="handleFilesUpload('Ablesung')"
              >
                <v-icon left dark>cloud_upload</v-icon>
                Ablesung
                <v-icon left dark>picture_in_picture</v-icon>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-card class="mt-4">
      <v-card-title>
        <h2 class="headline">
          <v-icon>import_export</v-icon> Historie
        </h2>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row>
          <v-col cols="8">
            <xml-file-imports
                ref="xmlFileImports"
                @selected="selectImport"
                @unselected="unselectImport"
            />
          </v-col>
          <v-col cols="4">
            <xml-import-elements
                v-if="isXmlImportSelected"
                :id="selectedImport"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <vue-slideout-panel
        v-model="showManual"
        :widths="['85%']"
        closeHtml=""
        @close="showManual=false"
        :styles="[{},{},{}, {display: 'none'}]"
    >
      <import-manual
          @close="showManual=false"
      />
    </vue-slideout-panel>
  </v-container>
</template>

<script>

import VueSlideoutPanel from 'vue-slideout-panel';
import wasserparkApi from '../wasserparkApi';
import XmlFileImports from './imports/XmlFileImports';
import XmlImportElements from './imports/XmlImportElements';
import ImportManual from './imports/ImportManual';

const UploadResults = {
  ok: 'Ok',
  invalidRequest: 'InvalidRequest',
  invalidContextType: 'InvalidContentType',
  invalidFileFormat: 'InvalidFileFormat',
  importError: 'ImportError',
};

export default {
  name: 'K5Import',
  components: {
    XmlFileImports,
    XmlImportElements,
    VueSlideoutPanel,
    ImportManual,
  },
  mounted() {
  },
  methods: {
    prepareFilesForUpload() {
      const uploadedFiles = this.$refs.xmlUpload.files;
      const fileArray = [];
      for (let i = 0; i < uploadedFiles.length; i += 1) {
        fileArray.push(uploadedFiles[i]);
      }

      this.preparedFiles = fileArray;
    },
    handleFilesUpload(uploadType) {
      this.saving = true;

      if (!this.hasPreparedFiles) {
        this.saving = false;
        return;
      }

      wasserparkApi.uploadXmlImport(uploadType, this.preparedFiles).then((r) => {
        const result = r.data.result;
        this.saving = false;
        this.preparedFiles = [];

        if (result !== UploadResults.ok) {
          if (result === UploadResults.invalidContextType) {
            this.message = 'Invalid content type. Please upload valid XML file';
          } else if (result === UploadResults.invalidFileFormat) {
            this.message = 'Invalid file format. Please upload valid XML file';
          } else if (result === UploadResults.importError) {
            this.message = 'An unknown error occurred during the import';
          }
          this.success = false;
        } else {
          this.message = 'Datei erfolgreich hochgeladen';
          this.success = true;
          this.$refs.xmlFileImports.reloadData();
        }
      });
    },
    selectImport(id) {
      this.selectedImport = id;
    },
    unselectImport() {
      this.selectedImport = null;
    },
    showManualView() {
      this.showManual = true;
    },
  },
  computed: {
    isXmlImportSelected() {
      return !!this.selectedImport;
    },
    messageType() {
      return this.success ? 'success' : 'error';
    },
    hasMessage() {
      return !!this.message;
    },
    hasPreparedFiles() {
      return this.preparedFiles && this.preparedFiles.length > 0;
    },
  },
  data() {
    return {
      showManual: false,
      saving: false,
      message: null,
      success: false,
      selectedImport: null,
      preparedFiles: [],
    };
  },
};
</script>
