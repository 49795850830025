<template>
  <v-form v-model="valid">
    <v-card>
      <v-card-title>
        <h2>Zählerdaten Korrigieren</h2>
      </v-card-title>
      <v-card-text>
        <v-row justify="space-between">
          <v-col cols="5">
            <v-text-field
              v-model="zaehlerNummer"
              :rules="zaehlerNummerRules"
              label="Zählernummer"
            />

            <v-text-field
              v-model="baujahr"
              :rules="baujahrRules"
              label="Baujahr"
              type="number"
              min="0"
            />

            <v-text-field
              v-model="eichjahr"
              :rules="eichjahrRules"
              label="Eichjahr"
              type="number"
              min="0"
            />
          </v-col>
          <v-col cols="5">
            <v-select
              :items="herstellerOptions"
              v-model="hersteller"
              label="Hersteller"
              :rules="herstellerRules"
            />
            <v-text-field
              v-model="nenndurchfluss"
              :rules="nenndurchflussRules"
              label="Nenndurchfluss"
              type="number"
              min="0"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="default" text @click="doCancel">Abbrechen</v-btn>
        <v-btn
          color="primary"
          @click="doConfirm"
          :disabled="!valid"
          :loading="saving"
        >
          Korrigieren
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>

import _ from 'lodash';

export default {
  name: 'EditTausch',
  props: {
    wasserzaehler: {
      type: Object,
      required: true,
    },
    allHerstellerOptions: Array,
  },
  mounted() {
  },
  methods: {
    doConfirm() {
      this.saving = true;
      const data = {
        zaehlerNummer: this.zaehlerNummer,
        baujahr: this.baujahr,
        eichjahr: this.eichjahr,
        hersteller: this.hersteller,
        nenndurchfluss: this.nenndurchfluss,
      };

      this.$emit('confirm', data);
    },
    doCancel() {
      this.$emit('cancel');
    },
    validateNumberIsPositive(v, errorMessage) {
      return (!!v && v >= 0) || errorMessage;
    },
    validateLetzteAblesungIsPositive(v) {
      return (!!v && v >= 0) || 'Letzte Ablesung kann nicht negativ sein';
    },
  },
  computed: {
    herstellerOptions() {
      return this.wasserzaehler.hersteller ?
        _.union(this.allHerstellerOptions, [this.wasserzaehler.hersteller]) :
        this.allHerstellerOptions;
    },
    contactOptions() {
      const result = [];
      if (this.emailContact) {
        result.push(this.emailContact);
      }

      result.push('Brief');
      result.push('Keine');

      return result;
    },
  },
  data() {
    return {
      saving: false,
      zaehlerNummer: this.wasserzaehler.externalId,
      baujahr: this.wasserzaehler.baujahr,
      eichjahr: this.wasserzaehler.eichjahr,
      hersteller: this.wasserzaehler.hersteller,
      nenndurchfluss: this.wasserzaehler.nenndurchfluss,
      valid: true,
      zaehlerNummerRules: [
        v => !!v || 'Zählernummer darf nicht leer sein',
      ],
      herstellerRules: [
        v => !!v || 'Hersteller darf nicht leer sein',
      ],
      baujahrRules: [
        v => !!v || 'Baujahr darf nicht leer sein',
        v => this.validateNumberIsPositive(v, 'Baujahr kann nicht negativ sein'),
      ],
      eichjahrRules: [
        v => !!v || 'Eichjahr darf nicht leer sein',
        v => this.validateNumberIsPositive(v, 'Eichjahr kann nicht negativ sein'),
      ],
      nenndurchflussRules: [
        v => !!v || 'Nenndurchfluss darf nicht leer sein',
        v => this.validateNumberIsPositive(v, 'Nenndurchfluss kann nicht negativ sein'),
      ],
    };
  },
};
</script>
