<template>
  <v-form v-model="valid">
    <v-card>
      <v-card-title>
        <h2>Tauschnachtrag</h2>
      </v-card-title>
      <v-card-text>
        <v-row justify="space-between">
          <v-col cols="5">
            <v-menu
              v-model="datumMenu"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-on="on"
                  v-model="formattedDatum"
                  label="Datum"
                  hint="Datum"
                  prepend-icon="event"
                  :rules="datumRules"
                  persistent-hint
                  readonly
                />
              </template>
              <v-date-picker
                v-model="datum"
                no-title
                locale="de"
                :allowed-dates="allowedDates"
              />
            </v-menu>
            <v-text-field
              v-model="letzteAblesung"
              :rules="letzteAblesungRules"
              label="Zählerstand"
              type="number"
              min="0"
            />
            <h4>Letzter Verbrauch: {{ letzterVerbrauch }}</h4>
            <v-text-field
              v-model="zaehlerNummer"
              :rules="zaehlerNummerRules"
              label="Zählernummer Neu"
            />

            <v-text-field
              v-model="baujahr"
              :rules="baujahrRules"
              label="Baujahr"
              type="number"
              min="0"
            />

            <v-text-field
              v-model="eichjahr"
              :rules="eichjahrRules"
              label="Eichjahr"
              type="number"
              min="0"
            />
          </v-col>
          <v-col cols="5">
            <v-select
              :items="herstellerOptions"
              v-model="hersteller"
              label="Hersteller"
              :rules="herstellerRules"
            />
            <v-text-field
              v-model="nenndurchfluss"
              :rules="nenndurchflussRules"
              label="Nenndurchfluss"
              type="number"
              min="0"
            />

            <v-text-field
              v-model="anfangsstand"
              :rules="anfagsstandRules"
              label="Anfangsstand"
              type="number"
              min="0"
            />

            <v-select
              :items="contactOptions"
              v-model="kontakt"
              label="Kontakt"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="default" text @click="doCancel">Abbrechen</v-btn>
        <v-btn
          color="primary"
          @click="doConfirm"
          :disabled="valid == false"
          :loading="saving"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>

import moment from 'moment';

export default {
  name: 'Tauchnachtrag',
  props: {
    wasserzaehlerAltLetzteAblesung: {
      type: Number,
      required: true,
    },
    emailContact: String,
    lastHistoryDate: String,
    herstellerOptions: Array,
  },
  mounted() {
  },
  methods: {
    allowedDates(val) {
      return moment() >= this.getUtcDate(val);
    },
    doConfirm() {
      this.saving = true;
      const tauchnachtrag = {
        wasserzaehlerAltLetzteAblesung: this.letzteAblesung,
        wasserzaehlerAltLetzterVerbrauch:
          this.letzteAblesung - this.wasserzaehlerAltLetzteAblesung,
        zaehlernummer: this.zaehlerNummer,
        baujahr: this.baujahr,
        eichjahr: this.eichjahr,
        hersteller: this.hersteller,
        nenndurchfluss: this.nenndurchfluss,
        anfangsstand: this.anfangsstand,
        kontakt: this.kontakt,
        datum: this.getUtcDate(this.datum),
      };
      this.$emit('confirm', tauchnachtrag);
    },
    doCancel() {
      this.$emit('cancel');
    },
    validateNumberIsPositive(v, errorMessage) {
      return (!!v && v >= 0) || errorMessage;
    },
    validateLetzteAblesungIsPositive(v) {
      return (v != null && v !== undefined && v >= 0) ||
        'Letzte Ablesung kann nicht negativ sein';
    },
    validateDatumIsAfterLastEntry() {
      const lastHistoryDate = this.lastHistoryMoment;
      if (!lastHistoryDate) {
        return true;
      }

      const datum = this.getUtcDate(this.datum);

      return datum > lastHistoryDate ||
        'Es ist nicht möglich einen Tausch vor einem anderen Historieneintrag zu erstellen';
    },
    getUtcDate(date) {
      if (!date) {
        return null;
      }

      const utcDate = moment.utc(date, 'YYYY-MM-DD');
      return utcDate;
    },
  },
  computed: {
    formattedDatum() {
      return this.datum
        ? moment(this.getUtcDate(this.datum)).format('MMMM Do YYYY')
        : '';
    },
    contactOptions() {
      const result = [];
      if (this.emailContact) {
        result.push(this.emailContact);
      }

      result.push('Brief');
      result.push('Keine');

      return result;
    },
    letzterVerbrauch() {
      return this.letzteAblesung
        ? this.letzteAblesung - this.wasserzaehlerAltLetzteAblesung
        : 0;
    },
    lastHistoryMoment() {
      if (!this.lastHistoryDate) {
        return null;
      }

      const result = moment(this.lastHistoryDate);

      return result.isValid ? result : null;
    },
  },
  data() {
    return {
      datum: moment().format('YYYY-MM-DD'),
      datumMenu: false,
      saving: false,
      zaehlerNummer: null,
      baujahr: null,
      eichjahr: null,
      hersteller: null,
      nenndurchfluss: null,
      anfangsstand: null,
      kontakt: null,
      letzteAblesung: this.wasserzaehlerAltLetzteAblesung,
      valid: false,
      datumRules: [
        v => !!v || 'Datum kann nicht leer sein',
        v => this.validateDatumIsAfterLastEntry(v),
      ],
      zaehlerNummerRules: [
        v => !!v || 'Zählernummer darf nicht leer sein',
      ],
      herstellerRules: [
        v => !!v || 'Hersteller darf nicht leer sein',
      ],
      baujahrRules: [
        v => !!v || 'Baujahr darf nicht leer sein',
        v => this.validateNumberIsPositive(v, 'Baujahr kann nicht negativ sein'),
      ],
      eichjahrRules: [
        v => !!v || 'Eichjahr darf nicht leer sein',
        v => this.validateNumberIsPositive(v, 'Eichjahr kann nicht negativ sein'),
      ],
      nenndurchflussRules: [
        v => !!v || 'Nenndurchfluss darf nicht leer sein',
        v => this.validateNumberIsPositive(v, 'Nenndurchfluss kann nicht negativ sein'),
      ],
      anfagsstandRules: [
        v => !!v || 'Anfangsstand darf nicht leer sein',
        v => this.validateNumberIsPositive(v, 'Anfangsstand kann nicht negativ sein'),
      ],
      letzteAblesungRules: [
        v => !!v || 'Zählerstand darf nicht leer sein',
        v => this.validateLetzteAblesungIsPositive(v),
      ],
    };
  },
};
</script>
