<template>
  <v-container>
    <v-toolbar color="white">
      <h2 class="headline ml-5"> Extremwerte definieren</h2>
    </v-toolbar>
    <v-row class="mt-4">
      <v-col cols="6">
        <v-card>
          <v-card-text>
            <v-form v-model="valid">
              <v-text-field
                append-icon="trending_up"
                label="Verbrauch (%)"
                placeholder="Enter Verbauch (%)"
                v-model="verbrauch"
                class="input-group--focused"
                :rules="verbrauchRules"
                mask="######"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              @click="updateExtremeVerbrauchSetting"
              :disabled="valid == false"
              :loading="saving"
            >
              Speichern
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import wasserparkApi from '../wasserparkApi';

export default {
  name: 'Extremwerte',
  mounted() {
    wasserparkApi
      .getExtremwerte()
      .then((r) => {
        this.verbrauch = r.data.verbrauchPercent;
      },
      response => window.console.error(response));
  },
  methods: {
    updateExtremeVerbrauchSetting() {
      this.saving = true;

      const extremwerte = {
        verbrauchPercent: this.verbrauch,
      };

      wasserparkApi.saveExtremwerte(extremwerte).then((r) => {
        this.saving = false;

        if (!r.data) {
          return;
        }

        this.verbrauch = r.data.verbrauchPercent;
      });
    },
  },
  data() {
    return {
      saving: false,
      verbrauch: null,
      valid: false,
      verbrauchRules: [
        v => !!v || 'Please select amount',
      ],
    };
  },
};
</script>
