<template>
  <v-row justify="space-between">
    <v-col cols="5">
      <zaehler-daten :wasserzaehler="historie.wasserzaehlerAlt" title="Zählerdaten Alt" />
    </v-col>
    <v-col cols="5">
      <zaehler-daten :wasserzaehler="historie.wasserzaehler" title="Zählerdaten Neu" />
    </v-col>
  </v-row>
</template>

<script>

import ZaehlerDaten from "@/components/common/ZaehlerDaten";
export default {
  name: 'WatermeterChangeHistorieView',
  components: {ZaehlerDaten},
  props: ['historie'],
};
</script>
