<template>
  <div>
    <div>
      Ablesung gemeldet von {{historie.kundenAblesung.kundeDisplayName}} am {{historie.kundenAblesung.date | formatDate('DD.MM.YYYY HH:mm')}}
    </div>
    <v-row justify="space-between">
      <v-col>
        <zaehler-daten :wasserzaehler="historie.wasserzaehler"/>
        <div v-if="hasAnswers" class="ml-2">
          <v-row v-for="a in historie.answers" :key="a.question" class="mt-4">
            <v-col>{{a.question}}</v-col>
            <v-col cols="auto">{{a.answer}}</v-col>
          </v-row>
        </div>
      </v-col>
      <v-col cols="5">
        <div>
          <h3>Zählerstand</h3>
          <div class="ml-2">
            <v-row class="mt-4">
              <v-col>Ablesung:</v-col>
              <v-col cols="auto">
                <CorrectedValue :initial-value="ablesungText" :actual-value="confirmedAblesungText" />
              </v-col>
            </v-row>
            <v-row class="mt-4">
              <v-col :class="{ 'red--text': historie.kundenAblesung.verbrauch < 0 }">Verbrauch:</v-col>
              <v-col :class="{ 'red--text': historie.kundenAblesung.verbrauch < 0 }" cols="auto">
                <CorrectedValue :initial-value="verbrauchText" :actual-value="confirmedVerbrauchText" />
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>
    <div v-if="historie.isEditable && aufgabe.isActive" class="d-flex justify-end">
      <v-btn
        color="info"
        class="white--text"
        @click="showEditDialog"
      >
        <v-icon left dark>edit</v-icon>
        Korrigieren
      </v-btn>
    </div>

    <ImageGallery :images="galleryImages" />

    <v-dialog v-model="showEditModal" :persistent="true" max-width="500px">
      <edit-ablesung
        v-if="showEditModal"
        :ablesung="historie"
        @confirm="onEditConfirmed"
        @cancel="onEditCanceled"
      />
    </v-dialog>
  </div>
</template>

<script>

import util from '@/util';
import urljoin from 'url-join';
import ImageGallery from '../common/ImageGallery';
import EditAblesung from '../aufgaben/EditAblesung';
import wasserparkApi from '../../wasserparkApi';
import ZaehlerDaten from "@/components/common/ZaehlerDaten";

import authUtils from '@/security/utils';
import CorrectedValue from "@/components/common/CorrectedValue";
const fotosUrlBase = util.getApiUrl();

export default {
  components: {
    CorrectedValue,
    ZaehlerDaten,
    ImageGallery,
    EditAblesung,
  },
  props: ['historie', 'aufgabe'],
  computed: {
    hasAnswers() {
      return this.historie.answers && this.historie.answers.length > 0;
    },
    wayOfReading() {
      return this.historie.wayOfReading;
    },
    verbrauchText () {
      return `${this.historie.kundenAblesung.verbrauch} m³`;
    },
    confirmedVerbrauchText () {
      return `${this.historie.kundenAblesung.confirmedVerbrauch} m³`;
    },
    ablesungText () {
      return `${this.historie.kundenAblesung.ablesung} m³`;
    },
    confirmedAblesungText () {
      return `${this.historie.kundenAblesung.confirmedAblesung} m³`;
    },
    galleryImages() {
      if (!this.historie ||
        !this.historie.fotoGallerien ||
        (this.historie.fotoGallerien.length <= 0)) {
        return [];
      }

      return this.historie.fotoGallerien[0].fotos.map(f => ({
        thumbnail: urljoin(fotosUrlBase, f.vorschaubildUrl, `?api_key=${authUtils.getAccessToken()}`),
        image: urljoin(fotosUrlBase, f.url, `?api_key=${authUtils.getAccessToken()}`),
      }));
    },
  },
  methods: {
    onEditConfirmed(ablesungUpdate) {
      wasserparkApi.editBestaetigteKundenAblesung(
        this.aufgabe.id,
        this.historie.kundenAblesung.id,
        ablesungUpdate
      ).then((r) => {
        this.$emit('changed', r.data);
        this.showEditModal = false;
      });
    },
    onEditCanceled() {
      this.showEditModal = false;
    },
    showEditDialog() {
      this.showEditModal = true;
    },
  },
  data() {
    return {
      showEditModal: false,
    };
  },
};
</script>
