<template>
  <v-container>
    <v-toolbar color="white">
      <h2 class="headline ml-5"> Kundenablesungen</h2>
    </v-toolbar>
    <v-card class="mt-4">
      <v-card-text class="pt-3">
        <v-card-actions>
          <v-btn
            color="info"
            @click="removePagingAndFiltering"
          >
            <v-icon left dark>cancel</v-icon>
            Filter löschen
          </v-btn>
          <v-btn
            color="primary"
            :disabled="!anySelected"
            @click="confirmAllSelected"
          >
            <v-icon left dark>check</v-icon>
            Alle Ablesungen bestätigen
          </v-btn>
          <v-btn
            color="primary"
            @click="downloadKundenablesungenReport"
          >
            <v-icon left dark>print</v-icon>
            Report
          </v-btn>
        </v-card-actions>
        <v-data-table
          :headers="headers"
          :items="customerReadings"
          :server-items-length="totalItems"
          :options.sync="pagination"
          :items-per-page-options="[10]"
          :loading="loading"
          item-key="id"
          show-select
        >
          <template v-slot:[`header.data-table-select`]="{ on }">
            <th>
              <v-checkbox
                v-if="selectableItems.length > 0"
                primary
                v-model="allSelected"
                v-on="on"
              ></v-checkbox>
            </th>
          </template>
          <template v-slot:header="{ props }">
            <tr>
              <th
                v-for="header in props.headers"
                :key="header.text"
                class="px-1"
              >
                <filter-box
                  :placeholder="header.filterPlaceholder"
                  :initialValue="getFilterInitialValue(header.value)"
                  :type="header.filterType"
                  :selectItems="header.selectItems"
                  @filter="applyFilter(header.value, ...arguments)"
                  v-if="header.filterable"
                />
              </th>
            </tr>
          </template>
          <template v-slot:item="{ item }">
            <tr :active="item.selected" @click="selectItemRow(item)">
              <td>
                <v-checkbox
                  v-if="isSelectEnabled(item)"
                  primary
                  :input-value="item.selected"
                ></v-checkbox>
              </td>
              <td class="text-xs-center">
                <router-link v-bind:to="getDetailsLink(item)">
                  {{ item.customerExternalId }}
                </router-link>
              </td>
              <td class="text-xs-center">
                <router-link v-bind:to="getDetailsLink(item)">
                  {{ item.zaehlernummer }}
                </router-link>
              </td>
              <td class="text-xs-left">{{ item.name }}</td>
              <td class="text-xs-left">{{ item.nachname }}</td>
              <td class="text-xs-left">
              <span v-if="!isCompleted(item)">
                {{ item.ablesung }}
              </span>
                <corrected-value
                  v-else
                  :initialValue="item.ablesung"
                  :actualValue="item.confirmedAblesung"
                />
              </td>
              <td class="text-xs-left">
              <span v-if="!isCompleted(item)">
                {{ item.verbrauch }}
              </span>
                <corrected-value
                  v-else
                  :initialValue="item.verbrauch"
                  :actualValue="item.confirmedVerbrauch"
                />
              </td>
              <td class="text-xs-left">{{ formatDate(item.date) }}</td>
              <td class="text-xs-center">
                <v-btn
                  rounded
                  dark
                  :color="getStatusColor(getStatusNameFromValue(item.status))"
                  @click="selectCustomerReading(item)"
                >
                  <v-icon>
                    {{ getStatusIcon(getStatusNameFromValue(item.status)) }}
                  </v-icon>
                  {{ getStatusNameFromValue(item.status) }}
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="customerReadingSelected" :persistent="true" max-width="600px">
      <confirm-customer-reading
        v-if="customerReadingSelected"
        :customerReading="selectedCustomerReading"
        @cancel="cancelSelectedCustomerReading"
        @confirm="confirmSelectedCustomerReading"
        @followupCheck="createFollowupCheckForSelectedCustomerReading"
        @delete="deleteSelectedCustomerReading"
      />
    </v-dialog>
    <v-dialog v-model="showConfirmSelectAllMessage" :persistent="true" max-width="500px">
      <v-card>
        <v-card-text>
          <h3>Sind Sie sicher, dass Sie {{selectedCount}} Ablesungen bestätigen möchten?</h3>
        </v-card-text>
        <v-card-actions>
          <v-btn color="default" text @click="showConfirmSelectAllMessage = false">Nein</v-btn>
          <v-btn
            color="primary"
            :loading="savingMultipleItemsInProgress"
            text
            @click="doConfirmAllSelected"
          >
            Ja
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

import moment from 'moment';

import wasserparkApi from '../wasserparkApi';
import FilterBox from './common/FilterBox';
import ConfirmCustomerReading from './kundenAblesungen/ConfirmCustomerReading';
import CorrectedValue from './common/CorrectedValue';
import customerReadingConstants from './kundenAblesungen/customerReadingConstants';

const console = window.console;

const StatusInitialValue = 'Todo';

export default {
  name: 'CustomerReadingsPreviewList',
  components: {
    FilterBox,
    ConfirmCustomerReading,
    CorrectedValue,
  },
  watch: {
    pagination: {
      handler() {
        this.reloadData();
      },
      deep: true,
    },
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    confirmAllSelected() {
      this.showConfirmSelectAllMessage = true;
    },
    doConfirmAllSelected() {
      this.savingMultipleItemsInProgress = true;

      const readings = [];
      if (!this.allSelectableSelected) {
        this.customerReadings.forEach((x) => {
          if (x.selected) {
            readings.push({ id: x.id, ablesung: x.ablesung, email: x.email, answers: x.answers, removeNachkontrolle: false });
          }
        });
      }

      wasserparkApi
        .confirmMultipleCustomerReadings(this.allSelectableSelected, readings)
        .then(() => {
          this.savingMultipleItemsInProgress = false;
          this.showConfirmSelectAllMessage = false;
          this.selectedCustomerReading = null;
          this.reloadData();
        },
        response => console.error(response));
    },
    toggleAll() {
      if (!this.allSelectableSelected) {
        this.selectableItems.forEach((x) => {
          /* eslint-disable no-param-reassign */
          x.selected = true;
        });
      } else {
        this.selectableItems.forEach((x) => {
          /* eslint-disable no-param-reassign */
          x.selected = false;
        });
      }

    },
    selectItemRow(item) {
      if (!this.isSelectEnabled(item)) {
        return;
      }

      const element = this.customerReadings.find(x => x.id === item.id);
      if (element) {
        element.selected = !element.selected;
      }
    },
    isSelectEnabled(item) {
      return item.status === StatusInitialValue;
    },
    getDetailsLink(item) {
      return `/Aufgabe/${item.aufgabeId}`;
    },
    getStatusNameFromValue(value) {
      return customerReadingConstants.getStatusNameFromValue(value);
    },
    getStatusIcon(status) {
      return customerReadingConstants.getStatusIcon(status);
    },
    getStatusColor(status) {
      return customerReadingConstants.getStatusColor(status);
    },
    getFilterInitialValue(header) {
      return this.filters[header];
    },
    reloadData() {
      this.loading = true;
      const filters = { ...this.filters };
      filters.status = customerReadingConstants.getStatusValue(filters.status);

      const paginationQuery = {
        sortBy: this.pagination.sortBy[0] || 'kunde',
        descending: this.pagination.sortDesc[0] || false,
        page: this.pagination.page,
        rowsPerPage: this.pagination.itemsPerPage
      };

      wasserparkApi
        .loadCustomerReadingsPreview(paginationQuery, filters)
        .then((r) => {
          this.customerReadings = r.data.data.map(x => ({ selected: this.allSelected, ...x }));
          this.totalItems = r.data.count;
          this.loading = false;
        },
        response => console.error(response));
    },
    applyFilter(header, filter) {
      this.filters[header] = filter;
      this.reloadData();
    },
    formatDate(date) {
      return date ? moment(date).format('MMMM Do YYYY, HH:mm:ss') : '';
    },
    removePagingAndFiltering() {
      this.filters = {
        status: StatusInitialValue,
      };
      this.pagination = {
        sortBy: ['date'],
        sortDesc: [true],
        page: 1,
        itemsPerPage: 10,
        multiSort: false,
      };
    },
    isCompleted(item) {
      return item.status === 'Confirmed';
    },
    selectCustomerReading(item) {
      this.selectedCustomerReading = item;
    },
    cancelSelectedCustomerReading() {
      this.selectedCustomerReading = null;
    },
    confirmSelectedCustomerReading(confirmation) {
      wasserparkApi
        .confirmCustomerReading({ ...confirmation, id: this.selectedCustomerReading.id })
        .then(() => {
          this.selectedCustomerReading = null;
          this.reloadData();
        },
        response => console.error(response));
    },
    deleteSelectedCustomerReading() {
      wasserparkApi.deleteCustomerReading(this.selectedCustomerReading.id).then(() => {
        this.selectedCustomerReading = null;
        this.reloadData();
      },
      response => console.error(response));
    },
    createFollowupCheckForSelectedCustomerReading() {
      wasserparkApi
        .requestFollowupCheckForCustomerReading(this.selectedCustomerReading.id)
        .then(() => {
          this.selectedCustomerReading = null;
          this.reloadData();
        },
        response => console.error(response));
    },
    downloadKundenablesungenReport() {
      const url = wasserparkApi.getKundenablesungenHtmlReportUrl();
      window.open(url, '_blank');
    },
  },
  computed: {
    selectedCount() {
      return this.selectedItems.length;
    },
    selectableItems() {
      return this.customerReadings.filter(x => this.isSelectEnabled(x));
    },
    allSelectableSelected() {
      return this.anySelected && this.selectableItems.every(x => x.selected);
    },
    selectedItems() {
      return this.selectableItems.filter(x => x.selected);
    },
    anySelected() {
      return this.selectedItems.length > 0;
    },
    allSelected: {
      get() {
        return this.allSelectableSelected;
      },
      set() {
        this.toggleAll();
      },
    },
    customerReadingSelected() {
      return !!this.selectedCustomerReading;
    },
  },
  data: () => ({
    showConfirmSelectAllMessage: false,
    savingMultipleItemsInProgress: false,
    selectedCustomerReading: null,
    customerReadings: [],
    totalItems: 0,
    loading: false,
    filters: {
      status: StatusInitialValue,
    },
    pagination: {
      sortBy: ['date'],
      sortDesc: [true],
      page: 1,
      itemsPerPage: 10,
      multiSort: false,
    },
    headers: [
      {
        text: 'Kundennummer',
        align: 'left',
        sortable: true,
        value: 'kunde',
        filterable: true,
        filterPlaceholder: 'Kundennummer',
        filterType: 'text',
      },
      {
        text: 'Zählernummer',
        align: 'left',
        sortable: true,
        value: 'zaehlernummer',
        filterable: true,
        filterPlaceholder: 'Zählernummer',
        filterType: 'text',
      },
      {
        text: 'Vorname',
        value: 'name',
        sortable: true,
        filterable: true,
        filterPlaceholder: 'Vorname',
        filterType: 'text',
      },
      {
        text: 'Nachname',
        value: 'nachname',
        sortable: true,
        filterable: true,
        filterPlaceholder: 'Nachname',
        filterType: 'text',
      },
      {
        text: 'Ablesung',
        value: 'ablesung',
        sortable: true,
        filterable: true,
        filterPlaceholder: 'Ablesung',
        filterType: 'text',
      },
      {
        text: 'Verbrauch',
        value: 'verbrauch',
        sortable: true,
        filterable: true,
        filterPlaceholder: 'Verbrauch',
        filterType: 'text',
      },
      {
        text: 'Datum',
        value: 'date',
        filterable: true,
        filterPlaceholder: 'Datum',
        filterType: 'date',
      },
      {
        text: 'Status',
        value: 'status',
        sortable: true,
        filterable: true,
        filterPlaceholder: 'Status',
        filterType: 'select',
        selectItems: customerReadingConstants.statusMap.map(s => s.name),
      },
    ],
  }),
};
</script>
