var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-btn',{attrs:{"color":"info"},on:{"click":_vm.removePagingAndFiltering}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("cancel")]),_vm._v(" Filter löschen ")],1),_c('v-spacer'),_c('v-switch',{staticStyle:{"flex":"none"},attrs:{"label":_vm.activityLabel},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.aufgaben,"server-items-length":_vm.totalItems,"options":_vm.pagination,"items-per-page-options":[10],"loading":_vm.loading,"item-key":"zaehlernummer"},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('tr',_vm._l((props.headers),function(header){return _c('th',{key:header.text,staticClass:"px-2"},[(header.filterable)?_c('filter-box',{attrs:{"placeholder":header.filterPlaceholder,"initialValue":_vm.filters[header.value],"type":header.filterType,"selectItems":header.selectItems},on:{"filter":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.applyFilter.apply(void 0, [ header.value ].concat( argsArray ))}}}):_vm._e()],1)}),0)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"text-xs-center"},[_c('router-link',{attrs:{"to":_vm.getDetailsLink(item)}},[_vm._v(" "+_vm._s(item.customerExternalId)+" ")])],1),_c('td',{staticClass:"text-xs-center"},[_c('router-link',{attrs:{"to":_vm.getDetailsLink(item)}},[_vm._v(" "+_vm._s(item.zaehlernummer)+" ")])],1),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(item.name))]),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(item.nachname))]),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(item.ort))]),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(item.addresse))]),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(item.objektbezeichnung))]),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(item.nenndurchfluss)+" m"),_c('sup',[_vm._v("3")])]),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(_vm.formatDate(item.letzterTausch)))]),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(_vm.formatDate(item.letzteAblesung)))])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }