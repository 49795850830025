<template>
  <div class="gallery">
    <h3 v-if="title">{{ title }}</h3>
    <ul>
      <li :key="index" v-for="(image, index) in images">
        <a nohref @click="clickImage(index)">
          <img :src="image.thumbnail">
        </a>
      </li>
    </ul>
    <div class="gallery-overlay" v-if="overlayActive" @click.self="closeOverlay">
      <div class="image-holder">
        <a class="close" nohref @click="closeOverlay"><span>&times;</span></a>
        <progressive-img
          :src="images[selectedImageIndex].image"
          placeholder="/static/img/loading.gif"
          fallback="/static/img/image-not-found.jpg"
          :blur="0"
          no-reatio
        />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ImageGallery',
  props: {
    title: {
      type: String,
    },
    images: {
      type: Array,
    },
  },
  mounted() {
    const self = this;
    window.addEventListener('keydown', e => self.handleGlobalKeyDown(e));
  },
  methods: {
    clickImage(index) {
      this.overlayActive = true;
      this.selectedImageIndex = index;
    },
    closeOverlay() {
      this.overlayActive = false;
      this.selectedImageIndex = null;
    },
    nextImage() {
      if (this.selectedImageIndex === (this.images.length - 1)) {
        this.selectedImageIndex = 0;
      } else {
        this.selectedImageIndex += 1;
      }
    },
    prevImage() {
      if (this.selectedImageIndex === 0) {
        this.selectedImageIndex = (this.images.length - 1);
      } else {
        this.selectedImageIndex -= 1;
      }
    },
    handleGlobalKeyDown(e) {
      switch (e.keyCode) {
        case 37: this.prevImage(); break;
        case 39: this.nextImage(); break;
        case 27: this.closeOverlay(); break;
        default: break;
      }
    },
  },
  data: () => ({
    overlayActive: false,
    selectedImageIndex: null,
  }),
};
</script>

<style>
.gallery > ul {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  display: block;
}
.gallery > ul > li {
  display: inline-block;
  padding: 5px;
  background: ghostwhite;
  margin: 10px;
    img {
      display: block;
      width: 200px;
  }
}
.gallery-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.7);
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  z-index: 9999;
}
.image-holder {

  max-width: 800px;
  margin: 100px auto 0 auto;
  position: relative;
  max-height: 100vh;
}
.image-holder > img {
  max-width: 800px;
  cursor: pointer;
  box-sizing: border-box;
  display: block;
  max-height: 100vh
}
.image-holder > .close {
  color: white;
  right: 10px;
  top: 0;
  font-size: 30px;
  opacity: 0.6;
  z-index: 1000000;
  position: absolute;
  text-align: left;
  box-sizing: border-box;
}
.image-holder > .close:hover {
  opacity: 1;
}

</style>
