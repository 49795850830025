import $ from 'jquery';

import util from '@/util';
import Qs from "qs";
import axios from "axios";
import authUtils from '@/security/utils';

const apiUrl = util.getApiUrl();

function getVersion() {
  return axios.get(`${apiUrl}AppVersion`)
}

function getMapCenter() {
  return axios.get(
    `${apiUrl}Dashboard/Mapcenter`,
  );
}
function getAufgabeReportUrl(aufgabeId) {
  return `${apiUrl}Aufgaben/Report/${aufgabeId}?api_key=${authUtils.getAccessToken()}`;
}
function getTerminvorlageHtmlReportUrl(aufgabeId, userFilter, dateRange) {
  const query = $.param({
    aufgabeId,
    userFilter,
    ...dateRange,
    api_key: authUtils.getAccessToken(),
  });
  return `${apiUrl}Termine/ReportHtml?${query}`;
}
function getTauschReportUrl(aufgabeId, tauschId) {
  const query = $.param({
    tauschId,
    api_key: authUtils.getAccessToken(),
  });

  return `${apiUrl}Aufgaben/TauschReport/${aufgabeId}?${query}`;
}
function getAufgabeDetails(id) {
  return axios.get(
    `${apiUrl}Aufgaben/Details/${id}`
  );
}
function getAufgabeHistorie(id) {
  return axios.get(`${apiUrl}Aufgaben/Historie/${id}`);
}
function getDashboardMapData(activeYear, allWatermeters) {
  return axios.get(
    `${apiUrl}Dashboard/MapData/`,
    {
      params: {
        activeYear,
        allWatermeters,
      },
    },
  );
}
function getDashboardActivityData(count) {
  return axios.get(
    `${apiUrl}Dashboard/ActivityData/`,
    {
      params: {
        count,
      },
    },
  );
}
function getDashboardStatistics(dateRange, allWatermeters) {
  return axios.get(
    `${apiUrl}Dashboard/Statistics/`,
    {
      params: {
        ...dateRange,
        allWatermeters,
      },
    },
  );
}
function getUsers() {
  return axios.get(
    `${apiUrl}Users/GetAll/`,
  );
}
function undoLastHistoryEntry(aufgabeId) {
 return axios.delete(`${apiUrl}Aufgaben/UndoLastHistoryEntry/${aufgabeId}`)
}
function loadAufgaben() {
  return axios.get(
    `${apiUrl}Aufgaben`,
  );
}
function loadAufgabenPreview(active,
  activeYear,
  allWatermeters,
  ableseperiodeFrom,
  ableseperiodeTo,
  pagination,
  filters) {
  const previewParams = {
    active,
    activeYear,
    allWatermeters,
    ableseperiodeFrom,
    ableseperiodeTo,
    ...pagination,
    ...filters,
  };

  return axios.get(
    `${apiUrl}Aufgaben/Preview`,
    {
      params: {
        ...previewParams,
      },
      paramsSerializer(p) {
        return Qs.stringify(p, {arrayFormat: 'brackets', allowDots: true})
      }
    },
  );
}
function loadUnappointedTasks(filter) {
  return axios.get(
    `${apiUrl}Termine/UnappointedTasks/`,
    {
      params: {
        filter,
      },
    },
  );
}
function scheduleTausch(aufgabeId,
  date,
  appointedUserName,
  appointedUserEmail,
  terminart,
  anmerkung) {
  return axios.post(
    `${apiUrl}Termine/ScheduleTausch/`,
    {
      aufgabeId,
      date,
      appointedUserName,
      appointedUserEmail,
      terminart,
      anmerkung,
    },
  );
}
function unscheduleTausch(aufgabeId) {
  return axios.delete(
    `${apiUrl}Termine/UnscheduleTausch/`,
    {
      params: {
        aufgabeId,
      },
    },
  );
}
function getActiveAppointments(userFilter) {
  return axios.get(
    `${apiUrl}Termine/ActiveAppointments/`,
    {
      params: {
        userFilter,
      },
    },
  );
}
function getExtremwerte() {
  return axios.get(
    `${apiUrl}Extremwerte/Get/`,
  );
}
function saveExtremwerte(extremwerte) {
  return axios.post(
    `${apiUrl}Extremwerte/Save/`,
    {
      ...extremwerte,
    },
  );
}
function createTag(name) {
  return axios.post(
    `${apiUrl}Tags/Create/`,
    {
      name,
    },
  );
}
function deleteTag(id) {
  return axios.delete(
    `${apiUrl}Tags/Delete/`,
    {
      params: {
        id,
      },
    },
  );
}
function addTagValue(id, valueName) {
  return axios.put(
    `${apiUrl}Tags/AddValue/`,
    {
      id,
      valueName,
    },
  );
}
function removeTagValue(id, valueName) {
  return axios.put(
    `${apiUrl}Tags/RemoveValue/`,
    {
      id,
      valueName,
    },
  );
}
function getTags() {
  return axios.get(
    `${apiUrl}Tags/GetAll/`,
  );
}
function getTagValues(id) {
  return axios.get(
    `${apiUrl}Tags/GetValues/${id}`,
  );
}
function getTagValuesByName(name) {
  return axios.get(
    `${apiUrl}Tags/GetValuesByName/`,
    {
      params: {
        name,
      },
    },
  );
}
function setAufgabeTag(id, name, value) {
  return axios.put(
    `${apiUrl}Aufgaben/SetTag/`,
    {
      id,
      name,
      value,
    },
  );
}
function editAblesung(aufgabeId, wasserzaehlerId, model) {
  return axios.put(
    `${apiUrl}Aufgaben/${aufgabeId}/AblesungKorrigieren/${wasserzaehlerId}`,
    model,
  );
}
function editAblesungDate(aufgabeId, date) {
  return axios.put(
    `${apiUrl}Aufgaben/EditAblesungDate/`,
    {
      aufgabeId,
      date,
    },
  );
}
function editTausch(aufgabeId, newWatermeter, letzteAblesung, contactOption) {
  return axios.put(
    `${apiUrl}Aufgaben/EditTausch/`,
    {
      aufgabeId,
      wasserzaehlerNeu: newWatermeter,
      letzteAblesung,
      contactOption,
    },
  );
}
function editTauschDate(aufgabeId, date) {
  return axios.put(
    `${apiUrl}Aufgaben/EditTauschDate/`,
    {
      aufgabeId,
      date,
    },
  );
}
function editAufgabeContacts(aufgabeId, email, phone) {
  return axios.put(
    `${apiUrl}Aufgaben/EditContacts/`,
    {
      aufgabeId,
      email,
      phone,
    },
  );
}
function kundendatenKorrigieren(aufgabeId, kundendaten) {
  return axios.put(`${apiUrl}Aufgaben/KundendatenKorrigieren/${aufgabeId}`, kundendaten);
}
function wasserzaehlerKorrigieren(aufgabeId, wasserzaehler) {
  return axios.put(`${apiUrl}Aufgaben/WasserzaehlerKorrigieren/${aufgabeId}`, wasserzaehler);
}
function getSystemSettings() {
  return axios.get(
    `${apiUrl}SystemSettings/Get/`,
  );
}
function saveSystemSettings(systemSettings) {
  return axios.post(
    `${apiUrl}SystemSettings/Save/`,
    {
      ...systemSettings,
    },
  );
}
function loadAblesungExports(pagination) {
  const queryParams = {
    ...pagination,
  };

  return axios.get(
    `${apiUrl}Export/GetAllAblesung`,
    {
      params: {
        ...queryParams,
      },
    },
  );
}
function loadTauschExports(pagination) {
  const queryParams = {
    ...pagination,
  };

  return axios.get(
    `${apiUrl}Export/GetAllTausch`,
    {
      params: {
        ...queryParams,
      },
    },
  );
}
function downloadExport(id) {
  return axios.get(
    `${apiUrl}Export/Get/${id}`,
    {
      responseType: 'arraybuffer',
    },
  );
}
function createAndDownloadAblesungExport(range) {
  return axios.post(
    `${apiUrl}Export/CreateAblesung`,
    null,
    {
      params: range,
      responseType: 'arraybuffer',
    }
  );
}
function createAndDownloadTauschExport(range) {
  return axios.post(
    `${apiUrl}Export/CreateTausch`,
    null,
    {
      params: range,
      responseType: 'arraybuffer',
    }
  );
}
function createAndDownloadGeorgExport(importId) {
  return axios.post(
    `${apiUrl}GeorgExports/CreateExport/${importId}`,
    null,
    {
      responseType: 'blob',
    },
  );
}
function uploadTenantLogo(files) {
  const formData = new FormData();
  files.forEach((f, i) => {
    formData.append(`files[ + ${i} + ]`, f);
  });

  return axios.post(
    `${apiUrl}SystemSettings/UploadLogo`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
}
function uploadXmlImport(uploadType, files) {
  const formData = new FormData();
  files.forEach((f, i) => {
    formData.append(`files[ + ${i} + ]`, f);
  });

  return axios.post(
    `${apiUrl}Imports/Upload?uploadType=${uploadType}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
}
function uploadGeorgImport(uploadType, files) {
  const formData = new FormData();
  files.forEach((f, i) => {
    formData.append(`files[ + ${i} + ]`, f);
  });

  return axios.post(
    `${apiUrl}GeorgImports/Upload?uploadType=${uploadType}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
}
function deleteTenantLogo() {
  return axios.put(
    `${apiUrl}SystemSettings/DeleteLogo/`,
  );
}
function loadCustomerReadingsPreview(pagination, filters) {
  const previewParams = {
    ...pagination,
    ...filters,
  };

  return axios.get(
    `${apiUrl}CustomerReadings/Preview`,
    {
      params: {
        ...previewParams,
      },
    },
  );
}
function confirmMultipleCustomerReadings(confirmAll, readings) {
  return axios.put(
    `${apiUrl}CustomerReadings/ConfirmMultiple/`,
    {
      confirmAll,
      readings,
    },
  );
}
function confirmCustomerReading(customerReading) {
  return axios.put(
    `${apiUrl}CustomerReadings/Confirm/`,
    customerReading,
  );
}
function deleteCustomerReading(id) {
  return axios.delete(`${apiUrl}CustomerReadings/Delete/${id}`);
}
function requestFollowupCheckForCustomerReading(id) {
  return axios.put(
    `${apiUrl}CustomerReadings/RequestFolloupCheck/${id}`,
    {},
  );
}
function loadXmlFileImports(pagination) {
  const queryParams = {
    ...pagination,
  };

  return axios.get(
    `${apiUrl}Imports/GetAllXmlmports`,
    {
      params: {
        ...queryParams,
      },
    },
  );
}
function loadGeorgFileImports(pagination) {
  const queryParams = {
    ...pagination,
  };

  return axios.get(
    `${apiUrl}GeorgImports/GetAllImports`,
    {
      params: {
        ...queryParams,
      },
    },
  );
}
function loadGeorgConfirmedFileImports() {
  return axios.get(`${apiUrl}GeorgImports/GetAllConfirmedImports`);
}
function loadXmlImportsData(id, pagination, filters) {
  const queryParams = {
    ...pagination,
    ...filters,
  };

  return axios.get(
    `${apiUrl}Imports/GetAllXmlmportElements/${id}`,
    {
      params: {
        ...queryParams,
      },
    },
  );
}
function loadGeorgImportsData(id, pagination, filters) {
  const queryParams = {
    ...pagination,
    ...filters,
  };

  return axios.get(
    `${apiUrl}GeorgImports/GetAllImportElements/${id}`,
    {
      params: {
        ...queryParams,
      },
    },
  );
}
function downloadImport(id) {
  return axios.get(
    `${apiUrl}Imports/Download/${id}`,
    {
      responseType: 'arraybuffer',
    },
  );
}
function confirmImport(id) {
  return axios.put(
    `${apiUrl}Imports/Confirm/${id}`,
  );
}
function confirmGeorgImport(id) {
  return axios.put(
    `${apiUrl}GeorgImports/Confirm/${id}`,
  );
}
function confirmImportDelete(id) {
  return axios.put(
    `${apiUrl}Imports/ConfirmDelete/${id}`,
  );
}
function confirmGeorgImportDelete(id) {
  return axios.put(
    `${apiUrl}GeorgImports/ConfirmDelete/${id}`,
  );
}
function webAblesung(id,
  wasserzaehlerId,
  ablesung,
  verbrauch,
  nachkontrolle,
  datum,
  wayOfReading,
  answers) {
  return axios.post(
    `${apiUrl}Aufgaben/WebAblesung/`,
    {
      id,
      wasserzaehlerId,
      ablesung,
      verbrauch,
      nachkontrolle,
      datum,
      wayOfReading,
      answers,
    },
  );
}
function tauchnachtrag(tauchnachtragData) {
  return axios.post(
    `${apiUrl}Aufgaben/Tauchnachtrag/`,
    {
      ...tauchnachtragData,
    },
  );
}
function getKundenablesungenHtmlReportUrl() {
  const query = $.param({
    api_key: authUtils.getAccessToken(),
  });
  return `${apiUrl}CustomerReadings/Report?${query}`;
}
function getHerstellerList() {
  return axios.get(
    `${apiUrl}Wassermeter/Hersteller/`,
  );
}
function getCustomerPortalQuestions() {
  return axios.get(
    `${apiUrl}SystemSettings/CustomerPortalQuestions/`,
  );
}
function deleteAufgabe(aufgabeId) {
  return axios.delete(
    `${apiUrl}Aufgaben/Delete/${aufgabeId}`,
    {},
  );
}
function changeAufgabeActivity(aufgabeId, isActive) {
  return axios.put(
    `${apiUrl}Aufgaben/ChangeAufgabeActivity/`,
    {
      id: aufgabeId,
      isActive,
    },
  );
}

function uploadAufgabeFile(aufgabeId, data) {
  const formData = new FormData();
  formData.append('file', data.file);
  formData.append('name', data.name);
  return axios.put(`${apiUrl}Aufgaben/UploadFile/${aufgabeId}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
}

function deleteAufgabeFile(aufgabeId, fileId) {
  return axios.delete(`${apiUrl}Aufgaben/DeleteFile/${aufgabeId}/${fileId}`);
}

function editBestaetigteKundenAblesung(aufgabeId, kundenablesungId, model) {
  return axios.put(`${apiUrl}Aufgaben/${aufgabeId}/KundenAblesungHistorieKorrigieren/${kundenablesungId}`, model);
}

export default {
  getVersion,
  getMapCenter,
  getAufgabeReportUrl,
  getTerminvorlageHtmlReportUrl,
  getTauschReportUrl,
  getAufgabeDetails,
  getAufgabeHistorie,
  getDashboardMapData,
  getDashboardStatistics,
  getDashboardActivityData,
  getUsers,
  undoLastHistoryEntry,
  loadAufgaben,
  loadAufgabenPreview,
  loadUnappointedTasks,
  scheduleTausch,
  unscheduleTausch,
  getActiveAppointments,
  getExtremwerte,
  saveExtremwerte,
  createTag,
  deleteTag,
  addTagValue,
  removeTagValue,
  getTags,
  getTagValues,
  getTagValuesByName,
  setAufgabeTag,
  editAblesung,
  editAblesungDate,
  editTausch,
  editTauschDate,
  editAufgabeContacts,
  kundendatenKorrigieren,
  wasserzaehlerKorrigieren,
  getSystemSettings,
  saveSystemSettings,
  loadAblesungExports,
  loadTauschExports,
  downloadExport,
  createAndDownloadTauschExport,
  createAndDownloadAblesungExport,
  createAndDownloadGeorgExport,
  uploadTenantLogo,
  deleteTenantLogo,
  loadCustomerReadingsPreview,
  confirmCustomerReading,
  deleteCustomerReading,
  confirmMultipleCustomerReadings,
  requestFollowupCheckForCustomerReading,
  uploadXmlImport,
  uploadGeorgImport,
  loadXmlFileImports,
  loadGeorgFileImports,
  loadGeorgConfirmedFileImports,
  downloadImport,
  loadXmlImportsData,
  loadGeorgImportsData,
  confirmImport,
  confirmGeorgImport,
  confirmImportDelete,
  confirmGeorgImportDelete,
  webAblesung,
  tauchnachtrag,
  getKundenablesungenHtmlReportUrl,
  getHerstellerList,
  getCustomerPortalQuestions,
  deleteAufgabe,
  changeAufgabeActivity,
  uploadAufgabeFile,
  deleteAufgabeFile,
  editBestaetigteKundenAblesung,
};
