<template>
  <v-app v-if="isAuthenticated">
    <v-navigation-drawer
      fixed
      v-model="drawer"
      app
      :clipped="$vuetify.breakpoint.mdAndUp"
    >
      <v-toolbar flat class="transparent">
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <img :src="loggedInPicture" >
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{loggedInName}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-toolbar>
      <v-list>
        <v-divider />
        <v-list-item v-for="item in items" :key="item.text" @click="navigate(item)">
          <v-list-item-action>
            <v-icon>{{item.icon}}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
        <v-divider />
      <v-list three-line flat>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <h4 class="text-md-center mt-3">Tauschperiode</h4>
            </v-list-item-title>
            <v-select
              :items="years"
              v-model="tauschperiode"
              label="Jahr"
              single-line
              menu-props="auto"
              prepend-icon="date_range"
              placeholder="Tauschperiode"
              hide-details
            />
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <h4 class="text-md-center">Ableseperiode</h4>
            </v-list-item-title>
            <date-range-picker v-model="ableseperiode"/>
          </v-list-item-content>
        </v-list-item>

        <v-list-item-group>
          <v-list-item v-model="showAllWatermeters">
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox :input-value="active"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Alle Wasserzähler</v-list-item-title>
                <v-list-item-subtitle>Deaktiviere den Filter nach Tauschperiode</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      app
      color="gray lighten-4"
      :clipped-left="$vuetify.breakpoint.mdAndUp"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-app-bar-title>
        <img src="/static/img/wasserpark-logo.png" width="180" />
      </v-app-bar-title>
      <v-spacer />
      <v-progress-linear :active="!initialized" indeterminate absolute bottom/>
      <v-btn icon @click="logout">
        <v-icon>lock</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <router-view v-if="initialized"/>
      </v-container>
    </v-main>
    <v-footer app class="pa-3">
      <span class="subtitle-2">
        <strong>Version:</strong> {{ appversion }}
      </span>
      <v-spacer></v-spacer>
      <a target="_blank" class="copyright" href="http://www.softwarepark.cc">Softwarepark Services GmbH © {{ new Date().getFullYear() }}</a>
    </v-footer>
  </v-app>
</template>

<script>
import wasserparkApi from '../wasserparkApi';

const YearSelectionDelta = 5;

export default {
  props: ['auth'],
  async mounted() {
    if (!this.auth.isAuthenticated()) {
      this.auth.login();
      return;
    }
    const { data: version } = await wasserparkApi.getVersion();
    this.appversion = version;

    const { data } = await wasserparkApi.getSystemSettings();

    if (data && data.ablesungQueryFrom && data.ablesungQueryTo) {
      this.$store.commit('setAbleseperiode', {
        from: data.ablesungQueryFrom,
        to: data.ablesungQueryTo,
      });
    }

    if (data) {
      this.$store.commit('setImportType', data.importType);
    }
    this.initialized = true;
  },
  methods: {
    logout() {
      this.auth.logout();
      this.isAuthenticated = false;
      this.auth.login();
    },
    navigate(item) {
      this.$router.push(item.link);
    },
  },
  computed: {
    ableseperiode: {
      get() {
        return this.$store.state.ableseperiode;
      },
      set(range) {
        this.$store.commit('setAbleseperiode', range);
      },
    },
    tauschperiode: {
      get() {
        const year = this.$store.state.tauschperiode;
        return { value: year, text: year.toString() };
      },
      set(year) {
        this.$store.commit('setTauschperiode', year);
      },
    },
    showAllWatermeters: {
      get() {
        return this.$store.state.showAllWatermeters;
      },
      set(newValue) {
        this.$store.commit('setAllWatermeterSelection', newValue);
      },
    },
    years() {
      const currentYear = (new Date()).getFullYear();

      const result = [];
      for (let i = currentYear;
        i <= currentYear + YearSelectionDelta; i += 1) {
        result.push({ value: i, text: i.toString() });
      }

      return result;
    },
  },
  data() {
    return {
      isAuthenticated: this.auth.isAuthenticated(),
      loggedInName: this.auth.getLoggedInName(),
      loggedInPicture: this.auth.getLoggedInPicture(),
      drawer: null,
      switch1: false,
      items: [
        { icon: 'trending_up', text: 'Dashboard', link: '/' },
        { icon: 'update', text: 'Aufgaben', link: '/Aufgaben' },
        { icon: 'date_range', text: 'Termine', link: '/Termine' },
        { icon: 'filter_none', text: 'Kundenablesungen', link: '/Kundenablesungen' },
        { icon: 'error_outline', text: 'Extremwerte definieren', link: '/Extremwerte' },
        { icon: 'label', text: 'Tags', link: '/Tags' },
        { icon: 'import_export', text: 'Export', link: '/Export' },
        { icon: 'import_export', text: 'Import', link: '/Import' },
        { icon: 'settings', text: 'Settings', link: '/SystemSettings' },
      ],
      initialized: false,
      appversion: '',
    };
  },
};
</script>
