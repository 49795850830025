<template>
  <v-container>
    <v-toolbar color="white">
      <h2 class="headline ml-5"> Settings</h2>
    </v-toolbar>
    <v-row class="mt-4">
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title>
            <h2 class="headline">
              <v-icon>photo</v-icon> Logo
            </h2>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <img
              v-if="hasLogo"
              :src="logoUrl"
              height="125"
              class="grey darken-4"
            />
          </v-card-text>
          <v-card-actions>
            <v-btn class="mr-2" color="error" @click="deleteLogo" v-if="hasLogo">
              <v-icon>delete_forever</v-icon>
            </v-btn>
            <v-progress-circular
              class="mr-2"
              v-if="savingLogo"
              indeterminate
              color="primary"
            />
            <input
              v-if="!savingLogo"
              type="file"
              ref="fileUpload"
              accept=".png,.jpeg,.jpg"
              @change="handleFilesUpload()"
            />
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title>
            <h2 class="headline">
              <v-icon>import_export</v-icon> Import
            </h2>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-select
              :clearable="false"
              v-model="settings.importType"
              :items="importTypes"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title>
            <h2 class="headline">
              <v-icon>settings</v-icon> Terminvorlage
            </h2>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-text-field
              v-model="settings.terminvorlageHeader.title"
              label="Title"
              hint="Title"
            />
            <v-text-field
              v-model="settings.terminvorlageHeader.subtitle"
              label="Subtitle"
              hint="Subtitle"
            />
            <v-textarea
              v-model="settings.terminvorlageHeader.text"
              label="Text"
              hint="Text"
            />
            <v-row>
              <v-col cols="3">
                <v-text-field
                  v-model="settings.terminvorlageHeader.bearbeiter"
                  label="Bearbeiter"
                  hint="Bearbeiter"
                />
              </v-col>
              <v-col offset="1" cols="3">
                <v-text-field
                  v-model="settings.terminvorlageHeader.telefonnumer"
                  label="Telefonnumer"
                  hint="Telefonnumer"
                />
              </v-col>
              <v-col offset="1" cols="3">
                <v-text-field
                  v-model="settings.terminvorlageHeader.ort"
                  label="Ort"
                  hint="Ort"
                />
              </v-col>
            </v-row>
            <v-textarea
              v-model="settings.terminvorlageFooter.signatur"
              label="Signatur"
              hint="Signatur"
            />
          </v-card-text>
          <v-divider />
          <v-card-title>
            <h2 class="headline">
              <v-icon>mail</v-icon> Emailadresse
            </h2>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-text-field
              v-model="settings.backupEmailAddresses"
              label="Emailadresse für Kopieempfang"
              hint="Emailadresse für Kopieempfang"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title>
            <h2 class="headline">
              <v-icon>settings</v-icon> Ablesungvorlage
            </h2>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-textarea
              v-model="settings.ablesungvorlage"
              label="Ablesungvorlage"
              hint="Ablesungvorlage"
            />
          </v-card-text>
          <v-divider />
          <v-card-title>
            <h2 class="headline">
              <v-icon>picture_in_picture</v-icon> Ableseperiode
            </h2>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <date-range-picker v-model="ableseperiodeRange" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-btn
      class="mt-4"
      color="primary"
      @click="updateSystemSettings"
      :loading="saving"
    >
      Speichern
    </v-btn>

    <v-dialog
      v-model="showErrorDialog"
      max-width="290"
    >
      <v-card>
        <v-card-text>
          {{errorMessage}}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="showErrorDialog = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

import util from '@/util';
import urljoin from 'url-join';
import wasserparkApi from '../wasserparkApi';

import authUtils from '@/security/utils';

const fotosUrlBase = `${util.getApiUrl()}Fotos/Download`;

const OkResult = 'Ok';
// const InvalidRequestResult = 'InvalidRequest';
const InvalidContentTypeResult = 'InvalidContentType';
const ImageTooLargeResult = 'ImageTooLarge';
// const UnexpectedErrorResult = 'UnexpectedError';

export default {
  name: 'SystemSettings',
  mounted() {
    wasserparkApi
      .getSystemSettings()
      .then((r) => {
        this.applyModel(r.data);
      },
      response => window.console.error(response));
  },
  methods: {
    applyModel(data) {
      if (data) {
        this.settings.ablesungvorlage = data.ablesungvorlage;
        this.settings.logoImageId = data.logoImageId;
        this.settings.backupEmailAddresses = data.backupEmailAddresses;
        if (data.terminvorlageHeader) {
          this.settings.terminvorlageHeader = data.terminvorlageHeader;
        }
        if (data.terminvorlageFooter) {
          this.settings.terminvorlageFooter = data.terminvorlageFooter;
        }
        if (data.ablesungQueryFrom && data.ablesungQueryTo) {
          this.settings.ablesungQueryFrom = data.ablesungQueryFrom;
          this.settings.ablesungQueryTo = data.ablesungQueryTo;
        }
        if (data.importType) {
          this.settings.importType = data.importType;
        } else {
          this.settings.importType = 'K5';
        }
      }
    },
    updateSystemSettings() {
      this.saving = true;
      wasserparkApi.saveSystemSettings(this.settings).then((r) => {
        this.applyModel(r.data);
        this.$store.commit('setAbleseperiode', {
          from: this.settings.ablesungQueryFrom,
          to: this.settings.ablesungQueryTo,
        });
        this.$store.commit('setImportType', this.settings.importType);
        this.saving = false;
      });
    },
    handleFilesUpload() {
      this.savingLogo = true;

      const uploadedFiles = this.$refs.fileUpload.files;
      const fileArray = [];
      for (let i = 0; i < uploadedFiles.length; i += 1) {
        fileArray.push(uploadedFiles[i]);
      }

      if (!uploadedFiles) {
        this.savingLogo = false;
        return;
      }

      wasserparkApi.uploadTenantLogo(fileArray).then((r) => {
        const result = r.data.result;
        if (result !== OkResult) {
          this.processImageUploadErrorResponse(
            result,
            r.data.maxWidth,
            r.data.maxHeight);
        } else {
          this.errorMessage = null;
          this.showErrorDialog = false;
          this.settings.logoImageId = r.data.imageId;
        }

        this.savingLogo = false;
      });
    },
    deleteLogo() {
      this.savingLogo = false;
      wasserparkApi.deleteTenantLogo().then(() => {
        this.errorMessage = null;
        this.showErrorDialog = false;
        this.settings.logoImageId = null;
        this.savingLogo = false;
      });
    },
    processImageUploadErrorResponse(result, maxWidth, maxHeight) {
      if (result === InvalidContentTypeResult) {
        this.errorMessage = 'Es sind nur jpeg und png Bilder elraubt.';
      } else if (result === ImageTooLargeResult) {
        this.errorMessage = `Das Bild ist zu groß. Die Maximalgröße beträgt ${maxWidth} px mal ${maxHeight} px.`;
      } else {
        this.errorMessage = 'Error occurred while uploading image';
      }

      this.showErrorDialog = true;
    },
  },
  computed: {
    hasLogo() {
      return !!this.settings.logoImageId;
    },
    logoUrl() {
      if (!this.settings.logoImageId) {
        return null;
      }

      const result = urljoin(
        fotosUrlBase,
        this.settings.logoImageId,
        `?api_key=${authUtils.getAccessToken()}`,
      );

      return result;
    },
    ableseperiodeRange: {
      get() {
        const result = {
          from: this.settings.ablesungQueryFrom,
          to: this.settings.ablesungQueryTo,
        };

        return result;
      },
      set(value) {
        this.settings.ablesungQueryTo = value.to;
        this.settings.ablesungQueryFrom = value.from;
      },
    }
  },
  data() {
    return {
      showErrorDialog: false,
      errorMessage: null,
      savingLogo: false,
      saving: false,
      importTypes: ['K5', 'Georg'],
      settings: {
        ablesungvorlage: null,
        logoImageId: null,
        backupEmailAddresses: null,
        terminvorlageHeader: {
          title: null,
          subtitle: null,
          text: null,
          bearbeiter: null,
          telefonnumer: null,
        },
        terminvorlageFooter: {
          signatur: null,
        },
        ablesungQueryFrom: null,
        ablesungQueryTo: null,
        importType: 'K5',
      },
    };
  },
};
</script>
