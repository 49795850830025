import axios from 'axios';

axios.interceptors.request.use((conf) => {
  const authToken = localStorage.getItem('auth0.access_token');

  if (authToken === null) {
    return conf;
  }

  const { headers } = conf;
  headers.Authorization = `Bearer ${authToken}`;

  return conf;
});
