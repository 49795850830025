<template>
  <v-card>
    <v-card-title>
      <v-btn
        color="info"
        @click="removePagingAndFiltering"
      >
        <v-icon left dark>cancel</v-icon>
        Filter löschen
      </v-btn>

      <v-spacer />

      <v-switch
        style="flex: none;"
        :label="activityLabel"
        v-model="active"
      />
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="aufgaben"
      :server-items-length="totalItems"
      :options.sync="pagination"
      :items-per-page-options="[10]"
      :loading="loading"
      item-key="zaehlernummer"
    >
      <template v-slot:header="{ props }">
        <tr>
          <th
            v-for="header in props.headers"
            :key="header.text"
            class="px-2"
          >
            <filter-box
              :placeholder="header.filterPlaceholder"
              :initialValue="filters[header.value]"
              :type="header.filterType"
              :selectItems="header.selectItems"
              @filter="applyFilter(header.value, ...arguments)"
              v-if="header.filterable"
            />
          </th>
        </tr>
      </template>
      <template v-slot:item="{ item }">
        <tr>
          <td class="text-xs-center">
            <router-link :to="getDetailsLink(item)">
              {{ item.customerExternalId }}
            </router-link>
          </td>
          <td class="text-xs-center">
            <router-link :to="getDetailsLink(item)">
              {{ item.zaehlernummer }}
            </router-link>
          </td>
          <td class="text-xs-left">{{ item.name }}</td>
          <td class="text-xs-left">{{ item.nachname }}</td>
          <td class="text-xs-left">{{ item.ort }}</td>
          <td class="text-xs-left">{{ item.addresse }}</td>
          <td class="text-xs-left">{{ item.objektbezeichnung }}</td>
          <td class="text-xs-left">{{ item.nenndurchfluss }} m<sup>3</sup></td>
          <td class="text-xs-left">{{ formatDate(item.letzterTausch) }}</td>
          <td class="text-xs-left">{{ formatDate(item.letzteAblesung) }}</td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>

import moment from 'moment';

import wasserparkApi from '../wasserparkApi';
import FilterBox from './common/FilterBox';
import {mapState} from "vuex";

const console = window.console;

export default {
  name: 'AufgabenPreviewList',
  components: {
    FilterBox,
  },
  props: {
    watermeterTypeFilter: String,
  },
  watch: {
    active: 'reloadData',
    pagination: {
      handler: 'reloadData',
      deep: true,
    },
    watermeterTypeFilter: 'reloadData',
    ableseperiode: {
      handler: 'reloadData',
      deep: true,
    },
    tauschperiode: 'reloadData',
    showAllWatermeters: 'reloadData',
  },
  computed: {
    activityLabel() {
      return this.active ? 'Aktiv' : 'Inaktiv';
    },
    ...mapState(['tauschperiode', 'showAllWatermeters', 'ableseperiode']),
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    removePagingAndFiltering() {
      this.filters = {};
      this.pagination =  {
        sortBy: ['kunde'],
        sortDesc: [false],
        page: 1,
        itemsPerPage: 10,
        multiSort: false,
      };
    },
    getQueryParams() {
      return {
        activeYear: this.tauschperiode,
        allWatermeters: this.showAllWatermeters,
      };
    },
    reloadData() {
      const queryParams = this.getQueryParams();
      const paginationQuery = {
          sortBy: this.pagination.sortBy[0] || 'kunde',
          descending: this.pagination.sortDesc[0] || false,
          page: this.pagination.page,
          rowsPerPage: this.pagination.itemsPerPage > 0 ? this.pagination.itemsPerPage : null,
      };

      this.loading = true;
      wasserparkApi
        .loadAufgabenPreview(this.active,
          queryParams.activeYear,
          queryParams.allWatermeters,
          this.ableseperiode.from,
          this.ableseperiode.to,
          paginationQuery,
          {
            ...this.filters,
            watermeterType: this.watermeterTypeFilter
          })
        .then((r) => {
          this.aufgaben = r.data.data;
          this.totalItems = r.data.count;
          this.loading = false;
        },
        response => console.error(response));
    },
    applyFilter(header, filter) {
      this.filters[header] = filter;
      this.reloadData();
    },
    getDetailsLink(aufgabePreview) {
      return `/Aufgabe/${aufgabePreview.id}`;
    },
    formatDate(date) {
      return date ? moment(date).format('MMMM Do YYYY, HH:mm:ss') : '';
    },
  },
  data: () => ({
    active: true,
    aufgaben: [],
    totalItems: 0,
    loading: false,
    filters: {
    },
    pagination: {
      sortBy: ['kunde'],
      sortDesc: [false],
      page: 1,
      itemsPerPage: 10,
      multiSort: false,
    },
    headers: [
      {
        text: 'Kundennummer',
        align: 'left',
        sortable: true,
        value: 'kunde',
        filterable: true,
        filterPlaceholder: 'Kundennummer',
        filterType: 'text',
      },
      {
        text: 'Zählernummer',
        align: 'left',
        sortable: true,
        value: 'zaehlernummer',
        filterable: true,
        filterPlaceholder: 'Zählernummer',
        filterType: 'text',
      },
      {
        text: 'Vorname',
        value: 'name',
        sortable: true,
        filterable: true,
        filterPlaceholder: 'Vorname',
        filterType: 'text',
      },
      {
        text: 'Nachname',
        value: 'nachname',
        sortable: true,
        filterable: true,
        filterPlaceholder: 'Nachname',
        filterType: 'text',
      },
      {
        text: 'Ort',
        value: 'ort',
        sortable: true,
        filterable: true,
        filterPlaceholder: 'Ort',
        filterType: 'text',
      },
      {
        text: 'Straße',
        value: 'addresse',
        sortable: true,
        filterable: true,
        filterPlaceholder: 'Straße',
        filterType: 'text',
      },
      {
        text: 'Objektbezeichnung',
        value: 'objektbezeichnung',
        filterable: true,
        filterPlaceholder: 'Objektbezeichnung',
        filterType: 'text',
      },
      {
        text: 'Zählergröße',
        value: 'nenndurchfluss',
        filterable: true,
        filterPlaceholder: 'Zählergröße',
        filterType: 'number',
      },
      {
        text: 'Letzter Tausch',
        value: 'letzterTausch',
        filterable: true,
        filterPlaceholder: 'Datum',
        filterType: 'daterange',
      },
      {
        text: 'Letzte Ablesung',
        value: 'letzteAblesung',
        sortable: true,
        filterable: true,
        filterPlaceholder: 'Datum',
        filterType: 'daterange',
      },
    ],
  }),
};
</script>
