import axios from 'axios';
import config from '../config';

const console = window.console;

const axiosInstance = axios.create();

function extractLatLngFromResult(result) {
  if (!result || !result.results || result.results.length <= 0) {
    return null;
  }

  return {
    latitude: result.results[0].geometry.location.lat,
    longitude: result.results[0].geometry.location.lng,
  };
}
function geocode(address, cb) {
  if (!address) {
    if (cb) {
      cb(null);
    }
    return;
  }

  const geocodeParams = {
    address,
    key: config.googleMaps.apiKey,
  };

  axiosInstance.get(
    'https://maps.googleapis.com/maps/api/geocode/json',
    {
      params: {
        ...geocodeParams,
      },
    },
  )
    .then((r) => {
      if (cb) {
        cb(extractLatLngFromResult(r.data));
      }
    },
    response => console.error(response));
}

export default {
  geocode,
};
