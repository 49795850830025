<template>
  <div>
    <h3>{{title}}</h3>
    <div class="ml-2">
      <v-row class="mt-4">
        <v-col>Vorname:</v-col>
        <v-col cols="auto">
          <corrected-value
            v-if="displayChanges"
            :initialValue="value.zbvBriefansicht1"
            :actualValue="valueChange.zbvBriefansicht1"
          />
          <span v-else>{{value.zbvBriefansicht1}}</span>
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col>Nachname:</v-col>
        <v-col cols="auto">
          <corrected-value
            v-if="displayChanges"
            :initialValue="value.zbvBriefansicht2"
            :actualValue="valueChange.zbvBriefansicht2"
          />
          <span v-else>{{value.zbvBriefansicht2}}</span>
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col>Adresse:</v-col>
        <v-col cols="auto">
          <corrected-value
            v-if="displayChanges"
            :initialValue="value.zbvAdresse"
            :actualValue="valueChange.zbvAdresse"
          />
          <span v-else>{{value.zbvAdresse}}</span>
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col>Ort:</v-col>
        <v-col cols="auto">
          <corrected-value
            v-if="displayChanges"
            :initialValue="value.zbvOrt"
            :actualValue="valueChange.zbvOrt"
          />
          <span v-else>{{value.zbvOrt}}</span>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import CorrectedValue from "@/components/common/CorrectedValue";
export default {
  components: {CorrectedValue},
  props: {
    title: {
      type: String,
      default: 'ZBV'
    },
    value: Object,
    valueChange: Object,
  },
  computed: {
    displayChanges() {
      return !!(this.value && this.valueChange);
    },
  },
}
</script>
