<template>
  <v-container fluid>
    <div v-if="historie.isEditable" class="d-flex justify-end">
      <v-btn
        text
        small
        color="primary"
        @click="showDateChangeModal = true"
      >
        Datumskorrektur
      </v-btn>
    </div>
    <v-row row>
      <v-col class="pr-5">
        <zaehler-daten :wasserzaehler="historie.wasserzaehlerAlt" title="Zählerdaten Alt" />
      </v-col>
      <v-col class="pr-5">
        <zaehler-stand :wasserzaehler="historie.wasserzaehlerAlt" :verbrauch-warning="historie.wasserzaehlerAlt.letzterVerbrauch < 0" />
      </v-col>
      <v-col>
        <h3>Checkliste</h3>
        <div class="ml-2">
          <v-row v-for="(answer, question, i) in fragen" :key="i" class="mt-4">
            <v-col>{{question}}</v-col>
            <v-col cols="auto">{{answer}}</v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <ImageGallery :images="zaehlerdatenAltFotos" />
    <v-row class="mt-4">
      <v-col class="pr-5">
        <zaehler-daten :wasserzaehler="historie.wasserzaehler" title="Zählerdaten Neu" />
      </v-col>
      <v-col class="pr-5">
        <h3>Zählerstand</h3>
        <div class="ml-2">
          <v-row class="mt-4">
            <v-col>Anfangsstand:</v-col>
            <v-col cols="auto">{{historie.wasserzaehler.letzteAblesung}} m<sup>3</sup></v-col>
          </v-row>
        </div>
      </v-col>
      <v-col>
        <div class="ml-2">
          <v-row class="mt-4">
            <v-col>Kontakt:</v-col>
            <v-col cols="auto">{{contactOption}}</v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <ImageGallery :images="zaehlerdatenNeuFotos" />
    <v-row v-if="hasComment">
      <v-col cols="6">
        <h3>Kommentar</h3>
        <v-textarea
          readonly
          outline
          :value="comment"
        />
      </v-col>
    </v-row>
    <ImageGallery v-if="zusammenschlussFotos.length > 0" :images="zusammenschlussFotos" title="Zusammenschluss" />

    <div class="d-flex justify-end">
      <v-btn
        color="info"
        class="white--text"
        @click="downloadReport"
        :loading="downloadingReport"
      >
        <v-icon left dark>print</v-icon>
        Bericht
      </v-btn>
      <v-btn
        v-if="historie.isEditable && aufgabe.isActive"
        color="info"
        class="white--text"
        @click="showEditDialog"
      >
        <v-icon left dark>edit</v-icon>
        Korrigieren
      </v-btn>
    </div>
    <v-dialog v-model="showEditModal" :persistent="true" max-width="500px">
      <edit-tausch
        v-if="showEditModal"
        :tausch="historie"
        :emailContact="emailContact"
        :all-hersteller-options="herstellerOptions"
        @confirm="onEditConfirmed"
        @cancel="onEditCanceled"
      />
    </v-dialog>
    <v-dialog v-model="showDateChangeModal" :persistent="true" max-width="500px">
      <change-date
        v-if="showDateChangeModal"
        :initialDate="historie.date"
        :lastHistoryDate="previousHistoryDate"
        @confirm="onDateChangeConfirmed"
        @cancel="showDateChangeModal = false"
      />
    </v-dialog>
  </v-container>
</template>

<script>

import util from '@/util';
import urljoin from 'url-join';
import ImageGallery from '../common/ImageGallery';
import wasserparkApi from '../../wasserparkApi';
import ChangeDate from '../aufgaben/ChangeDate';
import EditTausch from '../aufgaben/EditTausch';
import ZaehlerDaten from "@/components/common/ZaehlerDaten";
import ZaehlerStand from "@/components/common/ZaehlerStand";

import authUtils from '@/security/utils';


const fotosUrlBase = util.getApiUrl();

export default {
  name: 'TauschHistorieView',
  components: {
    ZaehlerStand,
    ZaehlerDaten,
    ImageGallery,
    EditTausch,
    ChangeDate,
  },
  props: ['historie', 'aufgabe', 'previousHistoryDate', 'herstellerOptions'],
  computed: {
    hasComment() {
      return this.historie.kommentar;
    },
    comment() {
      return this.hasComment ? this.historie.kommentar : '';
    },
    fragen() {
      return this.historie.fragen;
    },
    contactOption() {
      return this.historie.contactOption;
    },
    zaehlerdatenAltFotos() {
      return this.findGalleryImages('Zählerdaten Alt');
    },
    zaehlerdatenNeuFotos() {
      return this.findGalleryImages('Zählerdaten Neu');
    },
    zusammenschlussFotos() {
      return this.findGalleryImages('Zusammenschluss');
    },
    emailContact() {
      return this.aufgabe ? this.aufgabe.email : null;
    },
  },
  methods: {
    downloadReport() {
      const url = wasserparkApi
        .getTauschReportUrl(this.aufgabe.id, this.historie.operationId);

      window.open(url, '_blank');
    },
    findGalleryImages(galleryName) {
      if (!this.historie ||
        !this.historie.fotoGallerien ||
        (this.historie.fotoGallerien.length <= 0) ||
        (this.historie.fotoGallerien.findIndex(g => g.name === galleryName) < 0)) {
        return [];
      }

      const galleryIndex = this.historie.fotoGallerien.findIndex(g => g.name === galleryName);

      return this.historie.fotoGallerien[galleryIndex].fotos.map(f => ({
        thumbnail: urljoin(fotosUrlBase, f.vorschaubildUrl, `?api_key=${authUtils.getAccessToken()}`),
        image: urljoin(fotosUrlBase, f.url, `?api_key=${authUtils.getAccessToken()}`),
      }));
    },
    onDateChangeConfirmed(date) {
      wasserparkApi.editTauschDate(
        this.aufgabe.id,
        date,
      ).then((r) => {
        this.$emit('changed', r.data);
        this.showDateChangeModal = false;
      });
    },
    onEditConfirmed(data) {
      const newWatermeter = {
        externalId: data.zaehlerNummer,
        hersteller: data.hersteller,
        letzteAblesung: data.anfagsstand,
        nenndurchfluss: data.nenndurchfluss,
        baujahr: data.baujahr,
        eichjahr: data.eichjahr,
      };
      wasserparkApi.editTausch(
        this.aufgabe.id,
        newWatermeter,
        data.letzteAblesung,
        data.kontakt,
      ).then((r) => {
        this.$emit('changed', r.data);
        this.showEditModal = false;
      });
    },
    onEditCanceled() {
      this.showEditModal = false;
    },
    showEditDialog() {
      this.showEditModal = true;
    },
  },
  data: () => ({
    showEditModal: false,
    showDateChangeModal: false,
    downloadingReport: false,
  }),
};
</script>

<style>
  .date-edit-container {
    position: absolute;
    right: 15px;
    top: -20px;
  }
</style>
