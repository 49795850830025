<template>
  <k5-import v-if="isK5" />
  <georg-import v-else />
</template>

<script>

import K5Import from './K5Import';
import GeorgImport from './GeorgImport';

export default {
  components: {
    K5Import,
    GeorgImport,
  },
  computed: {
    isK5() {
      return this.$store.state.importType === 'K5';
    },
  },
};
</script>
