<template>
    <v-toolbar color="white">
      <v-toolbar-items>
        <template v-for="item in userFilters">
          <v-btn
            :key="createItemKey('', item)"
            text
            :color="getColor(item)"
            @click="select(item)"
          >
            <span :style="getUserStyle(item)">
              <v-icon large>{{item.icon}}</v-icon>
            </span>
            <span>{{item.label}}</span>
          </v-btn>
          <v-btn
            v-if="item.allowClear"
            :key="createItemKey('clear', item)"
            text
            icon
            :color="getColor(item)"
            @click="removeUserFromList(item)"
          >
            <v-icon>highlight_off</v-icon>
          </v-btn>
        </template>
      </v-toolbar-items>
      <v-spacer />
      <v-select
        style="max-width: 16%"
        v-model="selectedUser"
        :items="users"
        item-value="email"
        item-text="name"
        @input="onUserSelected"
        prepend-icon="person"
        placeholder="Wassermeister"
        hide-details
      />
    </v-toolbar>
</template>

<script>
import util from '../../util';

export default {
  name: 'UserSelection',
  props: {
    users: Array,
    userFilters: Array,
  },
  methods: {
    createItemKey(prefix, item) {
      return `${prefix}-${item.label}`;
    },
    getColor(item) {
      return item.selected ? 'blue darken-3' : 'grey lighten-1';
    },
    getUserStyle(item) {
      if (!item.allowClear) {
        return null;
      }
      return `color: ${util.nameToColor(item.user.name || item.user.email)}`;
    },
    select(item) {
      const updated = this.userFilters.map(x => ({
        ...x,
        selected: x.label === item.label,
      }));
      this.$emit('filter', item.user?.email);
      this.$emit('update:userFilters', updated)
    },
    onUserSelected(userEmail) {
      this.$nextTick(() => {
        this.selectedUser = null;
      });

      const user = this.users.find(x => x.email === userEmail);
      if (this.userFilters.some(x => x.user && x.user.email === user.email)) {
        return;
      }

      this.$emit('update:userFilters', [
        ...this.userFilters,
        {
          label: user.name,
          icon: 'person',
          user,
          selected: false,
          allowClear: true,
        },
      ]);
    },
    removeUserFromList(user) {
      this.$emit('update:userFilters', this.userFilters.filter(x => x.label !== user.label));
    },
  },
  data: () => ({
    selectedUser: null,
  }),
};
</script>
