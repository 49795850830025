<template>
  <div>
    <h3>{{title}}</h3>
    <div class="ml-2">
      <v-row class="mt-4">
        <v-col>Strasse:</v-col>
        <v-col cols="auto">
          <corrected-value
            :initialValue="value.strasse"
            :actualValue="valueChange.strasse"
            v-if="displayChanges"
          />
          <span v-else>{{value.strasse}}</span>
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col>Hausnummer:</v-col>
        <v-col cols="auto">
          <corrected-value
            :initialValue="value.hausnummer"
            :actualValue="valueChange.hausnummer"
            v-if="displayChanges"
          />
          <span v-else>{{value.hausnummer}}</span>
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col>Restadresse:</v-col>
        <v-col cols="auto">
          <corrected-value
            :initialValue="value.restadresse"
            :actualValue="valueChange.restadresse"
            v-if="displayChanges"
          />
          <span v-else>
            {{value.restadresse}}
            <span v-if="value.nutzungseinheit">{{value.nutzungseinheit}}</span>
          </span>
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col>PLZ:</v-col>
        <v-col cols="auto">
          <corrected-value
            :initialValue="value.plz"
            :actualValue="valueChange.plz"
            v-if="displayChanges"
          />
          <span v-else>{{value.plz}}</span>
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col>Ort:</v-col>
        <v-col cols="auto">
          <corrected-value
            :initialValue="value.ort"
            :actualValue="valueChange.ort"
            v-if="displayChanges"
          />
          <span v-else>{{value.ort}}</span>
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col>Staat:</v-col>
        <v-col cols="auto">
          <corrected-value
            :initialValue="value.staat"
            :actualValue="valueChange.staat"
            v-if="displayChanges"
          />
          <span v-else>{{value.staat}}</span>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import CorrectedValue from "@/components/common/CorrectedValue";
export default {
  components: {CorrectedValue},
  props: {
    title: {
      type: String,
      default: 'Rechnungsadresse',
    },
    value: Object,
    valueChange: Object,
  },
  computed: {
    displayChanges() {
      return !!(this.value && this.valueChange);
    },
  },
}
</script>
