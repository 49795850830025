<script>
import moment from "moment";

export default {
  name: "DatePicker",
  props: {
    value: [String, Object],
    label: {
      type: String,
      default: 'Datum'
    },
    rules: [Array, Object],
    allowedDates: [Function]
  },
  data: () => ({
    datumMenu: false,
  }),
  computed: {
    datumModel: {
      get() {
        return moment(this.value).format('YYYY-MM-DD');
      },
      set(v) {
        this.$emit('input', v);
      }
    },
    formattedDatum() {
      return this.value
        ? moment(this.getUtcDate(this.value)).format('MMMM Do YYYY')
        : '';
    },
  },
  methods: {
    getUtcDate(date) {
      if (!date) {
        return null;
      }

      const utcDate = moment.utc(date, 'YYYY-MM-DD');
      return utcDate;
    },
  }
}
</script>

<template>
  <v-menu
    v-model="datumMenu"
    full-width
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="formattedDatum"
        :label="label"
        hint="Datum"
        prepend-icon="event"
        :rules="rules"
        persistent-hint
        readonly
        v-on="on"
      />
    </template>
    <v-date-picker
      v-model="datumModel"
      no-title
      locale="de"
      :allowed-dates="allowedDates"
    />
  </v-menu>
</template>

<style scoped>

</style>
