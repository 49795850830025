<template>
  <div>
    <v-row>
      <v-col cols="4">
        <v-list two-line subheader>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ data.tauschAufgabe.ouIdent }}</v-list-item-title>
              <v-list-item-subtitle>Organisationseinheit</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-subheader>Auftragsdaten</v-subheader>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ data.tauschAufgabe.auftragTyp }}</v-list-item-title>
              <v-list-item-subtitle>Auftragstyp</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ data.tauschAufgabe.faelligVonDat || '-' }}</v-list-item-title>
              <v-list-item-subtitle>Auftragsdatum - Von</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-subheader>Kundendaten</v-subheader>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ data.tauschAufgabe.kundeName }}</v-list-item-title>
              <v-list-item-subtitle>Kundenname</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ data.tauschAufgabe.strasse }}</v-list-item-title>
              <v-list-item-subtitle>Straße</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ data.tauschAufgabe.ort }}</v-list-item-title>
              <v-list-item-subtitle>Ort</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ data.tauschAufgabe.lat || '-' }}</v-list-item-title>
              <v-list-item-subtitle>Lat</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>

      <v-col cols="4">
        <v-list two-line subheader>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ data.tauschAufgabe.auftragNr }}</v-list-item-title>
              <v-list-item-subtitle>Auftragsnummer</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
            </v-list-item-content>
          </v-list-item>

          <v-subheader></v-subheader>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ data.tauschAufgabe.faelligBisDat || '-' }}</v-list-item-title>
              <v-list-item-subtitle>Auftragsdatum - Bis</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
            </v-list-item-content>
          </v-list-item>

          <v-subheader></v-subheader>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ data.tauschAufgabe.hausnummer || '-' }}</v-list-item-title>
              <v-list-item-subtitle>Hausnummer</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ data.tauschAufgabe.plz }}</v-list-item-title>
              <v-list-item-subtitle>PLZ</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ data.tauschAufgabe.lon || '-' }}</v-list-item-title>
              <v-list-item-subtitle>Lon</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>

      <v-divider vertical></v-divider>

      <v-col cols="4">
        <v-list two-line subheader>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ data.tauschAufgabe.zaehlerNr }}</v-list-item-title>
              <v-list-item-subtitle>Zählernummer</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

           <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ data.tauschAufgabe.equipmentTyp }}</v-list-item-title>
              <v-list-item-subtitle>Equipmenttyp</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

           <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ data.tauschAufgabe.materialTypBez || '-' }}
              </v-list-item-title>
              <v-list-item-subtitle>Materialtypbezeichnung</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

           <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ data.tauschAufgabe.ltZaehlerStand || '-' }}
              </v-list-item-title>
              <v-list-item-subtitle>Letzter Zählerstand</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

           <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ data.tauschAufgabe.ltZaehlerStandDat || '-' }}
              </v-list-item-title>
              <v-list-item-subtitle>Letzte Ablesung</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ data.tauschAufgabe.standort || '-' }}</v-list-item-title>
              <v-list-item-subtitle>Standort</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ data.tauschAufgabe.techPlatzCd }}</v-list-item-title>
              <v-list-item-subtitle>Tech. Platz - Schlüssel</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ data.tauschAufgabe.techPlatzBez || '-' }}</v-list-item-title>
              <v-list-item-subtitle>Tech. Platz - Bezeichnung</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'GeorgImportElementDetails',
  props: {
    data: {
      type: Object,
    },
  },
};
</script>
