<template>
  <ablesung-historie-view
    v-if="istAblesung"
    :historie="historie"
    :aufgabe="aufgabe"
    :previousHistoryDate="previousHistoryDate"
    @changed="$emit('changed', $event)"
  />
  <KundenAblesungBestaetigtHistorieView
    v-else-if="istKundenablesung"
    :historie="historie"
    :aufgabe="aufgabe"
    @changed="$emit('changed', $event)"
  />
  <nachkontrolle-historie-view v-else-if="istNachkontrolle" :historie="historie" />
  <nicht-anwesend-historie-view v-else-if="istNichtAnwesend" :historie="historie" />
  <customer-change-historie-view v-else-if="istCustomerChange" :historie="historie" />
  <kundendaten-korrektur-historie-view v-else-if="istKundendatenKorrektur" :historie="historie"/>
  <watermeter-change-historie-view v-else-if="istWatermeterChange" :historie="historie" />
  <tausch-historie-view
    v-else
    :historie="historie"
    :aufgabe="aufgabe"
    :previousHistoryDate="previousHistoryDate"
    :hersteller-options="herstellerOptions"
    @changed="$emit('changed', $event)"
  />
</template>

<script>

import AblesungHistorieView from './historie/AblesungHistorieView';
import CustomerChangeHistorieView from './historie/CustomerChangeHistorieView';
import NachkontrolleHistorieView from './historie/NachkontrolleHistorieView';
import NichtAnwesendHistorieView from './historie/NichtAnwesendHistorieView';
import TauschHistorieView from './historie/TauschHistorieView';
import WatermeterChangeHistorieView from './historie/WatermeterChangeHistorieView';
import KundendatenKorrekturHistorieView from "@/components/historie/KundendatenKorrekturHistorieView";
import KundenAblesungBestaetigtHistorieView from "@/components/historie/KundenAblesungBestaetigtHistorieView";

export default {
  name: 'HistorieView',
  props: ['historie', 'aufgabe', 'previousHistoryDate', 'herstellerOptions'],
  computed: {
    istNachkontrolle() {
      return this.historie.entryType === 'Nachkontrolle';
    },
    istNichtAnwesend() {
      return this.historie.entryType === 'NichtAnwesend';
    },
    istAblesung() {
      return this.historie.entryType === 'Ablesung';
    },
    istTausch() {
      return this.historie.entryType === 'Tausch';
    },
    istCustomerChange() {
      return this.historie.entryType === 'KundendatenGeaendert';
    },
    istKundendatenKorrektur() {
      return this.historie.entryType === 'KundendatenKorrigiert';
    },
    istWatermeterChange() {
      return this.historie.entryType === 'WasserzaehlerDatenGeaendert';
    },
    istKundenablesung() {
      return this.historie.entryType === 'Kundenablesung';
    },
  },
  components: {
    KundenAblesungBestaetigtHistorieView,
    KundendatenKorrekturHistorieView,
    AblesungHistorieView,
    CustomerChangeHistorieView,
    NachkontrolleHistorieView,
    NichtAnwesendHistorieView,
    TauschHistorieView,
    WatermeterChangeHistorieView,
  },
};
</script>
