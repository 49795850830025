// For development use the local api, else use the api on the same domain
const debug = process.env.NODE_ENV !== 'production';
const apiUrl = debug ? `${window.location.protocol}//${window.location.hostname}:5002/api/` : `${window.location.protocol}//${window.location.host}/api/`;

function getApiUrl() {
  return apiUrl;
}
function stringToHslColor(str, s, l) {
  let hash = 0;
  for (let i = 0; i < str.length; i += 1) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return `hsl(${h}, ${s}%, ${l}%)`;
}

function nameToColor(name) {
  const parts = (name || '').split(' ');
  return stringToHslColor(parts[0], 30, 40);
}

export default {
  getApiUrl,
  nameToColor,
};
