<template>
  <div>
    <component
      :is="wrapperComponent"
      ref="pickerwrapper"
      v-model="showPicker"
      :return-value.sync="range"
      v-bind="wrapperComponentProps"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-on="on"
          v-bind="attrs"
          :label="placeholder"
          prepend-icon="date_range"
          v-model="dateRangeText"
          readonly
        />
      </template>
      <v-card>
        <v-card-text class="d-flex pt-3">
          <div class="mr-4">
            <v-date-picker
              :active-picker.sync="activeFromPicker"
              elevation="1"
              :title-date-format="headerFromDisplay"
              :year-format="headerFromYear"
              show-adjacent-months
              range
              :picker-date.sync="fromPickerDate"
              :value="rangePickerModel"
              @input="dateSelected($event, 'from')"
            />
          </div>
          <div>
            <v-date-picker
              :active-picker.sync="activeToPicker"
              elevation="1"
              :title-date-format="headerToDisplay"
              :year-format="headerToYear"
              show-adjacent-months
              range
              :picker-date.sync="toPickerDate"
              :value="rangePickerModel"
              @input="dateSelected($event, 'to')"
            />
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="cancel" text>Abbrechen</v-btn>
          <v-btn @click="confirmSelection" text color="primary" :disabled="!canConfirm">Bestätigen</v-btn>
        </v-card-actions>
      </v-card>
    </component>
  </div>
</template>

<script>

import moment from 'moment';

export default {
  props: {
    value: Object,
    placeholder: String,
    dialog: Boolean,
  },
  watch: {
    value: {
      deep: true,
      handler: 'updateRange',
    }
  },
  computed: {
    dateRangeText() {
      return this.range && this.range.from && this.range.to ?
        `${ moment(this.range.from).format('DD.MM.YYYY')} - ${ moment(this.range.to).format('DD.MM.YYYY')}`:
        '';
    },
    rangePickerModel() {
      return [this.range.from ?? moment().format('YYYY-MM-DD'), this.range.to ?? moment().format('YYYY-MM-DD')];
    },
    wrapperComponent() {
      return this.dialog ? 'v-dialog' : 'v-menu';
    },
    wrapperComponentProps() {
      return this.dialog ?
        {
          persistent: true,
          'content-class': 'picker-wrapper'
        } :
        {
          'close-on-content-click': false,
          'min-width': 'auto',
        };
    },
    canConfirm() {
      return this.range && this.range.from && this.range.to;
    },
  },
  methods: {
    headerFromDisplay() {
      return this.range.from ? moment(this.range.from).format('ddd DD MMM') : '';
    },
    headerToDisplay() {
      return this.range.to ? moment(this.range.to).format('ddd DD MMM') : '';
    },
    headerFromYear(date) {
      if(this.activeFromPicker === 'YEAR'){
        return date;
      }
      return this.range.from ? moment(this.range.from).year() : moment(this.fromPickerDate).year();
    },
    headerToYear(date) {
      if(this.activeToPicker === 'YEAR'){
        return date;
      }
      return this.range.to ? moment(this.range.to).year() : moment(this.toPickerDate).year();
    },
    updateRange() {
      this.range = {
        from: this.value?.from ? moment(this.value.from).format('YYYY-MM-DD') : null,
        to: this.value?.to ? moment(this.value.to).format('YYYY-MM-DD') : null,
      };
      this.fromPickerDate = this.range.from;
      this.toPickerDate = this.range.to;
    },
    dateSelected(newValue, type) {
      this.$set(this.range, type, newValue[0]);
      if(this.range.from && this.range.to && moment(this.range.from).isAfter(moment(this.range.to))) {
        this.range = {
          from: this.range.to,
          to: this.range.from,
        }
      }
    },
    confirmSelection() {
      const updated = {
        from: moment.utc(this.range.from).format(),
        to: moment.utc(this.range.to).format()
      }

      this.$refs.pickerwrapper.save(updated);
      this.$emit('input', updated);
    },
    cancel() {
      this.showPicker = false;
      this.activeFromPicker = 'DAY';
      this.activeToPicker = 'DAY';
      this.updateRange();
    },
  },
  mounted() {
    this.updateRange();
  },
  data: () => ({
    showPicker: false,
    range: {
      from: null,
      to: null,
    },
    fromPickerDate: moment().format('YYYY-MM-DD'),
    toPickerDate: moment().format('YYYY-MM-DD'),
    activeFromPicker: 'DAY',
    activeToPicker: 'DAY'
  }),
};
</script>
<style lang="scss">
.picker-wrapper {
  width: auto !important;
}
</style>
