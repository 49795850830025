<template>
  <div>
    <h3>{{title}}</h3>
    <div v-if="wasserzaehler" class="ml-2">
      <v-row class="mt-4">
        <v-col>Zählernummer:</v-col>
        <v-col cols="auto">
          <corrected-value
            :initial-value="wasserzaehler.externalId"
            :actual-value="wasserzaehlerChange.externalId"
            v-if="displayChanges"
          />
          <span v-else>{{wasserzaehler.externalId}}</span>
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col>Baujahr:</v-col>
        <v-col cols="auto">
          <corrected-value
            :initial-value="wasserzaehler.baujahr"
            :actual-value="wasserzaehlerChange.baujahr"
            v-if="displayChanges"
          />
          <span v-else>{{wasserzaehler.baujahr}}</span>
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col>Eichjahr:</v-col>
        <v-col cols="auto">
          <corrected-value
            :initial-value="wasserzaehler.eichjahr"
            :actual-value="wasserzaehlerChange.eichjahr"
            v-if="displayChanges"
          />
          <span v-else>{{wasserzaehler.eichjahr}}</span>
        </v-col>
      </v-row >
      <v-row class="mt-4">
        <v-col>Hersteller:</v-col>
        <v-col cols="auto">
          <corrected-value
            :initial-value="wasserzaehler.hersteller"
            :actual-value="wasserzaehlerChange.hersteller"
            v-if="displayChanges"
          />
          <span v-else>{{wasserzaehler.hersteller}}</span>
        </v-col>
      </v-row>
      <v-row  class="mt-4">
        <v-col>Nenndurchfluss:</v-col>
        <v-col cols="auto">
          <corrected-value
            :initial-value="wasserzaehler.nenndurchfluss"
            :actual-value="wasserzaehlerChange.nenndurchfluss"
            v-if="displayChanges"
          />
          <span v-else>{{wasserzaehler.nenndurchfluss}} m<sup>3</sup></span>
        </v-col>
      </v-row>
      <template v-if="showDetails">
        <v-row class="mt-4">
          <v-col>Zählerart:</v-col>
          <v-col cols="auto">
            <corrected-value
              :initial-value="wasserzaehler.zaehlerart"
              :actual-value="wasserzaehlerChange.zaehlerart"
              v-if="displayChanges"
            />
            <span v-else>{{ wasserzaehler.zaehlerart }}</span>
          </v-col>
        </v-row>
        <v-row class="mt-4">
          <v-col>Objektbezeichnung:</v-col>
          <v-col cols="auto">
            <corrected-value
              :initial-value="wasserzaehler.objektbezeichnung"
              :actual-value="wasserzaehlerChange.objektbezeichnung"
              v-if="displayChanges"
            />
            <span v-else>{{ wasserzaehler.objektbezeichnung }}</span>
          </v-col>
        </v-row>
        <v-row class="mt-4">
          <v-col>Versorgungskreis:</v-col>
          <v-col cols="auto">
            <corrected-value
              :initial-value="wasserzaehler.versorgungskreis"
              :actual-value="wasserzaehlerChange.versorgungskreis"
              v-if="displayChanges"
            />
            <span v-else>{{ wasserzaehler.versorgungskreis }}</span>
          </v-col>
        </v-row>
      </template>
    </div>
  </div>
</template>

<script>
import CorrectedValue from "@/components/common/CorrectedValue";
export default {
  components: {CorrectedValue},
  props: {
    wasserzaehler: Object,
    showDetails: Boolean,
    title: {
      type: String,
      default: 'Zählerdaten',
    },
    wasserzaehlerChange: Object,
  },
  computed: {
    displayChanges() {
      return !!(this.wasserzaehler && this.wasserzaehlerChange);
    },
  },
}
</script>

<style scoped>

</style>
