<template>
  <v-container fluid>
    <user-selection :users="userList" :user-filters.sync="userFilters" @filter="reloadActiveAppointments"/>
    <v-row class="mt-4">
      <v-col cols="9">
        <v-card>
          <v-card-title>
            <v-btn
              color="info"
              class="white--text"
              @click="showMapView"
            >
              <v-icon left dark>place</v-icon>
              Karte
            </v-btn>

            <h2 class="text-xs-right ml-4">Termine</h2>
            <v-spacer />
            <v-text-field
              name="input-1"
              label="Suche..."
              append-icon="search"
              v-model="eventFilter"
            />
          </v-card-title>
          <v-card-text>
            <full-calendar :events="filteredEvents" :config="config" />
          </v-card-text>
          <v-card-actions>
            <v-row justify="end" align="center">
              <v-col md="6">
                <date-range-picker class="mr-4" v-model="termineFilter" />
              </v-col>
              <v-col cols="auto">
                <v-btn
                  color="info"
                  class="white--text"
                  @click="downloadReport"
                  :loading="downloadingReport"
                >
                  <v-icon left dark>print</v-icon>
                  Terminvorlage
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card>
          <v-card-text>
            <v-text-field
              name="input-1"
              label="Suche: Name, Strasse, Ort"
              append-icon="search"
              v-model="customerFilterValue"
            />
            <div class="draggable" v-for="(item,i) in allAufgaben" :key="i" :data-index="i">
              <h4 class="text-xs-center">
                <highlight :text="item.owner" :query="customerFilterValue" separator="," />
              </h4>
              <h5 class="text-xs-center">
                <v-icon
                  v-if="!!item.icon"
                  color="blue">
                  {{item.icon}}
                </v-icon>
                <highlight :text="item.address" :query="customerFilterValue" separator="," />
              </h5>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <activity class="mt-4" />

    <v-dialog v-model="showNewAppointmentModal" :persistent="true" max-width="500px">
      <appointment-details
        v-if="showNewAppointmentModal"
        :users="userList"
        :appointment="droppedItem"
        title="Neuer Termin"
        @confirm="onAufgabeDragAndDropConfirmed"
        @cancel="onAufgabeDragAndDropCanceled"
      />
    </v-dialog>
    <v-dialog v-model="showEditAppointmentModal" :persistent="true" max-width="550px">
      <appointment-details
        v-if="showEditAppointmentModal"
        :allowDelete="true"
        :initialUser="editItem.user"
        :initialTermineMessage="editItem.terminAnmerkung"
        :initialTerminart="editItem.terminart"
        :users="userList"
        :appointment="editItem"
        title="Termin bearbeiten"
        @confirm="onAppointmentEditConfirm"
        @cancel="onAppointmentEditCancel"
        @delete="onAppointmentEditDelete"
      />
    </v-dialog>
    <vue-slideout-panel
      v-model="showMap"
      :widths="['85%']"
      closeHtml=""
      @close="showMap=false"
      :styles="[{},{},{}, {display: 'none'}]"
    >
      <termine-map-selection
        v-if="showMap"
        @select="onMapAufgabenSelected"
        @close="showMap=false"
      />
    </vue-slideout-panel>
  </v-container>
</template>

<script>

import VueSlideoutPanel from 'vue-slideout-panel';
import moment from 'moment';
// https://vuejsdevelopers.com/2017/05/20/vue-js-safely-jquery-plugin/
import { FullCalendar } from 'vue-full-calendar';

import 'fullcalendar/dist/locale/de-at';

import $ from 'jquery';
import 'jquery-ui/ui/widgets/draggable';

import wasserparkApi from '../wasserparkApi';
import UserSelection from './termine/UserSelection';
import AppointmentDetails from './termine/AppointmentDetails';
import TermineMapSelection from './termine/TermineMapSelection';
import Highlight from './common/Highlight';
import Activity from './Activity';
import util from '../util';

const console = window.console;

export default {
  name: 'Termine',
  components: {
    Activity,
    FullCalendar,
    UserSelection,
    AppointmentDetails,
    Highlight,
    VueSlideoutPanel,
    TermineMapSelection,
  },
  watch: {
    customerFilterValue() {
      this.reloadUnappointedTasks();
    },
  },
  mounted() {
    wasserparkApi
      .getUsers()
      .then((r) => {
        this.userList = r.data;
      },
      response => console.error(response));

    this.reloadActiveAppointments();
    this.reloadUnappointedTasks();
  },
  updated() {
    this.initDraggable();
  },
  methods: {
    showMapView() {
      this.showMap = true;
    },
    downloadReport() {
      const url = wasserparkApi.getTerminvorlageHtmlReportUrl(
        null,
        this.userFilters.find(x => x.selected)?.user?.email,
        this.termineFilter);

      window.open(url, '_blank');
    },
    addAppointmentToEvents(appointment) {
      this.events.push({
        aufgabeId: appointment.aufgabeId,
        userEmail: appointment.appointedUserEmail,
        userName: appointment.appointedUserName,
        owner: appointment.owner,
        ownerEmail: appointment.ownerEmail,
        ownerPhone: appointment.ownerPhone,
        address: appointment.address,
        terminart: appointment.terminart,
        terminAnmerkung: appointment.terminAnmerkung,
        title: `${appointment.owner} - ${appointment.address}`,
        start: moment(appointment.date),
        color: util.nameToColor(appointment.appointedUserName || appointment.appointedUserEmail),
      });
    },
    reloadActiveAppointments(userFilter) {
      wasserparkApi.getActiveAppointments(userFilter)
        .then((r) => {
          if (!r.data) {
            return;
          }

          this.events = [];

          r.data.data.forEach((a) => {
            this.addAppointmentToEvents(a);
          });
        });
    },
    reloadUnappointedTasks() {
      wasserparkApi.loadUnappointedTasks(this.customerFilterValue)
        .then((r) => {
          if (!r.data) {
            return;
          }

          const termine = r.data.data;

          if (termine && termine.length > 0) {
            this.aufgaben = termine;
          } else {
            this.aufgaben = [];
          }
        });
    },
    initDraggable() {
      $('.draggable').draggable({
        revert: true,
        revertDuration: 0,
      });
    },
    getAufgabe(index) {
      return this.allAufgaben[index];
    },
    onAufgabeDragAndDrop(time, aufgabe) {
      this.droppedItem = {
        time,
        aufgabeId: aufgabe.aufgabeId,
        owner: aufgabe.owner,
        ownerEmail: aufgabe.ownerEmail,
        ownerPhone: aufgabe.ownerPhone,
        address: aufgabe.address,
      };

      this.showNewAppointmentModal = true;
    },
    onAppointmentSelected(aufgabeId, time, userName, userEmail) {
      const event = this.events.find(x => x.aufgabeId === aufgabeId);

      if (!event) {
        return;
      }

      this.editItem = {
        time,
        aufgabeId: event.aufgabeId,
        owner: event.owner,
        ownerEmail: event.ownerEmail,
        ownerPhone: event.ownerPhone,
        address: event.address,
        user: userEmail,
        terminart: event.terminart,
        terminAnmerkung: event.terminAnmerkung,
      };

      this.showEditAppointmentModal = true;
    },
    onAppointmentRescheduled(aufgabeId, time, userName, userEmail, terminart, termineMessage, revertFnc) {
      wasserparkApi.scheduleTausch(
        aufgabeId,
        moment(time).utc().format(),
        userName,
        userEmail,
        terminart,
        termineMessage
      ).then(() => {
        const event = this.events.find(x => x.aufgabeId === aufgabeId);

        if (event) {
          event.start = time;
        }
      },
      (response) => {
        console.error(response);
        if (revertFnc) {
          revertFnc();
        }
      });
    },
    onAppointmentEditConfirm(data) {
      wasserparkApi.scheduleTausch(
        this.editItem.aufgabeId,
        moment(this.editItem.time).utc().format(),
        data.selectedUser.name,
        data.selectedUser.email,
        data.terminart,
        data.termineMessage,
      ).then((r) => {
        const event = this.events.find(x => x.aufgabeId === r.data.aufgabeId);

        if (event) {
          event.userEmail = r.data.appointedUserEmail;
          event.userName = r.data.appointedUserName;
          event.color = util.nameToColor(r.data.appointedUserName || r.data.appointedUserEmail);
          event.terminart = r.data.terminart;
          event.terminAnmerkung = r.data.terminAnmerkung;
        }
      });

      this.editItem = null;
      this.showEditAppointmentModal = false;
    },
    onAppointmentEditCancel() {
      this.editItem = null;
      this.showEditAppointmentModal = false;
    },
    onAppointmentEditDelete() {
      wasserparkApi.unscheduleTausch(this.editItem.aufgabeId)
        .then((r) => {
          if (!r.data || !r.data.unscheduled) {
            return;
          }

          const eventIndex = this.events.findIndex(x => x.aufgabeId === r.data.aufgabeId);
          if (eventIndex >= 0) {
            this.events.splice(eventIndex, 1);
          }

          this.reloadUnappointedTasks();
        });

      this.editItem = null;
      this.showEditAppointmentModal = false;
    },
    onAufgabeDragAndDropConfirmed(data) {
      const droppedItemId = this.droppedItem.aufgabeId;
      wasserparkApi.scheduleTausch(
        this.droppedItem.aufgabeId,
        moment(this.droppedItem.time).utc().format(),
        data.selectedUser.name,
        data.selectedUser.email,
        data.terminart,
        data.termineMessage,
      ).then((r) => {
        if (!r.data) {
          return;
        }

        const mapIndex = this.mapAufgaben.findIndex(
          x => x.aufgabeId === droppedItemId);

        if (mapIndex >= 0) {
          this.mapAufgaben.splice(mapIndex, 1);
        }

        this.addAppointmentToEvents(r.data);
        this.reloadUnappointedTasks();
      });

      this.droppedItem = null;
      this.showNewAppointmentModal = false;

      if(!this.userFilters.some(x => x.user && x.user.email === data.selectedUser.email))
      {
        this.userFilters.push({
          label: data.selectedUser.name,
          icon: 'person',
          user: data.selectedUser,
          selected: false,
          allowClear: true,
        });
      }
    },
    onAufgabeDragAndDropCanceled() {
      this.droppedItem = null;
      this.showNewAppointmentModal = false;
    },
    onMapAufgabenSelected(aufgaben) {
      if (!aufgaben) {
        return;
      }

      aufgaben.forEach((a) => {
        const eventAlreadyScheduled = this.events.findIndex(
          x => x.aufgabeId === a.aufgabeId) >= 0;

        if (!eventAlreadyScheduled) {
          this.mapAufgaben.push(a);
        }
      });
      this.showMap = false;
    },
  },
  computed: {
    allAufgaben() {
      const result = [];
      this.mapAufgaben.forEach((a) => {
        result.push({
          icon: 'pin_drop',
          ...a,
        });
      });
      this.aufgaben.forEach((a) => {
        result.push({
          ...a,
        });
      });

      return result;
    },
    filteredEvents() {
      if (!this.eventFilter) {
        return this.events;
      }
      return this.events.filter(e =>
        e.owner.toLowerCase().indexOf(this.eventFilter.toLowerCase()) >= 0);
    },
  },
  data() {
    return {
      showMap: false,
      customerFilterValue: null,
      downloadingReport: false,
      showNewAppointmentModal: false,
      showEditAppointmentModal: false,
      userFilters: [
        {
          label: 'Alle Wassermeister',
          icon: 'person',
          user: null,
          selected: true,
          allowClear: false,
        },
      ],
      userList: [],
      aufgaben: [],
      mapAufgaben: [],
      events: [],
      editItem: null,
      droppedItem: null,
      eventFilter: null,
      termineFilter: {
        from: null,
        to: null,
      },
      config: {
        timezone: 'local',
        droppable: true,
        eventDurationEditable: false,
        defaultTimedEventDuration: '00:30:00',
        eventClick: (event) => {
          this.onAppointmentSelected(
            event.aufgabeId,
            event.start,
            event.userName,
            event.userEmail);
        },
        eventDrop: (event, delta, revertFunc) => {
          // called on event move end
          this.onAppointmentRescheduled(
            event.aufgabeId,
            event.start,
            event.userName,
            event.userEmail,
            event.terminart,
            event.termineMessage,
            revertFunc);
        },
        drop: (time, source) => {
          const index = $(source.target).attr('data-index');
          const aufgabe = this.getAufgabe(index);
          this.onAufgabeDragAndDrop(time, aufgabe);
        },
      },
    };
  },
};
</script>
<style src="fullcalendar/dist/fullcalendar.css"></style>
<style>
.draggable {
  border-bottom: 1px solid #f3f3f3;
  background-color: white;
  margin: 15px;
  padding-top: 10px;
  cursor: pointer;
}
.fc-event {
  color: white !important;
}
</style>
