<template>
<div>
  <h3 class="header-with-padding">{{ title }}</h3>
  <v-row>
    <v-col v-for="(file, idx) of files" :key="idx" cols="4" md="3" lg="2">
      <v-card @click="selectFile(idx)">
        <v-btn fab small color="red" class="image-delete" @click.stop="deleteFile(file)">
          <v-icon color="white">delete</v-icon>
        </v-btn>
        <v-card-text>
          <v-img :src="getPreviewUrl(file)" contain :aspect-ratio="0.5" max-height="150"/>
        </v-card-text>
        <v-card-title>{{file.name}}</v-card-title>
      </v-card>
    </v-col>
  </v-row>
  <v-overlay :value="showOverlay">
    <v-toolbar flat color="rgba(0,0,0,.6)" class="overlay-app-bar">
      <v-toolbar-title>{{images[carouselIndex].name}}</v-toolbar-title>
      <v-spacer/>
      <v-btn icon @click="closeOverlay">
        <v-icon>close</v-icon>
      </v-btn>
      <v-progress-linear absolute bottom indeterminate :active="currentImageLoading"/>
    </v-toolbar>
    <v-carousel v-model="carouselIndex" v-if="showOverlay" hide-delimiters height="100%">
      <v-carousel-item v-for="(image, imgIdx) of images" :key="imgIdx">
        <v-img contain :src="image.url" :lazy-src="image.thumbnailUrl" @loadstart="onStartLoading(imgIdx)" @load="onStopLoading(imgIdx)"/>
      </v-carousel-item>
    </v-carousel>
  </v-overlay>
  <v-dialog :value="showConfirmDeleteDialog" persistent max-width="450">
    <v-card v-if="showConfirmDeleteDialog">
      <v-card-title>{{deletionFileType}} löschen?</v-card-title>
      <v-card-text>Wollen Sie das {{deletionFileType}} '{{selectedDeletionFile.name}}' wirklich löschen?</v-card-text>
      <v-card-actions>
        <v-btn text @click="resetDeletionFile">Nein</v-btn>
        <v-btn text @click="confirmFileDelete">Ja</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
export default {
  name: "FileGallery",
  props: {
    title: {
      type: String,
      default: 'Files'
    },
    files: Array
  },
  data: () => ({
    showOverlay: false,
    carouselIndex: 0,
    selectedDeletionFile: null,
    loadingImages: [],
  }),
  computed: {
    images() {
      return this.files.map((f, idx) => ({...f, idx})).filter(f => !!f.thumbnailUrl);
    },
    showConfirmDeleteDialog() {
      return !!this.selectedDeletionFile;
    },
    deletionFileType() {
      if(!this.selectedDeletionFile) {
        return null;
      }

      return this.selectedDeletionFile.thumbnailUrl ? 'Foto' : 'Dokument';
    },
    currentImageLoading() {
      return this.loadingImages.includes(this.carouselIndex);
    },
  },
  methods: {
    getPreviewUrl(file) {
      return file.thumbnailUrl ? file.thumbnailUrl : "/static/img/pdf.png";
    },
    selectFile(fileIdx) {
      if(!this.files[fileIdx].thumbnailUrl) {
        const elem = document.createElement('a');
        elem.setAttribute('href', this.files[fileIdx].url);
        elem.setAttribute('download', this.files[fileIdx].name);
        elem.setAttribute('target', '_blank');
        elem.click();
        document.removeChild(elem);
        return;
      }
      this.showOverlay = true;
      this.carouselIndex = this.images.findIndex(i => i.idx === fileIdx);
    },
    onStartLoading(imageIdx) {
      this.loadingImages.push(imageIdx);
    },
    onStopLoading(imageIdx) {
      this.loadingImages = this.loadingImages.filter(i => i !== imageIdx);
    },
    deleteFile(file) {
      this.selectedDeletionFile = file;
    },
    confirmFileDelete() {
      this.$emit('delete', this.selectedDeletionFile);
      this.resetDeletionFile();
    },
    resetDeletionFile() {
      this.selectedDeletionFile = null;
    },
    closeOverlay() {
      this.showOverlay = false;
    },
  },
}
</script>

<style scoped>
  .overlay-app-bar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
  }
  .image-delete {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    z-index: 4;
  }
</style>
