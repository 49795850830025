<template>
  <div>
    <v-data-table
      :headers="headers"
      header-key="value"
      :items="data"
      :server-items-length="totalItems"
      :options.sync="pagination"
      :footer-props="{ 'items-per-page-options': [5, 10, 15] }"
      :loading="loading"
      item-key="id"
    >
      <template v-slot:header="{ props }">
        <tr>
          <th
            v-for="header in props.headers"
            :key="header.text"
            class="px-1"
          >
            <filter-box
              :placeholder="header.filterPlaceholder"
              :initialValue="getFilterInitialValue(header.value)"
              :type="header.filterType"
              :selectItems="header.selectItems"
              @filter="applyFilter(header.value, ...arguments)"
              v-if="header.filterable"
            />
          </th>
        </tr>
      </template>
      <template v-slot:item="{ item }">
        <tr :active="item.selected" @click="selectItemRow(item)">
          <td class="text-xs-left">{{ item.number }}</td>
          <td class="text-xs-left">{{ item.customerId }}</td>
          <td class="text-xs-left">{{ item.importElementType }}</td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog v-model="showDetailsDialog" :persistent="false" max-width="860px">
      <v-card>
        <v-card-actions>
          <v-spacer />
          <v-btn icon small color="primary" @click="showDetailsDialog = false">
            <v-icon>cancel</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text>
          <xml-import-element-details
              v-if="showDetailsDialog"
              :aufgabe="selectedItem.aufgabe"
              :importedData="selectedItem.importedData"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>

import wasserparkApi from '../../wasserparkApi';
import FilterBox from '../common/FilterBox';
import XmlImportElementDetails from './XmlImportElementDetails';

const StatusInitialValue = 'Alle';

export default {
  name: 'XmlImportElements',
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  watch: {
    id() {
      this.reloadData();
    },
    pagination: {
      handler() {
        this.reloadData();
      },
      deep: true,
    },
  },
  components: {
    FilterBox,
    XmlImportElementDetails,
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    getFilterInitialValue(header) {
      return this.filters[header];
    },
    selectItemRow(item) {
      this.selectedItem = item;
    },
    applyFilter(header, filter) {
      this.filters[header] = filter;
      this.reloadData();
    },
    reloadData() {
      this.loading = true;
      wasserparkApi
        .loadXmlImportsData(this.$props.id, this.pagination, this.filters)
        .then((r) => {
          this.data = r.data.data;
          this.totalItems = r.data.count;
          this.loading = false;
        },
        response => window.console.error(response));
    },
  },
  computed: {
    showDetailsDialog: {
      get() {
        return !!this.selectedItem;
      },
      set(value) {
        if (!value) {
          this.selectedItem = null;
        }
      },
    },
  },
  data() {
    return {
      selectedItem: null,
      loading: false,
      data: [],
      totalItems: 0,
      filters: {
        importElementType: StatusInitialValue,
      },
      pagination: {
        page: 1,
        rowsPerPage: 10,
      },
      headers: [
        {
          text: '#',
          value: 'number',
          sortable: false,
        },
        {
          text: 'Kundennummer',
          value: 'customerId',
          sortable: false,
          filterable: true,
          filterType: 'text',
        },
        {
          text: 'Status',
          value: 'importElementType',
          sortable: false,
          filterable: true,
          filterType: 'select',
          selectItems: [
            'Alle',
            'Neu',
            'Update - Objektdaten',
            'Update - Kundendaten',
            'Ablesung',
            'Tausch',
            'Ignoriert',
            'Falscher Eintrag',
          ],
        },
      ],
    };
  },
};
</script>
