<template>
  <v-form v-model="valid">
    <v-card>
      <v-card-title>
        <h2>{{title}}: {{time}}</h2>
      </v-card-title>
      <v-card-text>
        <h3>{{appointment.owner}}, {{appointment.address}}</h3>
        <h4>{{ownerContactDetails}}</h4>
        <v-select
          :items="termineTypes"
          v-model="terminart"
          append-icon="all_out"
          placeholder="Terminart"
          hint="Terminart"
          :rules="terminartRules"
          required
        />
        <v-text-field
          label="Anmerkung"
          append-icon="message"
          v-model="termineMessage"
        />
        <v-select
          :items="users"
          item-value="email"
          item-text="name"
          v-model="selectedUser"
          append-icon="person"
          placeholder="Wassermeister"
          hint="Zuständiger Wassermeister"
          :rules="userRules"
          required
        />
      </v-card-text>
      <v-card-actions>
        <v-btn color="default" text @click="doCancel">Abbrechen</v-btn>
        <v-btn
          color="primary"
          @click="doConfirm"
          :disabled="valid == false"
          :loading="saving"
        >
          Speichern
        </v-btn>
        <v-btn color="error" @click="doDelete" v-if="allowDelete">Löschen</v-btn>
        <v-btn color="default" text :to="getDetailsLink()">Details</v-btn>
        <v-btn
          text
          color="info"
          class="white--text"
          @click="downloadReport"
          :loading="downloadingReport"
        >
          <v-icon left dark>print</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>

import moment from 'moment';
import wasserparkApi from '../../wasserparkApi';

export default {
  name: 'AppointmentDetails',
  props: {
    allowDelete: {
      type: Boolean,
      default: false,
    },
    appointment: {
      type: Object,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    initialUser: {
      type: String,
    },
    initialTerminart: {
      type: String,
    },
    initialTermineMessage: {
      type: String,
    },
  },
  mounted() {
  },
  methods: {
    getDetailsLink() {
      return `/Aufgabe/${this.appointment.aufgabeId}`;
    },
    doConfirm() {
      this.saving = true;
      const data = {
        selectedUser: this.users.find(x => x.email === this.selectedUser),
        terminart: this.terminart,
        termineMessage: this.termineMessage,
      };
      this.$emit('confirm', data);
    },
    doCancel() {
      this.$emit('cancel');
    },
    doDelete() {
      this.$emit('delete');
    },
    downloadReport() {
      const url = wasserparkApi.getTerminvorlageHtmlReportUrl(
        this.appointment.aufgabeId);

      window.open(url, '_blank');
    },
  },
  computed: {
    ownerContactDetails() {
      return [this.appointment.ownerEmail, this.appointment.ownerPhone]
        .filter(Boolean)
        .join(', ');
    },
    time() {
      return moment(this.appointment.time).format('MMMM Do YYYY, HH:mm');
    },
  },
  data() {
    return {
      saving: false,
      downloadingReport: false,
      selectedUser: this.initialUser,
      termineTypes: [
        'Tausch',
        'Ablesung',
        'Nachkontrolle',
      ],
      terminart:
        this.initialTerminart ? this.initialTerminart : 'Tausch',
      valid: false,
      termineMessage: this.initialTermineMessage,
      terminartRules: [
        v => !!v || 'Please select Terminart',
      ],
      userRules: [
        v => !!v || 'Bitte wählen Sie einen Wassermeister aus',
      ],
    };
  },
};
</script>
