import Vuex from 'vuex';
import Vue from "vue";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    tauschperiode: (new Date()).getFullYear(),
    showAllWatermeters: false,
    ableseperiode: {
      from: null,
      to: null,
    },
    importType: 'K5',
  },

  mutations: {
    setAbleseperiode(state, ableseperiode){
      state.ableseperiode = ableseperiode;
    },
    setTauschperiode(state, year) {
      state.tauschperiode = year;
    },
    setAllWatermeterSelection(state, value) {
      state.showAllWatermeters = value;
    },
    setImportType(state, importType) {
      state.importType = importType || 'K5';
    },
  },
});
export default store;
