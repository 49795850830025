<template>
  <div v-if="hasImportedData">
    <v-tabs fixed-tabs v-model="tabs">
      <v-tab href="#details">
        Details
      </v-tab>
      <v-tab href="#rechnungsadresse" v-if="!isAblesung && !isTausch">
        Rechnungsadresse
      </v-tab>
      <v-tab href="#ablesung" v-if="isAblesung">
        Ablesung
      </v-tab>
      <v-tabs-items v-model="tabs">
        <v-tab-item id="details">
          <v-card>
            <v-card-text>
              <v-row justify="space-between">
                <v-col cols="5">
                  <kunden-daten :kundendaten="kundendatenAlt" :kundendaten-change="kundendatenNeu" />
                  <zbv-daten class="mt-4" :value="kundendatenAlt" :value-change="kundendatenNeu" />
                </v-col>
                <v-col cols="5">
                  <zaehler-daten :wasserzaehler="zaehlerdatenAlt" :wasserzaehler-change="zaehlerdatenNeu" show-details />
                  <div class="mt-4 ml-2" v-if="isTausch">
                    <v-row>
                      <v-col>Anfangsstand:</v-col>
                      <v-col cols="auto">{{importedData.anfangsstand}}</v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item id="rechnungsadresse" v-if="!isAblesung && !isTausch">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <rechnungsadresse-daten :value="rechnungsadresseAlt" :value-change="rechnungsadresseNeu" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item id="ablesung" v-if="isAblesung">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <v-row>
                    <v-col>Ablesung:</v-col>
                    <v-col cols="auto">{{importedData.ablesung}}</v-col>
                  </v-row>
                  <v-row>
                    <v-col>Verbrauch:</v-col>
                    <v-col cols="auto">{{importedData.verbrauch}} m<sup>3</sup></v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>

import KundenDaten from "@/components/common/KundenDaten";
import ZbvDaten from "@/components/common/ZbvDaten";
import ZaehlerDaten from "@/components/common/ZaehlerDaten";
import RechnungsadresseDaten from "@/components/common/RechnungsadresseDaten";

export default {
  name: 'XmlImportElementDetails',
  props: {
    importedData: {
      type: Object,
    },
  },
  components: {
    RechnungsadresseDaten,
    ZaehlerDaten,
    ZbvDaten,
    KundenDaten,
  },
  mounted() {
  },
  methods: {
  },
  computed: {
    isAblesung() {
      return this.importedData && this.importedData.importElementType === 'Ablesung';
    },
    isTausch() {
      return this.importedData && this.importedData.importElementType === 'Tausch';
    },
    hasImportedData() {
      return !!this.importedData;
    },
    kundendatenAlt() {
      return this.importedData ? this.importedData.kundendatenAlt : null;
    },
    kundendatenNeu() {
      return this.importedData ? this.importedData.kundendatenNeu : null;
    },
    zaehlerdatenAlt() {
      return this.importedData ? this.importedData.zaehlerdatenAlt : null;
    },
    zaehlerdatenNeu() {
      return this.importedData ? this.importedData.zaehlerdatenNeu : null;
    },
    rechnungsadresseAlt() {
      return this.importedData ? this.importedData.rechnungsadresseNeu : null;
    },
    rechnungsadresseNeu() {
      return this.importedData ? this.importedData.rechnungsadresseNeu : null;
    },
  },
  data: () => ({
    tabs: null,
  }),
};
</script>
