<template>
  <v-container fluid>
    <wasserzaehler-type-filter
      :items="wasserzaehlerFilters"
      @wasserzaehlerFilterChanged="wasserzaehlerFilterChanged(...arguments)"
    />
  <aufgaben-preview-list class="mt-4" :watermeterTypeFilter="selectedFilter"/>
  </v-container>
</template>

<script>

import AufgabenPreviewList from './AufgabenPreviewList';
import WasserzaehlerTypeFilter from './WasserzaehlerTypeFilter';

export default {
  name: 'Aufgaben',
  components: {
    AufgabenPreviewList,
    WasserzaehlerTypeFilter,
  },
  methods: {
    wasserzaehlerFilterChanged(filter) {
      if (!filter) {
        return;
      }
      this.selectedFilter = filter.type;
    },
  },
  data: () => ({
    selectedFilter: 'all',
    wasserzaehlerFilters: [
      {
        id: 1,
        label: 'Alle Wasserzähler',
        icon: 'alarm',
        selected: true,
        type: 'all',
      },
      {
        id: 2,
        label: 'Wasserzähler gewechselt',
        icon: 'alarm_on',
        type: 'changed',
      },
      {
        id: 3,
        label: 'Wasserzähler ausständig',
        icon: 'sync_problem',
        type: 'awaitingChange',
      },
      {
        id: 3,
        label: 'Ausständige ablesen',
        icon: 'visibility_off',
        type: 'notRead',
      },
      {
        id: 4,
        label: 'Wasserzähler abgelesen',
        icon: 'remove_red_eye',
        type: 'read',
      },
      {
        id: 5,
        label: 'Extremwerte zur Nacharbeitung',
        icon: 'error_outline',
        type: 'extremeConsumption',
      },
      {
        id: 6,
        label: 'Nachkontrollen',
        icon: 'youtube_searched_for',
        type: 'followupCheck',
      },
    ],
  }),
};
</script>
