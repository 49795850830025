<template>
  <v-form v-model="valid">
    <v-card>
      <v-card-title>
        <h2>Kundendaten</h2>
      </v-card-title>
      <v-card-text>
        <v-text-field v-model="phone" label="Telefonnummer" />
        <v-text-field v-model="email" label="E-mail" :rules="emailRules" />
      </v-card-text>
      <v-card-actions>
        <v-btn color="default" text @click="doCancel">Abbrechen</v-btn>
        <v-btn
          color="primary"
          @click="doConfirm"
          :disabled="!valid"
          :loading="saving"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>

export default {
  name: 'EditAufgabeContacts',
  props: {
    contacts: {
      type: Object,
      required: true,
    },
  },
  mounted() {
  },
  methods: {
    doConfirm() {
      this.saving = true;
      const contacts = {
        phone: this.phone,
        email: this.email,
      };
      this.$emit('confirm', contacts);
    },
    doCancel() {
      this.$emit('cancel');
    },
  },
  data() {
    return {
      saving: false,
      valid: true,
      phone: this.contacts.phone,
      email: this.contacts.email,
      emailRules: [
        v => /^(?:[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,6})?$/.test(v) || 'E-mail must be valid',
      ],
    };
  },
};
</script>
