<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="data"
      :server-items-length="totalItems"
      :options.sync="pagination"
      :footer-props="{ 'items-per-page-options': [5, 10, 15] }"
      :loading="loading"
      item-key="id"
    >
      <template v-slot:item="{ item }">
        <tr :active="item.selected" @click="selectItemRow(item)">
          <td class="text-xs-left">{{ formatDate(item.date) }}</td>
          <td class="text-xs-left">{{ item.numberOfEntries }}</td>
          <td class="text-xs-left">{{ item.status }}</td>
          <td class="text-xs-left">{{ item.user }}</td>
          <td class="text-xs-left">{{ item.uploadType }}</td>
          <td class="text-xs-left">
            <v-progress-circular
              v-if="item.availableForProcessing && loading"
              indeterminate
              color="primary"
            />
            <v-btn
              v-if="item.availableForProcessing && !loading"
              text
              color="success"
              class="white--text"
              @click="showConfirmDialogForItem(item.id)"
            >
              <v-icon left dark>call_made</v-icon>
              Übernehmen
            </v-btn>
          </td>
          <td class="text-xs-right">
            <v-btn
              text
              color="info"
              class="white--text"
              @click="downloadImport(item.id)"
            >
              <v-icon left dark>get_app</v-icon>
              Download
            </v-btn>
          </td>
          <td>
            <v-btn
              v-if="item.canBeDeleted && !loading"
              text
              color="red darken-4"
              class="white--text"
              @click="doShowDeleteConfirmDialog(item.id)"
            >
              <v-icon dark>delete_forever</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog v-model="showConfirmDialog" :persistent="true" max-width="500px">
      <v-card>
        <v-card-text>
          <h3>Wollen Sie diese Einträge wirklich in Wasserpark übernhemen?</h3>
        </v-card-text>
        <v-card-actions>
          <v-btn color="default" text @click="cancelConfirmDialog()">Nein</v-btn>
          <v-btn
            color="primary"
            :loading="loading"
            text
            @click="confirmSelectedImport()"
          >
            Ja
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showDeleteConfirmDialog" :persistent="true" max-width="500px">
      <v-card>
        <v-card-text>
          <h3>Wollen Sie den Importeintrag wirklich löschen?</h3>
        </v-card-text>
        <v-card-actions>
          <v-btn color="default" text @click="cancelDeleteConfirmDialog()">Nein</v-btn>
          <v-btn
            color="primary"
            :loading="loading"
            text
            @click="confirmSelectedImportDelete()"
          >
            Ja
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showErrorDialog" :persistent="true" max-width="500px">
      <v-card>
        <v-card-text>
          <h4>
            Die neuen Daten konnten nicht übernommen werden, da seit dem Hochladen der Datei
            Änderungen in Wasserpark durchgeführt wurden. Bitten Laden Sie die Datei neu
            hoch, damit Wasserpark die Einträge prüfen kann.
          </h4>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="showErrorDialog = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showUnexpectedErrorDialog" :persistent="true" max-width="500px">
      <v-card>
        <v-card-text>
          <h4>
            Beim übernehmen der Daten ist ein unerwarteter Fehler aufgetreten. Bitten überprüfen Sie die Daten und laden Sie die Datei neu
            hoch, damit Wasserpark die Einträge prüfen kann.
          </h4>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="showUnexpectedErrorDialog = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>

import download from 'downloadjs';
import moment from 'moment';
import wasserparkApi from '../../wasserparkApi';

export default {
  name: 'GeorgImports',
  components: {
  },
  mounted() {
    this.reloadData();
  },
  watch: {
    pagination: {
      handler() {
        this.reloadData();
      },
      deep: true,
    },
  },
  methods: {
    selectItemRow(item) {
      const element = this.data.find(x => x.id === item.id);
      if (!element) {
        return;
      }

      element.selected = !element.selected;

      if (element.selected) {
        this.data.forEach((x) => {
          if (x.id !== element.id) {
            /* eslint-disable no-param-reassign */
            x.selected = false;
          }
        });

        this.$emit('selected', element.id);
      } else {
        this.$emit('unselected');
      }
    },
    cancelConfirmDialog() {
      this.confirmItemId = null;
      this.showConfirmDialog = false;
    },
    cancelDeleteConfirmDialog() {
      this.deleteItemId = null;
      this.showDeleteConfirmDialog = false;
    },
    doShowDeleteConfirmDialog(id) {
      this.deleteItemId = id;
      this.showDeleteConfirmDialog = true;
    },
    confirmSelectedImportDelete() {
      this.showDeleteConfirmDialog = false;
      this.loading = true;

      wasserparkApi
        .confirmGeorgImportDelete(this.deleteItemId)
        .then((response) => {
          this.deleteItemId = null;
          this.loading = false;

          if (response.data.success) {
            this.reloadData();
          } else {
            this.showErrorDialog = true;
          }
        },
        response => window.console.error(response));
    },
    showConfirmDialogForItem(id) {
      this.confirmItemId = id;
      this.showConfirmDialog = true;
    },
    confirmSelectedImport() {
      this.showConfirmDialog = false;
      this.loading = true;

      wasserparkApi
        .confirmGeorgImport(this.confirmItemId)
        .then((response) => {
          this.confirmItemId = null;
          this.loading = false;

          if (response.data.success) {
            this.reloadData();
          } else {
            this.showErrorDialog = true;
          }
        },
        (error) => {
          window.console.error(error);
          this.loading = false;
          this.showUnexpectedErrorDialog = true;
        });
    },
    downloadImport(id) {
      this.loading = true;

      wasserparkApi
        .downloadImport(id)
        .then((response) => {
          download(
            response.data,
            `Import-${id}.csv`,
            response.headers['content-type'],
          );
          this.loading = false;
        },
        response => window.console.error(response));
    },
    formatDate(date) {
      if (!date) {
        return '';
      }

      return moment(date).format('MMMM Do YYYY');
    },
    reloadData() {
      this.loading = true;
      wasserparkApi
        .loadGeorgFileImports({
          page: this.pagination.page,
          rowsPerPage: this.pagination.itemsPerPage
        })
        .then((r) => {
          this.data = r.data.data.map(x => ({ selected: false, ...x }));
          this.totalItems = r.data.count;
          this.loading = false;
        },
        response => window.console.error(response));
    },
  },
  computed: {
  },
  data() {
    return {
      showConfirmDialog: false,
      showErrorDialog: false,
      showUnexpectedErrorDialog: false,
      confirmItemId: null,
      deleteItemId: null,
      showDeleteConfirmDialog: false,
      loading: false,
      data: [],
      totalItems: 0,
      pagination: {
        page: 1,
        itemsPerPage: 10,
      },
      headers: [
        {
          text: 'Datum',
          value: 'date',
          sortable: false,
        },
        {
          text: 'Anzahl',
          value: 'numberOfEntries',
          sortable: false,
        },
        {
          text: 'Status',
          value: 'status',
          sortable: false,
        },
        {
          text: 'User',
          value: 'user',
          sortable: false,
        },
        {
          text: 'Typ',
          value: 'uploadType',
          sortable: false,
        },
        {
          text: '',
          value: 'commands',
          sortable: false,
        },
        {
          text: ' ',
          value: 'download',
          sortable: false,
        },
        {
          text: ' ',
          value: 'delete',
          sortable: false,
        },
      ],
    };
  },
};
</script>
