<template>
  <span v-if="isEqual">
    {{actualValue}}
  </span>
  <span v-else class="d-inline-flex">
    <span class="text-decoration-line-through">
      {{initialValue}}
    </span>
    <span>
      <v-icon color="green">arrow_right_alt</v-icon>
    </span>
    <span>
      {{actualValue}}
    </span>
  </span>
</template>

<script>

export default {
  name: 'CorrectedValue',
  props: {
    initialValue: {
      type: [String, Number],
    },
    actualValue: {
      type: [String, Number],
    },
  },
  computed: {
    isEqual() {
      return this.initialValue === this.actualValue;
    },
  },
};
</script>
