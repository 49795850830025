<template>
  <span v-html="highlight()"></span>
</template>

<script>

export default {
  name: 'Highlight',
  props: ['text', 'query', 'separator'],
  computed: {
  },
  methods: {
    highlightFor(text, query) {
      return text.replace(new RegExp(query, 'gi'), match =>
        `<span class="highlighted">${match}</span>`);
    },
    highlight() {
      if (!this.query || !this.text) {
        return this.text;
      }
      if (!this.separator) {
        return this.highlightFor(this.text, this.query);
      }

      const queryParts = this.query.split(',')
        .map(x => x.trim())
        .filter((item, pos, self) => self.indexOf(item) === pos) // remove duplicates
        .filter(x => !!x); // remove empty values

      let result = this.text;

      queryParts.forEach((q) => {
        result = this.highlightFor(result, q);
      });

      return result;
    },
  },
};
</script>

<style>
  .highlighted {
    background: yellow;
  }
</style>
