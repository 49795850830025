<template>
  <div class="top-level-container">
    <div class="map-container">
      <div class="map">
        <gmap-map
          :center="center"
          :zoom="zoom"
          style="width: 100%; height: 100%"
        >
          <gmap-info-window
            :options="infoOptions"
            :position="infoPosition"
            :opened="showInfoWindow"
          >
            <div>
              <h2>{{infoTitle}}</h2>
              <router-link v-bind:to="infoLinkDestination">
                {{ infoLinkText }}
              </router-link>
              <br />
              <v-btn
                v-if="!infoHasActiveTermin"
                color="info"
                class="white--text"
                @click="addSelectedLocation"
              >
                <v-icon left dark>add_location</v-icon>
                Hinzufügen
              </v-btn>
              <p
                v-if="infoHasActiveTermin"
              >
                <strong>Ein Termin wurde bereits vergeben</strong>
              </p>
            </div>
          </gmap-info-window>
          <gmap-marker
            :key="i"
            v-for="(m,i) in mapData"
            :position="m.position"
            :title="m.label"
            :icon="m.icon"
            :clickable="true"
            :draggable="false"
            @click="toggleInfoWindow(m)"
          />
        </gmap-map>
      </div>
      <div class="termine">
        <div
          class="termine-element"
          v-for="item in selectedAufgabe"
          :key="item.aufgabeId"
        >
          <h3>{{ item.owner }}</h3>
          <h4>{{ item.address }}</h4>
          <v-btn
            color="error"
            class="white--text"
            @click="removeSelectedLocation(item.aufgabeId)"
          >
            <v-icon left dark>highlight_off</v-icon>
            Löschen
          </v-btn>
        </div>
      </div>
    </div>
    <div style="clear: both">
      <v-btn
        color="primary"
        class="white--text"
        @click="closeScreen"
      >
        <v-icon left dark>highlight_off</v-icon>
        Schließen
      </v-btn>
      <v-btn
        v-if="hasSelection"
        color="success"
        class="white--text"
        @click="confirmSelection"
      >
        <v-icon left dark>check_circle_outline</v-icon>
        Bestätigen
      </v-btn>
    </div>
  </div>
</template>

<script>

import googleMapsApi from '../../googleMapsApi';
import wasserparkApi from '../../wasserparkApi';

export default {
  name: 'TermineMapSelection',
  mounted() {
    this.initializeMap();
  },
  methods: {
    closeScreen() {
      this.$emit('close', this.selectedAufgabe);
    },
    confirmSelection() {
      this.$emit('select', this.selectedAufgabe);
    },
    removeSelectedLocation(id) {
      const index = this.selectedAufgabe.findIndex(x => x.aufgabeId === id);

      if (index >= 0) {
        this.selectedAufgabe.splice(index, 1);
      }
    },
    addSelectedLocation() {
      if (!this.infoId) {
        this.resetMarkerSelection();
        return;
      }

      const userAlreadySelected = this.selectedAufgabe.findIndex(
        x => x.aufgabeId === this.infoId) >= 0;

      if (userAlreadySelected) {
        return;
      }

      this.selectedAufgabe.push({
        address: this.infoLinkText,
        aufgabeId: this.infoId,
        owner: this.infoTitle,
        ownerEmail: '',
        ownerPhone: '',
      });

      this.resetMarkerSelection();
    },
    resetMarkerSelection() {
      this.infoHasActiveTermin = false;
      this.showInfoWindow = false;
      this.infoTitle = '';
      this.infoLinkText = '';
      this.infoLinkDestination = '';
      this.infoId = '';
      this.infoPosition = { lat: -1, lng: -1 };
    },
    getQueryParams() {
      return {
        activeYear: this.$store.state.tauschperiode,
        allWatermeters: this.$store.state.showAllWatermeters,
      };
    },
    getMarkerIcon(marker) {
      if (marker.hasActiveTermin) {
        return '/static/img/marker-green.png';
      }
      if (marker.nachkontrolle) {
        return '/static/img/marker-orange.png';
      }
      return marker.hasBeenReplaced ? '/static/img/marker-gray.png' : '/static/img/marker.png';
    },
    toggleInfoWindow(marker) {
      if (
        (this.infoPosition.lat === marker.position.lat) &&
        (this.infoPosition.lat === marker.position.lat)
      ) {
        this.showInfoWindow = !this.showInfoWindow;
        return;
      }
      this.infoHasActiveTermin = marker.hasActiveTermin;
      this.infoPosition.lat = marker.position.lat;
      this.infoPosition.lng = marker.position.lng;
      this.infoTitle = marker.label;
      this.infoLinkText = marker.address;
      this.infoLinkDestination = `/Aufgabe/${marker.id}`;
      this.infoId = marker.id;
      this.showInfoWindow = true;
    },
    initializeMap() {
      this.mapData = [];
      const queryParams = this.getQueryParams();

      wasserparkApi
        .getMapCenter()
        .then((r) => {
          this.center.lat = r.data.latitude;
          this.center.lng = r.data.longitude;
        });

      wasserparkApi
        .getDashboardMapData(queryParams.activeYear, queryParams.allWatermeters)
        .then((r) => {
          r.data.forEach((m) => {
            if (m.position) {
              this.mapData.push({
                id: m.id,
                address: m.address,
                label: m.label,
                icon: this.getMarkerIcon(m),
                hasActiveTermin: m.hasActiveTermin,
                position: {
                  lat: m.position.latitude,
                  lng: m.position.longitude,
                },
              });
            } else {
              googleMapsApi.geocode(`${m.address} ${m.ort}`, (geocodePosition) => {
                if (geocodePosition) {
                  this.mapData.push({
                    id: m.id,
                    address: m.address,
                    label: m.label,
                    icon: this.getMarkerIcon(m),
                    hasActiveTermin: m.hasActiveTermin,
                    position: {
                      lat: geocodePosition.latitude,
                      lng: geocodePosition.longitude,
                    },
                  });
                }
              });
            }
          });
        },
        response => window.console.error(response));
    },
  },
  computed: {
    hasSelection() {
      return this.selectedAufgabe && this.selectedAufgabe.length > 0;
    },
  },
  data: () => ({
    selectedAufgabe: [],
    showInfoWindow: false,
    mapData: [],
    infoHasActiveTermin: false,
    infoTitle: '',
    infoLinkText: '',
    infoLinkDestination: '',
    infoId: '',
    infoPosition: { lat: -1, lng: -1 },
    infoOptions: {
      pixelOffset: { width: 0, height: -35 },
    },
    center: {
      lat: 0.0,
      lng: 0.0,
    },
    zoom: 14,
    activityCount: 10,
  }),
};
</script>

<style>
  .top-level-container {
    height: 95%;
  }
  .map-container {
    height: 95%;
  }
  .map-container .map {
    height: 80%;
  }
  .termine {
    margin-top: 20px;
  }
  .termine-element {
    float: left;
    margin-right: 10px;
    padding: 5px;
    border: 1px solid #eee;
    box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  }
  .termine-element h3, h4 {
    text-align: center;
  }
</style>
