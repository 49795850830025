<!-- eslint-disable -->
<template>
  <v-container fluid>
    <v-row>
      <v-col cols="8">
        <br />
        <h2>Wasserpark Importer Anleitung:</h2>
        <p>
          Um einen reibungslosen Betrieb des Importers zu garantieren, sollten folgende Regeln beachtet werden. Es empfiehlt sich bei Operationen wie der Anlage eines neuen Kunden, nur diese(n) Einträg aus k5 zu exportieren und in Wasserpark einzuspielen.
        </p>

        <h3>NEU (Anlage neuer Kunden in Wasserpark)</h3>
        <p>
          Ein neuer Wasserzähler wird erstellt wenn:
        </p>
        <ul>
          <li>Die eingespielte EDV Nummer nicht vorhanden ist und die Wasserzählernummer nicht im System existiert</li>
        </ul>

        <br />
        <h3>Update (Änderung Kundendaten oder Eigentümerwechsel)</h3>
        <p>
          Ein Kundenupdate (zBsp.: Namensänderung) wird durchgeführt wenn:
        </p>
        <ul>
          <li>Die EDV Nummer und die Wasserzählernummer in Kombination im System existieren</li>
        </ul>
        <br />
        <p>
          Ein Besitzerwechsel wird durchgeführt wenn:
        </p>
        <ul>
          <li>Die Wasserzählernummer im System vorhanden ist und eine neue EDV Nummer in der xml Datei vergeben ist (Hier ist zu beachten, dass wenn der Wasserzählerstand, welcher bei dem Besitzwechsel eingespielt wird, nicht mit dem aktuelle Wasserzählerstand übereinstimmt, wird von Wasserpark eine Ablesung mit dem in der Importdatei enthaltenen Endstand erstellt)</li>
        </ul>

        <br />
        <h3>Ablesung (Import nur in k5 existierender Ablesungen)</h3>
        <p>
          Eine Ablesung im System wird nacherfasst wenn:
        </p>
        <ul>
          <li>Die Kombination aus EDV Nummer und Wasserzählernummer im System existiert</li>
          <li>und der Wasserzählerstand und der Verbrauch mit den in Wasserpark vorhandenen Daten ein sinnvolles Ergebnis liefert.</li>
        </ul>

        <br />
        <h3>Anmerkungen</h3>
        <p>
          Durch die Tatsache, dass der Import aus k5 nur über eine xml Datei erfolgen kann, ist es nicht möglich mehrere Vorgänge gleichzeitig durchzuführen, da es sonst zu einer Vermischung der Operationen kommen könnte.  Grundsätzlich sollte das Vorgehen wie folgt sein:
        </p>
        <ul>
          <li>Importieren der durchgeführten Tausch und Ablesevorgänge aus Wasserpark in k5</li>
          <li>Verarbeitung der Daten in k5</li>
          <li>Import neuer Wasserzähler in Wasserpark</li>
          <li>Import von eventuell vorhandenen Updates in Wasserpark</li>
          <li>Import von Ablesungen in Wasserpark</li>
        </ul>
        <br />
        <p>
          Sollte diese Reihenfolge nicht eingehalten werden, kann es zu Fehlern kommen, weil in k5 noch die korrekten Daten der eventuell zuvor in Wasserpark erfassten Zählertauschvorgängen fehlen können.
        </p>

        <v-btn
          color="primary"
          class="white--text"
          @click="closeScreen"
        >
          <v-icon left dark>highlight_off</v-icon>
          Schließen
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>

export default {
  name: 'ImportManual',
  methods: {
    closeScreen() {
      window.console.log('closing screen');
      this.$emit('close');
    },
  },
  data() {
    return {
    };
  },
};
</script>
