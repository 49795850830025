<template>
  <div>
    <v-data-table
      :headers="headers"
      header-key="value"
      :items="indexedData"
      :server-items-length="totalItems"
      :options.sync="pagination"
      :footer-props="{ 'items-per-page-options': [5, 10, 15] }"
      :loading="loading"
      item-key="id"
    >
      <template v-slot:item="{ item }">
        <tr :active="isSelected(item)" @click="selectItemRow(item)">
          <td class="text-xs-center">{{ item.index }}</td>
          <td class="text-xs-center">{{ item.tauschAufgabe.auftragNr }}</td>
          <td class="text-xs-center">{{ getTranslatedStatus(item.status) }}</td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog v-if="selectedItem" v-model="showDetailsDialog" :persistent="false" max-width="860px">
      <v-card>
        <v-card-title class="headline">{{ selectedItem.tauschAufgabe.auftragNr }}</v-card-title>
        <v-card-text>
          <GeorgImportElementDetails
            v-if="showDetailsDialog"
            :data="selectedItem"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>

import wasserparkApi from '../../wasserparkApi';
import GeorgImportElementDetails from './GeorgImportElementDetails';

const StatusInitialValue = 'Alle';

export default {
  name: 'GeorgImportElements',
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  watch: {
    id() {
      this.reloadData();
    },
    pagination: {
      handler() {
        this.reloadData();
      },
      deep: true,
    },
  },
  components: {
    GeorgImportElementDetails,
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    getFilterInitialValue(header) {
      return this.filters[header];
    },
    isSelected(item) {
      return this.selectedItem?.id === item.id;
    },
    selectItemRow(item) {
      this.selectedItem = item;
    },
    applyFilter(header, filter) {
      this.filters[header] = filter;
      this.reloadData();
    },
    reloadData() {
      this.loading = true;
      const paginationQuery = {
        page: this.pagination.page,
        rowsPerPage: this.pagination.itemsPerPage,
      };
      wasserparkApi
        .loadGeorgImportsData(this.$props.id, paginationQuery, this.filters)
        .then((r) => {
          this.data = r.data.data;
          this.totalItems = r.data.count;
          this.loading = false;
        },
        response => window.console.error(response));
    },
    getTranslatedStatus(status) {
      switch (status) {
        case 'Imported': return 'Importiert';
        case 'Ignored': return 'Ignoriert';
        default: return '-';
      }
    },
  },
  computed: {
    showDetailsDialog: {
      get() {
        return !!this.selectedItem;
      },
      set(value) {
        if (!value) {
          this.selectedItem = null;
        }
      },
    },
    indexedData() {
      return this.data.map((item, index) => ({
        index: index + 1, // apparently "normal" humans start counting at 1
        ...item,
      }));
    },
  },
  data() {
    return {
      selectedItem: null,
      loading: false,
      data: [],
      totalItems: 0,
      filters: {
        importElementType: StatusInitialValue,
      },
      pagination: {
        page: 1,
        itemsPerPage: 10,
      },
      headers: [
        {
          text: '#',
          value: 'number',
          sortable: false,
        },
        {
          text: 'Auftrags Nr.',
          value: 'string',
          sortable: false,
        },
        {
          text: 'Status',
          value: 'string',
          sortable: false,
        },
      ],
    };
  },
};
</script>
