<template>
  <div>
    <h3>Zählerstand</h3>
    <div class="ml-2" v-if="wasserzaehler">
      <v-row class="mt-4">
        <v-col v-if="isAblesung">Ablesung:</v-col>
        <v-col v-else>Letzte Ablesung:</v-col>
        <v-col cols="auto">
          {{wasserzaehler.letzteAblesung}} m<sup>3</sup>
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col v-if="isAblesung" :class="{ 'red--text': verbrauchWarning }">Verbrauch:</v-col>
        <v-col v-else :class="{ 'red--text': verbrauchWarning }">Letzter Verbrauch:</v-col>
        <v-col :class="{ 'red--text': verbrauchWarning }" cols="auto">
          {{wasserzaehler.letzterVerbrauch}} m<sup>3</sup>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    wasserzaehler: Object,
    verbrauchWarning: Boolean,
    isAblesung: Boolean,
  }
}
</script>
