<template>
  <v-container fluid>
      <v-toolbar color="white">
        <h2 class="headline">Export</h2>
      </v-toolbar>

      <v-row class="mt-4">
        <v-col cols="6">
          <v-card>
            <v-card-title>
              <h2 class="headline">
                <v-icon>import_export</v-icon> Export erstellen
              </h2>
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-autocomplete
                v-model="selectedImport"
                :items="availableImports"
                :loading="isLoading"
                :hint="hint"
                :filter="matchImport"
                label="Wählen sie einen Import aus"
                persistent-hint>
                <template v-slot:item="{ item }">
                  {{getFormattedImportTime(item.date)}} - {{item.user}}
                </template>
                 <template v-slot:selection="{ item }">
                  {{getFormattedImportTime(item.date)}} - {{item.user}}
                </template>
              </v-autocomplete>
            </v-card-text>
            <v-card-actions>
              <v-btn
                @click="createExport(selectedImport)"
                :disabled="isExportCreating"
                color="primary"
              >
                <span v-if="!isExportCreating">Start</span>
                <v-progress-circular
                  v-else indeterminate
                  color="primary"
                  size="24"
                  width="2"
                >
                </v-progress-circular>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <v-card class="mt-4">
        <v-card-title>
          <h2 class="headline">
            <v-icon>import_export</v-icon>
            Historie
          </h2>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-data-table>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
</template>

<script>
import moment from 'moment';
import download from 'downloadjs';

import api from '@/wasserparkApi';

export default {
  data: () => ({
    selectedImport: null,
    isLoading: false,
    isExportCreating: false,
    availableImports: [],
  }),
  async mounted() {
    this.isLoading = true;
    const confirmedImports = await api.loadGeorgConfirmedFileImports();
    this.availableImports = confirmedImports.data;
    this.isLoading = false;
  },

  computed: {
    hint() {
      if (!this.selectedImport) {
        return 'Nur Einträge die dem Import zuordbar sind werden exportiert';
      }

      return `${this.selectedImport.numberOfEntries} Einträge werden exportiert`;
    },
  },

  methods: {
    async createExport(selectedImport) {
      this.isExportCreating = true;
      try {
        const response = await api.createAndDownloadGeorgExport(selectedImport.id);

        download(response.data, 'Export.csv', response.headers['content-type']);
      } catch (err) {
        // TODO: Display error msg
      } finally {
        this.isExportCreating = false;
      }
    },

    getFormattedImportTime(time) {
      return moment(time).format('DD.MM.YYYY');
    },

    matchImport(queryText, item) {
      const queryParts = queryText.toLowerCase().split(' ');

      let matches = true;
      queryParts.forEach((q) => {
        let m = false;
        if (this.getFormattedImportTime(item.date).includes(q)) {
          m = true;
        }

        if (item.user.toLowerCase().includes(q)) {
          m = true;
        }

        matches = matches && m;
      });

      return matches;
    },
  },
};
</script>
