<template>
  <div>
    <v-row>
      <v-col cols="12" sm="8" md="6" lg="4">
        <v-row>
          <v-col>Titel:</v-col>
          <v-col cols="auto">
            <corrected-value
              :initialValue="kundendatenAlt.titel"
              :actualValue="kundendatenNeu.titel"
            />
          </v-col>
        </v-row>
        <v-row class="mt-4">
          <v-col>Vorname:</v-col>
          <v-col cols="auto">
            <corrected-value
              :initialValue="kundendatenAlt.name"
              :actualValue="kundendatenNeu.name"
            />
          </v-col>
        </v-row>
        <v-row class="mt-4">
          <v-col>Nachname:</v-col>
          <v-col cols="auto">
            <corrected-value
              :initialValue="kundendatenAlt.nachname"
              :actualValue="kundendatenNeu.nachname"
            />
          </v-col>
        </v-row>
        <v-row class="mt-4">
          <v-col>Namensergänzung:</v-col>
          <v-col cols="auto">
            <corrected-value
              :initialValue="kundendatenAlt.namensergaenzung"
              :actualValue="kundendatenNeu.namensergaenzung"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CorrectedValue from "@/components/common/CorrectedValue";
export default {
  name: "KundendatenKorrekturHistorieView",
  components: {CorrectedValue },
  props: ['historie'],
  computed: {
    kundendatenAlt() {
      const kundendatenAlt = this.historie.kundendatenAlt;

      if (!kundendatenAlt || !kundendatenAlt.kontakt) {
        return {
          kundennummer: '',
          titel: '',
          name: '',
          nachname: '',
          namensergaenzung: '',
        };
      }

      return {
        kundennummer: kundendatenAlt.kontakt.kundennummer,
        titel: kundendatenAlt.kontakt.titel,
        name: kundendatenAlt.kontakt.name,
        nachname: kundendatenAlt.kontakt.nachname,
        namensergaenzung: kundendatenAlt.kontakt.namensergaenzung,
      };
    },
    kundendatenNeu() {
      const kundendatenNeu = this.historie.kundendatenNeu;

      if (!kundendatenNeu || !kundendatenNeu.kontakt) {
        return {
          kundennummer: '',
          titel: '',
          name: '',
          nachname: '',
          namensergaenzung: '',
        };
      }

      return {
        kundennummer: kundendatenNeu.kontakt.kundennummer,
        titel: kundendatenNeu.kontakt.titel,
        name: kundendatenNeu.kontakt.name,
        nachname: kundendatenNeu.kontakt.nachname,
        namensergaenzung: kundendatenNeu.kontakt.namensergaenzung,
      };
    },
  },
}
</script>

