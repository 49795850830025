<template>
  <div>
    <v-row justify="space-between">
      <v-col cols="5">
        <zaehler-daten :wasserzaehler="historie.wasserzaehler" />
      </v-col>
      <v-col cols="5">
        <zaehler-stand :wasserzaehler="historie.wasserzaehler" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="5">
        <v-checkbox
          readonly
          label="Erneute Nachkontrolle"
          v-model="erneuteNachkontrolle"
        />
      </v-col>
    </v-row>
    <v-row v-if="!!historie.kommentar">
      <v-col cols="5">
        <h4>Anmerkung</h4>
        <p>{{historie.kommentar}}</p>
      </v-col>
    </v-row>

    <ImageGallery :images="galleryImages" />
  </div>
</template>

<script>

import util from '@/util';
import urljoin from 'url-join';
import ImageGallery from '../common/ImageGallery';
import ZaehlerDaten from "@/components/common/ZaehlerDaten";
import ZaehlerStand from "@/components/common/ZaehlerStand";

import authUtils from '@/security/utils';

const fotosUrlBase = util.getApiUrl();

export default {
  name: 'NachkontrolleHistorieView',
  components: {
    ZaehlerStand,
    ZaehlerDaten,
    ImageGallery,
  },
  props: ['historie'],
  computed: {
    erneuteNachkontrolle() {
      return this.historie.erneuteNachkontrolle;
    },
    galleryImages() {
      if (!this.historie ||
        !this.historie.fotoGallerien ||
        (this.historie.fotoGallerien.length <= 0)) {
        return [];
      }

      return this.historie.fotoGallerien[0].fotos.map(f => ({
        thumbnail: urljoin(fotosUrlBase, f.vorschaubildUrl, `?api_key=${authUtils.getAccessToken()}`),
        image: urljoin(fotosUrlBase, f.url, `?api_key=${authUtils.getAccessToken()}`),
      }));
    },
  },
};
</script>
