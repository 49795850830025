<template>
  <div>
    <v-row justify="space-between">
      <v-col cols="5">
        <kunden-daten title="Kundendaten Alt" :kundendaten="kundendatenAlt" />
        <zbv-daten :value="kundendatenAlt" title="ZBV Alt" class="mt-4" />
        <rechnungsadresse-daten :value="rechnungsadresseAlt" title="Rechnungsadresse Alt" class="mt-4" />
      </v-col>
      <v-col cols="5">
        <kunden-daten :kundendaten="kundendatenNeu" title="Kundendaten Neu" />
        <zbv-daten :value="kundendatenNeu" title="ZBV Neu" class="mt-4" />
        <rechnungsadresse-daten :value="rechnungsadresseNeu" title="Rechnungsadresse Neu" class="mt-4" />
      </v-col>
    </v-row>
  </div>
</template>

<script>

import KundenDaten from "@/components/common/KundenDaten";
import ZbvDaten from "@/components/common/ZbvDaten";
import RechnungsadresseDaten from "@/components/common/RechnungsadresseDaten";
export default {
  name: 'CustomerChangeHistorieView',
  components: {
    RechnungsadresseDaten,
    ZbvDaten,
    KundenDaten
  },
  props: ['historie'],
  computed: {
    kundendatenAlt() {
      const kundendatenAlt = this.historie.kundendatenAlt;

      if (!kundendatenAlt || !kundendatenAlt.kontakt) {
        return {
          kundennummer: '',
          titel: '',
          name: '',
          nachname: '',
          namensergaenzung: '',
          telefonnummer: '',
          email: '',
          ort: '',
          strasse: '',
          zbvBriefansicht1: '',
          zbvBriefansicht2: '',
          zbvAdresse: '',
          zbvOrt: '',
        };
      }

      return {
        kundennummer: kundendatenAlt.kontakt.kundennummer,
        titel: kundendatenAlt.kontakt.titel,
        name: kundendatenAlt.kontakt.name,
        nachname: kundendatenAlt.kontakt.nachname,
        namensergaenzung: kundendatenAlt.kontakt.namensergaenzung,
        telefonnummer: kundendatenAlt.kontakt.telefonnummer,
        email: kundendatenAlt.kontakt.email,
        ort: kundendatenAlt.kontakt.ort,
        strasse: kundendatenAlt.kontakt.strasse,
        zbvBriefansicht1: kundendatenAlt.kontakt.zbvBriefansicht1,
        zbvBriefansicht2: kundendatenAlt.kontakt.zbvBriefansicht2,
        zbvAdresse: kundendatenAlt.kontakt.zbvAdresse,
        zbvOrt: kundendatenAlt.kontakt.zbvOrt,
      };
    },
    kundendatenNeu() {
      const kundendatenNeu = this.historie.kundendatenNeu;

      if (!kundendatenNeu || !kundendatenNeu.kontakt) {
        return {
          kundennummer: '',
          titel: '',
          name: '',
          nachname: '',
          namensergaenzung: '',
          telefonnummer: '',
          email: '',
          ort: '',
          strasse: '',
          zbvBriefansicht1: '',
          zbvBriefansicht2: '',
          zbvAdresse: '',
          zbvOrt: '',
        };
      }

      return {
        kundennummer: kundendatenNeu.kontakt.kundennummer,
        titel: kundendatenNeu.kontakt.titel,
        name: kundendatenNeu.kontakt.name,
        nachname: kundendatenNeu.kontakt.nachname,
        namensergaenzung: kundendatenNeu.kontakt.namensergaenzung,
        telefonnummer: kundendatenNeu.kontakt.telefonnummer,
        email: kundendatenNeu.kontakt.email,
        ort: kundendatenNeu.kontakt.ort,
        strasse: kundendatenNeu.kontakt.strasse,
        zbvBriefansicht1: kundendatenNeu.kontakt.zbvBriefansicht1,
        zbvBriefansicht2: kundendatenNeu.kontakt.zbvBriefansicht2,
        zbvAdresse: kundendatenNeu.kontakt.zbvAdresse,
        zbvOrt: kundendatenNeu.kontakt.zbvOrt,
      };
    },
    rechnungsadresseAlt() {
      const kundendatenAlt = this.historie.kundendatenAlt;

      if (!kundendatenAlt || !kundendatenAlt.rechnungsadresse) {
        return {
          hausnummer: '', ort: '', plz: '', restadresse: '', staat: '', strasse: '' };
      }

      return {
        hausnummer: kundendatenAlt.rechnungsadresse.hausnummer,
        ort: kundendatenAlt.rechnungsadresse.ort,
        plz: kundendatenAlt.rechnungsadresse.plz,
        restadresse: kundendatenAlt.rechnungsadresse.restadresse,
        staat: kundendatenAlt.rechnungsadresse.staat,
        strasse: kundendatenAlt.rechnungsadresse.strasse,
      };
    },
    rechnungsadresseNeu() {
      const kundendatenNeu = this.historie.kundendatenNeu;

      if (!kundendatenNeu || !kundendatenNeu.rechnungsadresse) {
        return {
          hausnummer: '', ort: '', plz: '', restadresse: '', staat: '', strasse: '' };
      }

      return {
        hausnummer: kundendatenNeu.rechnungsadresse.hausnummer,
        ort: kundendatenNeu.rechnungsadresse.ort,
        plz: kundendatenNeu.rechnungsadresse.plz,
        restadresse: kundendatenNeu.rechnungsadresse.restadresse,
        staat: kundendatenNeu.rechnungsadresse.staat,
        strasse: kundendatenNeu.rechnungsadresse.strasse,
      };
    },
  },
  data() {
    return {
    };
  },
};
</script>
