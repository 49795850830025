<template>
  <v-form v-model="valid">
    <v-card>
      <v-card-title>
        <h2>Datumskorrektur</h2>
      </v-card-title>
      <v-card-text>
        <v-menu
          v-model="datumMenu"
          full-width
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="formattedDatum"
              label="Datum"
              hint="Datum"
              prepend-icon="event"
              :rules="datumRules"
              persistent-hint
              readonly
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="datum"
            no-title
            locale="de"
            :allowed-dates="allowedDates"
          />
        </v-menu>
      </v-card-text>
      <v-card-actions>
        <v-btn color="default" text @click="doCancel">Abbrechen</v-btn>
        <v-btn
          color="primary"
          @click="doConfirm"
          :disabled="!valid"
          :loading="saving"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>

import moment from 'moment';

export default {
  name: 'ChangeDate',
  props: {
    initialDate: {
      type: String,
      required: true,
    },
    lastHistoryDate: {
      type: String,
    },
  },
  mounted() {
  },
  methods: {
    doConfirm() {
      this.saving = true;
      const date = moment(this.datum).utc().format();
      this.$emit('confirm', date);
    },
    doCancel() {
      this.$emit('cancel');
    },
    allowedDates(val) {
      return moment() >= this.getUtcDate(val);
    },
    validateDatumIsAfterLastEntry() {
      const lastHistoryDate = this.lastHistoryMoment;
      if (!lastHistoryDate) {
        return true;
      }

      const datum = this.getUtcDate(this.datum);

      return datum > lastHistoryDate ||
        'Es is nicht möglich eine Ablesung/Tausch vor einem anderen Historieneintrag zu erstellen';
    },
    getUtcDate(date) {
      if (!date) {
        return null;
      }

      const utcDate = moment.utc(date, 'YYYY-MM-DD');
      return utcDate;
    },
  },
  computed: {
    formattedDatum() {
      return this.datum
        ? moment(this.getUtcDate(this.datum)).format('MMMM Do YYYY')
        : '';
    },
    lastHistoryMoment() {
      if (!this.lastHistoryDate) {
        return null;
      }

      const result = moment(this.lastHistoryDate);

      return result.isValid ? result : null;
    },
  },
  data() {
    return {
      datum: moment(this.initialDate).format('YYYY-MM-DD'),
      datumMenu: false,
      saving: false,
      valid: true,
      datumRules: [
        v => !!v || 'Datum kann nicht leer sein',
        v => this.validateDatumIsAfterLastEntry(v),
      ],
    };
  },
};
</script>
