<template>
  <v-container>
    <v-toolbar color="white">
      <v-icon>label</v-icon>
      <v-toolbar-title>Tags</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-expansion-panels class="mt-4">
      <v-expansion-panel v-for="(tag) in tags" :key="tag.id">
        <v-expansion-panel-header>
          {{tag.name}}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <tag-values :id="tag.id" :name="tag.name" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>

import wasserparkApi from '../wasserparkApi';
import TagValues from './TagValues';

export default {
  name: 'Tags',
  components: {
    TagValues,
  },
  mounted() {
    wasserparkApi
      .getTags()
      .then((r) => {
        this.tags = r.data;
      },
      response => window.console.error(response));
  },
  methods: {
  },
  data() {
    return {
      tags: null,
    };
  },
};
</script>
