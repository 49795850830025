<template>
  <v-card>
    <v-card-title>
      <h2 class="headline">
        <v-icon>timeline</v-icon> Letzte Aktivitäten
      </h2>
      <v-spacer></v-spacer>
      <v-btn-toggle v-model="count" mandatory>
        <v-btn text :value="10">
          10
        </v-btn>
        <v-btn text :value="20">
          20
        </v-btn>
        <v-btn text :value="50">
          50
        </v-btn>
      </v-btn-toggle>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-list two-line>
        <v-list-item
          v-for="(a, i) in activity"
          :key="i"
          @click="navigate(a)"
        >
          <v-list-item-avatar>
            <v-icon
              :class="[getActivityColor(a)]"
            >
              {{ getActivityIcon(a) }}
            </v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ a.activityName }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ getActivityDetails(a) }}
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-list-item-action-text v-text="formatDate(a.date)" />
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>

import moment from 'moment';
import wasserparkApi from '../wasserparkApi';

const activityIcons = [
  { name: 'Nachkontrolle', icon: 'history', color: 'amber white--text' },
  { name: 'Nicht anwesend', icon: 'highlight_off', color: 'red white--text' },
  { name: 'Ablesung', icon: 'picture_in_picture', color: 'green white--text' },
  { name: 'Tausch', icon: 'cached', color: 'blue white--text' },
];

const defaultIcon = 'star_border';
const defaultColor = 'teal lighten-1 white--text';

export default {
  name: 'Activity',
  watch: {
    count(newVal) {
      this.loadData(newVal);
    },
  },
  mounted() {
    this.loadData(this.count);
  },
  methods: {
    loadData(count) {
      wasserparkApi
        .getDashboardActivityData(count)
        .then((r) => {
          this.activity = r.data;
        },
        response => window.console.error(response));
    },
    navigate(a) {
      this.$router.push(`/Aufgabe/${a.aufgabeId}`);
    },
    getActivityIcon(a) {
      const activityIcon = activityIcons.find(i => i.name === a.activityName);
      return activityIcon ? activityIcon.icon : defaultIcon;
    },
    getActivityColor(a) {
      const actvityIcon = activityIcons.find(i => i.name === a.activityName);
      return actvityIcon ? actvityIcon.color : defaultColor;
    },
    formatDate(date) {
      return moment(date).format('DD.MM.YYYY HH:mm');
    },
    getActivityDetails(a) {
      return `${a.owner}, ${a.address}`;
    },
  },
  data() {
    return {
      activity: null,
      count: 10,
    };
  },
};
</script>
