<template>
  <div>
    <div class="d-flex align-center">
      <h3>{{title}}</h3>
      <v-btn icon tile small v-if="editable" @click="showKundeEditModal = true" class="ml-2">
        <v-icon small>edit</v-icon>
      </v-btn>
    </div>
    <div class="ml-2">
      <v-row class="mt-4">
        <v-col>Kundennummer:</v-col>
        <v-col cols="auto">
          <corrected-value
            v-if="displayChanges"
            :initialValue="kundendaten.kundennummer"
            :actualValue="kundendatenChange.kundennummer"
          />
          <span v-else>{{kundendaten.kundennummer}}</span>
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col>Name:</v-col>
        <v-col cols="auto">
          <corrected-value
            v-if="displayChanges"
            :initialValue="name"
            :actualValue="changedName"
          />
          <span v-else>{{name}}</span>
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col>Ort:</v-col>
        <v-col cols="auto">
          <corrected-value
            v-if="displayChanges"
            :initialValue="kundendaten.ort"
            :actualValue="kundendatenChange.ort"
          />
          <span v-else>{{kundendaten.ort}}</span>
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col>Straße:</v-col>
        <v-col cols="auto">
          <corrected-value
            v-if="displayChanges"
            :initialValue="kundendaten.strasse"
            :actualValue="kundendatenChange.strasse"
          />
          <span v-else>{{kundendaten.strasse}}</span>
        </v-col>
      </v-row>
    </div>
    <div class="pa-2">
      <div class="d-flex align-center ml-n2">
        <h4 class="text-start">Kontakt</h4>
        <v-btn icon tile small v-if="editable" @click="showContactEditModal = true" class="ml-2">
          <v-icon small>edit</v-icon>
        </v-btn>
      </div>
      <v-row>
        <v-col>Telefonnummer:</v-col>
        <v-col cols="auto">
          <corrected-value
            v-if="displayChanges"
            :initialValue="kundendaten.telefonnummer"
            :actualValue="kundendatenChange.telefonnummer"
          />
          <span v-else>{{kundendaten.telefonnummer}}</span>
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col>Email:</v-col>
        <v-col cols="auto">
          <corrected-value
            v-if="displayChanges"
            :initialValue="kundendaten.email"
            :actualValue="kundendatenChange.email"
          />
          <span v-else>{{kundendaten.email}}</span>
        </v-col>
      </v-row>
    </div>
    <v-dialog v-model="showKundeEditModal" persistent max-width="500">
      <edit-kunde
        v-if="showKundeEditModal"
        :kundendaten="kundendaten"
        @confirm="onKundeEditConfirmed"
        @cancel="showKundeEditModal = false"
      />
    </v-dialog>
    <v-dialog v-model="showContactEditModal" persistent max-width="500">
      <edit-aufgabe-contacts
        v-if="showContactEditModal"
        :contacts="contacts"
        @confirm="onContactEditConfirmed"
        @cancel="showContactEditModal = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import CorrectedValue from "@/components/common/CorrectedValue";
import EditAufgabeContacts from "@/components/aufgaben/EditAufgabeContacts";
import EditKunde from "@/components/aufgaben/EditKunde";
export default {
  components: {EditKunde, CorrectedValue, EditAufgabeContacts},
  props: {
    title: {
      type: String,
      default: 'Kundendaten',
    },
    kundendaten: Object,
    kundendatenChange: Object,
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showKundeEditModal: false,
    showContactEditModal: false,
  }),
  computed: {
    displayChanges() {
      return !!(this.kundendaten && this.kundendatenChange);
    },
    contacts() {
      return {
        phone: this.kundendaten.telefonnummer,
        email: this.kundendaten.email,
      };
    },
    name() {
      return `${this.kundendaten.titel ?? ''} ${this.kundendaten.name ?? ''} ${this.kundendaten.nachname ?? ''} ${this.kundendaten.namensergaenzung ?? ''}`
    },
    changedName() {
      return `${this.kundendatenChange.titel ?? ''} ${this.kundendatenChange.name ?? ''} ${this.kundendatenChange.nachname ?? ''} ${this.kundendatenChange.namensergaenzung ?? ''}`
    },
  },
  methods: {
    onKundeEditConfirmed(data) {
      this.$emit('kundeEdit', data);
      this.showKundeEditModal = false;
    },
    onContactEditConfirmed(data) {
      this.$emit('contactEdit', data);
      this.showContactEditModal = false;
    },
  }
}
</script>
