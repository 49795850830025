<template>
  <v-card>
    <v-card-title>
      <h2>Kundendaten Korrigieren</h2>
    </v-card-title>
    <v-card-text>
      <v-text-field v-model="titel" label="Titel" />
      <v-text-field v-model="name" label="Vorname" />
      <v-text-field v-model="nachname" label="Nachname" />
      <v-text-field v-model="namensergaenzung" label="Namensergänzung" />
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn color="default" text @click="doCancel">Abbrechen</v-btn>
      <v-btn
        color="primary"
        @click="doConfirm"
        :loading="saving"
      >
        Korrigieren
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "EditKunde",
  props: {
    kundendaten: Object,
  },
  data: () => ({
    saving: false,
    titel: null,
    name: null,
    nachname: null,
    namensergaenzung: null,
  }),
  watch: {
    kundendaten: {
      handler: 'updateKundendaten',
      deep: true,
    },
  },
  methods: {
    doConfirm() {
      this.saving = true;
      const data = {
        kundennummer: this.kundendaten.kundennummer,
        titel: this.titel,
        name: this.name,
        nachname: this.nachname,
        namensergaenzung: this.namensergaenzung

      };
      this.$emit('confirm', data);
    },
    doCancel() {
      this.$emit('cancel');
    },
    updateKundendaten(value) {
      this.titel = value.titel;
      this.name = value.name;
      this.nachname = value.nachname;
      this.namensergaenzung = value.namensergaenzung;
    },
  },
  created() {
    this.updateKundendaten(this.kundendaten);
  },
}
</script>

<style scoped>

</style>
