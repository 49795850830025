var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"header-key":"value","items":_vm.data,"server-items-length":_vm.totalItems,"options":_vm.pagination,"footer-props":{ 'items-per-page-options': [5, 10, 15] },"loading":_vm.loading,"item-key":"id"},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('tr',_vm._l((props.headers),function(header){return _c('th',{key:header.text,staticClass:"px-1"},[(header.filterable)?_c('filter-box',{attrs:{"placeholder":header.filterPlaceholder,"initialValue":_vm.getFilterInitialValue(header.value),"type":header.filterType,"selectItems":header.selectItems},on:{"filter":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.applyFilter.apply(void 0, [ header.value ].concat( argsArray ))}}}):_vm._e()],1)}),0)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{attrs:{"active":item.selected},on:{"click":function($event){return _vm.selectItemRow(item)}}},[_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(item.number))]),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(item.customerId))]),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(item.importElementType))])])]}}])}),_c('v-dialog',{attrs:{"persistent":false,"max-width":"860px"},model:{value:(_vm.showDetailsDialog),callback:function ($$v) {_vm.showDetailsDialog=$$v},expression:"showDetailsDialog"}},[_c('v-card',[_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","small":"","color":"primary"},on:{"click":function($event){_vm.showDetailsDialog = false}}},[_c('v-icon',[_vm._v("cancel")])],1)],1),_c('v-card-text',[(_vm.showDetailsDialog)?_c('xml-import-element-details',{attrs:{"aufgabe":_vm.selectedItem.aufgabe,"importedData":_vm.selectedItem.importedData}}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }