const constants = {
  statusMap: [
    { name: 'Alle', value: '', color: '', icon: '' },
    { name: 'Todo', value: 'Todo', color: 'primary', icon: 'playlist_add_check' },
    { name: 'Nachkontrolle beantragt', value: 'FollowupCheckRequested', color: 'orange', icon: 'history' },
    { name: 'Nachkontrolle durchgeführt', value: 'FollowupCheckCompleted', color: 'orange darken-4', icon: 'history' },
    { name: 'Ablesung bestätigt', value: 'Confirmed', color: 'green', icon: 'check_circle' },
    { name: 'Obsolet', value: 'Obsolete', color: 'red', icon: 'block' }
  ],
  getStatusNameFromValue(value) {
    if (!value) {
      return null;
    }
    const statusElement = this.statusMap.find(s => s.value === value);
    return statusElement ? statusElement.name : null;
  },
  getStatusValue(status) {
    if (!status) {
      return null;
    }
    const statusElement = this.statusMap.find(s => s.name === status);
    return statusElement ? statusElement.value : null;
  },
  getStatusIcon(status) {
    if (!status) {
      return '';
    }

    const statusElement = this.statusMap.find(s => s.name === status);
    return statusElement ? statusElement.icon : '';
  },
  getStatusColor(status) {
    if (!status) {
      return 'primary';
    }

    const statusElement = this.statusMap.find(s => s.name === status);
    return statusElement ? statusElement.color : 'primary';
  },
};

export default constants;
