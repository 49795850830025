<template>
  <v-container fluid>
    <v-toolbar color="white">
      <h2 class="headline">Exports</h2>
    </v-toolbar>
    <v-row class="mt-4">
      <v-col cols="6">
        <v-card>
          <v-card-title>
            <h2 class="headline">
              <v-icon>import_export</v-icon> New export
            </h2>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <date-range-picker v-model="exportRange" />
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              @click="createAndDownloadNewTausch"
              :loading="saving"
            >
              <v-icon>import_export</v-icon>
              Export Tausch
              <v-icon>cached</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              @click="createAndDownloadNewAblesung"
              :loading="saving"
            >
              <v-icon>import_export</v-icon>
              Export Ablesung
              <v-icon>picture_in_picture</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-card class="mt-4">
      <v-card-title>
        <h2 class="headline">
          <v-icon>import_export</v-icon> Historie
        </h2>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-card>
              <v-card-title>
                <h2 class="headline">
                  Tausch
                </h2>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="tauschData"
                :server-items-length="tauschTotalItems"
                :options.sync="tauschPagination"
                :footer-props="{ 'items-per-page-options': [5, 10, 15] }"
                :loading="loading"
                item-key="id"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td class="text-xs-left">{{ formatDate(item.date) }}</td>
                    <td class="text-xs-left">{{ formatDate(item.fromFilter) }}</td>
                    <td class="text-xs-left">{{ formatDate(item.toFilter) }}</td>
                    <td class="text-xs-left">{{ item.user }}</td>
                    <td class="text-xs-right">
                      <v-btn
                        text
                        color="info"
                        class="white--text"
                        @click="downloadExport(item.id)"
                      >
                        <v-icon left dark>get_app</v-icon>
                        Download
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card>
              <v-card-title>
                <h2 class="headline">
                  Ablesung
                </h2>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="ablesungData"
                :server-items-length="ablesungTotalItems"
                :options.sync="ablesungPagination"
                :footer-props="{ 'items-per-page-options': [5, 10, 15] }"
                :loading="loading"
                item-key="id"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td class="text-xs-left">{{ formatDate(item.date) }}</td>
                    <td class="text-xs-left">{{ formatDate(item.fromFilter) }}</td>
                    <td class="text-xs-left">{{ formatDate(item.toFilter) }}</td>
                    <td class="text-xs-left">{{ item.user }}</td>
                    <td class="text-xs-right">
                      <v-btn
                        text
                        color="info"
                        class="white--text"
                        @click="downloadExport(item.id)"
                      >
                        <v-icon left dark>get_app</v-icon>
                        Download
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

import download from 'downloadjs';
import moment from 'moment';

import wasserparkApi from '../../wasserparkApi';

export default {
  name: 'Export',
  watch: {
    ablesungPagination: {
      handler() {
        this.reloadData();
      },
      deep: true,
    },
    tauschPagination: {
      handler() {
        this.reloadData();
      },
      deep: true,
    },
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    formatDate(date) {
      if (!date) {
        return '';
      }

      return moment(date).utc().format('MMMM Do YYYY');
    },
    createAndDownloadNewAblesung() {
      this.saving = true;
      wasserparkApi
        .createAndDownloadAblesungExport(this.exportRange)
        .then((response) => {
          download(
            response.data,
            'AblesungExport.xml',
            response.headers['content-type'],
          );
          this.saving = false;
          this.reloadData();
        },
        response => window.console.error(response));
    },
    createAndDownloadNewTausch() {
      this.saving = true;
      wasserparkApi
        .createAndDownloadTauschExport(this.exportRange)
        .then((response) => {
          download(
            response.data,
            'TauschExport.xml',
            response.headers['content-type'],
          );
          this.saving = false;
          this.reloadData();
        },
        response => window.console.error(response));
    },
    downloadExport(id) {
      this.loading = true;

      wasserparkApi
        .downloadExport(id)
        .then((response) => {
          download(
            response.data,
            `Export-${id}.xml`,
            response.headers['content-type'],
          );
          this.loading = false;
        },
        response => window.console.error(response));
    },
    reloadData() {
      this.loading = true;
      const tauschPaginationQuery = {
        page: this.tauschPagination.page,
        rowsPerPage: this.tauschPagination.itemsPerPage,
      };

      wasserparkApi
        .loadTauschExports(tauschPaginationQuery)
        .then((r) => {
          this.tauschData = r.data.data;
          this.tauschTotalItems = r.data.count;

          const ablesungPaginationQuery = {
            page: this.ablesungPagination.page,
            rowsPerPage: this.ablesungPagination.itemsPerPage,
          };

          wasserparkApi
            .loadAblesungExports(ablesungPaginationQuery)
            .then((ra) => {
              this.ablesungData = ra.data.data;
              this.ablesungTotalItems = ra.data.count;
              this.loading = false;
            },
            ra => window.console.error(ra));
        },
        response => window.console.error(response));
    },
  },
  data() {
    return {
      loading: false,
      saving: false,
      exportRange: {
        from: null,
        to: null,
      },
      ablesungData: [],
      ablesungTotalItems: 0,
      ablesungPagination: {
        page: 1,
        itemsPerPage: 10,
      },
      tauschData: [],
      tauschTotalItems: 0,
      tauschPagination: {
        page: 1,
        itemsPerPage: 10,
      },
      headers: [
        {
          text: 'Datum',
          value: 'date',
          sortable: false,
        },
        {
          text: 'Von',
          value: 'from',
          sortable: false,
        },
        {
          text: 'Bis',
          value: 'to',
          sortable: false,
        },
        {
          text: 'User',
          value: 'user',
          sortable: false,
        },
        {
          text: '',
          value: '',
          sortable: false,
        },
      ],
    };
  },
};
</script>
